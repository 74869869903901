import { Input } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { routesNamedDict } from '../../../routes';
import { editLibrary } from '../../../utils/backendRequests/library/editLibrary';
import { RootAuth } from '../../../utils/types/Types';
import { stringValid } from '../../../utils/validations/stringValidator';
import { Modal } from '../../Modal';
import { EditLibProps } from '../../table/TableTypes';

export function EditFabricLibraryModal({
  record,
  getLibraryList,
}: EditLibProps) {
  const navigate = useNavigate();
  const [modal, setModal] = useState({ visible: false });
  const { name, description, id } = record;
  const [newDescription, setNewDescription] = useState(description);
  const [newName, setNewName] = useState(name);
  const [errorName, setErrorName] = useState(false);
  const [errorDescription, setErrorDescription] = useState(false);
  const account = useSelector((state: RootAuth) => state.auth.accountType);

  const handleCancel = () => {
    setModal({ visible: false });
    setNewName(name);
    setNewDescription(description);
  };

  useEffect(() => {
    setNewName(name);
    setNewDescription(description);
    setErrorName(false);
    setErrorDescription(false);
  }, [record]);

  const handleOk = async () => {
    setErrorName(false);
    setErrorDescription(false);
    const isNameValid = stringValid(newName, 50, i18n.t('common:name'), false);
    const isDescriptionValid = stringValid(
      newDescription,
      500,
      i18n.t('column_titles:description'),
      true
    );
    if (isNameValid && isDescriptionValid) {
      const descriptionValue = newDescription === '' ? null : newDescription;
      await editLibrary(
        id,
        descriptionValue,
        newName,
        navigate,
        routesNamedDict
      );
      getLibraryList();
      setModal({ visible: false });
    } else {
      // Set error state if validation fails for either name or description
      setErrorName(true);
      setErrorDescription(!isDescriptionValid);
    }
  };

  const showModal = () => {
    setModal({ visible: true });
  };

  return (
    <>
      {account !== 'viewer' && (
        <a onClick={showModal} role="button">
          {i18n.t('buttons:edit')}
        </a>
      )}
      <Modal
        title={i18n.t('headers:edit_library')}
        open={modal.visible}
        onCancel={handleCancel}
        cancelButtonProps={{ danger: true }}
        onOk={handleOk}
      >
        {`${i18n.t('common:name')}:`}
        <Input
          allowClear
          onChange={(evt) => setNewName(evt.target.value)}
          value={newName}
          status={errorName ? 'error' : ''}
        />
        {`${i18n.t('column_titles:description')}:`}
        <Input
          showCount
          value={newDescription}
          style={{ marginTop: '10px' }}
          onChange={(evt) => setNewDescription(evt.target.value)}
          status={errorDescription ? 'error' : ''}
        />
      </Modal>
    </>
  );
}
