import { message } from 'antd';
import i18n from 'i18next';

import { api } from '../api/api';

/**
 * Send an inspiration to the bin based on its id.
 */
export async function deleteToBinInspiration(
  inspirationId: string
): Promise<boolean> {
  try {
    await api({
      method: 'PUT',
      url: `v1/user_upload/${inspirationId}/delete_to_bin`,
      showErrMsg: true,
    });
    message.success(i18n.t('long_messages:message_inspiration_bin_success'));
    return true;
  } catch (error) {
    return false;
  }
}
