import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TabTitle } from '../../components/TabTitle';
import { UserManagementList } from '../../components/table/userManagement/UserManagementList';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { UserManagementHelpIcon } from '../../elements/icons/StyledIcons';
import { MainLayout } from '../../layouts/Main';
import i18n from '../../plugins/i18n';
import { UserProps } from '../../utils/backendRequests/user/getCurrentUser';
import { getAllUsers } from '../../utils/backendRequests/user/getUsers';
import { RootAuth } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export function UserManagementPage({ title }: TitleProp) {
  const [data, setData] = useState<UserProps[]>([]);
  const account = useSelector((state: RootAuth) => state.auth.accountType);

  async function getCurrentBrandUsers() {
    setData(await getAllUsers());
  }

  useEffect(() => {
    getCurrentBrandUsers();
  }, []);

  const pageSubtitle =
    account === 'manager' ? (
      <>
        {i18n.t('page_subtitles:manage_users_manager_sub')}
        <UserManagementHelpIcon fontSize="16px" margin="0 0 -15px 8px" />
      </>
    ) : (
      i18n.t('page_subtitles:manage_users_non_manager_sub')
    );

  return (
    <MainLayout>
      <HeaderTitle
        title={i18n.t('page_titles:user_management_page')}
        subtitle={pageSubtitle}
      />
      <TabTitle title={title} />
      <UserManagementList data={data} onSuccess={getCurrentBrandUsers} />
    </MainLayout>
  );
}
