import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { TabTitle } from '../../components/TabTitle';
import {
  BackendFilterProps,
  TransformedFilterProps,
} from '../../components/filter/FilterTypes';
import { FabricSearchResults } from '../../components/search/fabric/FabricSearchResults';
import { defaultPageSize } from '../../components/table/TableGeneric';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { MainLayout } from '../../layouts/Main';
import i18n from '../../plugins/i18n';
import { getFabricsByIds } from '../../utils/backendRequests/fabric/getFabricsByIds';
import { record } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export const CompareFabricsPage = ({ title }: TitleProp) => {
  const { search } = useLocation();
  const [fabrics, setFabrics] = useState<record[]>([]);
  const [selectableColumnNames, setSelectableColumnNames] = useState<string[]>(
    []
  );
  const [sortableColumnNames, setSortableColumnNames] = useState<string[]>([]);
  // Represents the range of filterable fields from the backend
  const [filterableFields, setFilterableFields] = useState<BackendFilterProps>(
    {}
  );
  // Represents the selected filter values to be sent to the backend
  const [filterableColumns, setFilterableColumns] =
    useState<TransformedFilterProps>({});

  const fabricIds = useMemo(() => {
    const url = new URLSearchParams(search);
    const fabricsParam = url.get('fabrics');
    return fabricsParam ? JSON.parse(decodeURIComponent(fabricsParam)) : [];
  }, [search]);

  // Pagination and Sorter state
  const [tableState, setTableState] = useState({
    current: 1,
    pageSize: defaultPageSize,
    total: 0,
    sortField: '',
    sortOrder: '',
  });

  const getFabrics = async () => {
    const res = await getFabricsByIds(fabricIds, tableState, filterableColumns);
    if (res) {
      setFabrics(res.fabrics);
      setTableState((prevState) => ({
        ...prevState,
        total: res.fabrics_count,
      }));
      setSelectableColumnNames(res.selectable_columns);
      setSortableColumnNames(res.sortable_columns);
      if (Object.keys(filterableFields).length === 0) {
        setFilterableFields(res.filterable_columns);
      }
    }
  };

  const getFilteredFabrics = async () => {
    setTableState((prevState) => ({
      ...prevState,
      current: 1,
    }));
    const res = await getFabricsByIds(fabricIds, tableState, filterableColumns);
    if (res) {
      setFabrics(res.fabrics);
      setTableState((prevState) => ({
        ...prevState,
        current: 1,
        total: res.fabrics_count,
      }));
      setSelectableColumnNames(res.selectable_columns);
      setSortableColumnNames(res.sortable_columns);
    }
  };

  // Get data on page load or resets data when all data filtered out
  useEffect(() => {
    if (fabrics.length === 0) {
      getFabrics();
    }
  }, [fabricIds, filterableColumns]);

  // Load data on pagination or sorter change
  useEffect(() => {
    if (fabrics.length > 0) getFabrics();
  }, [
    tableState.current,
    tableState.pageSize,
    tableState.sortField,
    tableState.sortOrder,
  ]);

  return (
    <MainLayout>
      <HeaderTitle title={i18n.t('page_titles:compare_fabrics_page')} />
      <TabTitle title={title} />

      <FabricSearchResults
        initialData={fabrics}
        tableState={tableState}
        setTableState={setTableState}
        selectableColumnNames={selectableColumnNames}
        sortableColumns={sortableColumnNames}
        filterableFields={filterableFields}
        filterableColumns={filterableColumns}
        setFilterableColumns={setFilterableColumns}
        getFilteredFabrics={getFilteredFabrics}
      />
    </MainLayout>
  );
};
