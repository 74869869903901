import { Col, Tooltip } from 'antd';
import {
  ColorCircle,
  ColourTableText,
  DisplayColourContainer,
  DisplayColourRow,
} from '../elements/colours/StyledColour';

export interface Colour {
  title: string | null;
  hexValue: string | null;
  tooltipShow?: boolean;
}

interface DisplayColourProps {
  colours: Colour[];
  isTechnical?: boolean;
}

export function DisplayColour({
  colours,
  isTechnical = false,
}: DisplayColourProps) {
  return (
    <DisplayColourContainer $isTechnical={isTechnical}>
      {colours.map((colour, index) => (
        <DisplayColourRow
          $isTechnical={isTechnical}
          key={index}
          align="middle"
          wrap={false}
        >
          <Col>
            <ColourTableText>{colour.title || '-'}</ColourTableText>
          </Col>
          <Col style={{ marginLeft: '8px' }}>
            {colour.hexValue && (
              <Tooltip
                title={colour.tooltipShow && colour.hexValue.toUpperCase()}
              >
                <ColorCircle
                  $hexValue={colour.hexValue}
                  $tooltipShow={colour.tooltipShow as boolean}
                />
              </Tooltip>
            )}
          </Col>
        </DisplayColourRow>
      ))}
    </DisplayColourContainer>
  );
}
