import { message } from 'antd';
import i18n from 'i18next';
import { MsgProp } from '../../types/Types';
import { api } from '../api/api';

/**
 * Copies all the fabrics from a library to an existing collection.
 */
export async function copyLibraryFabricsToCollection(
  collectionId: string,
  libraryId: string
): Promise<MsgProp> {
  const data = {
    collection_id: collectionId,
    library_id: libraryId,
  };
  return await api({
    method: 'POST',
    url: 'v1/collection/copy_from_library/fabric',
    body: data,
    showErrMsg: true,
  }).then((res: MsgProp) => {
    message.success(
      i18n.t('long_messages:message_collection_add_fabric_success')
    );
    return res;
  });
}
