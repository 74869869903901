import i18n from 'i18next';
import { useState } from 'react';

import { AddToProjectModal } from '../../modal/project/AddToProjectModal';
import { CopyCollectionToProjectLinkProps } from './CollectionListTypes';

export function CopyCollectionToProjectLink({
  collectionId,
}: CopyCollectionToProjectLinkProps) {
  const [collectionModal, setCollectionModal] = useState({ visible: false });

  return (
    <>
      <a
        onClick={() =>
          setCollectionModal({
            visible: true,
          })
        }
        role="button"
      >
        {i18n.t('buttons:copy_to_project')}
      </a>
      <AddToProjectModal
        modal={collectionModal}
        setModal={setCollectionModal}
        collectionId={collectionId}
      />
    </>
  );
}
