import { useSelector } from 'react-redux';
import placeholderImage from '../../../assets/images/placeholder-image.png';

import {
  FabricCardContainer,
  FabricCardText,
  FabricCardTextContainer,
} from '../../../elements/fabrics/fabricStyledElement';
import { ImageFabricCard } from '../../../elements/images/StyledImages';
import { routesNamedDict } from '../../../routes';
import {
  parseFabricTitles,
  returnOrderedFields,
} from '../../../utils/fabrics/parseFabricFields';
import { RootAuth } from '../../../utils/types/Types';
import {
  FabricCardProps,
  ImageDisplayProps,
  cardField,
} from '../CarouselTypes';

/**
 * A card for to show a fabric on a carousel.
 */
export function ShowFabricCard({ isMobile, item, navigate }: FabricCardProps) {
  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);
  const cardFields = returnOrderedFields([
    'name',
    'weight_grams_per_sqm',
    'width_cm',
  ]);

  const cardTextProps = cardFields.map((field: cardField) => {
    let fieldValue = '';
    if (field.name in item) {
      fieldValue = `${parseFabricTitles(field.name, measurementUnit)}: ${
        item[field.name as keyof typeof item]
      }`;
    }
    return <FabricCardText key={field.name}>{fieldValue}</FabricCardText>;
  });

  const ImageDisplay = ({ src }: ImageDisplayProps) => {
    return <ImageFabricCard alt={item.name} src={src} isMobile={isMobile} />;
  };

  return (
    <FabricCardContainer
      $isMobile={isMobile}
      onClick={() => {
        navigate(
          routesNamedDict.FABRICS_FABRIC_DETAILS.replace(
            ':libraryId',
            item.lib_id
          ).replace(':fabricId', item.id)
        );
      }}
    >
      <ImageDisplay
        src={
          item.image_url_front ||
          item.image_url_macro ||
          item.image_url_header ||
          placeholderImage
        }
      />
      <FabricCardTextContainer $isMobile={isMobile}>
        {cardTextProps}
      </FabricCardTextContainer>
    </FabricCardContainer>
  );
}
