import { api } from '../api/api';
import { SharedDesignerBrandsObject } from '../collection/CollectionTypes';

/**
 * Only usable by supplier users.
 * Gets an object of Designer Brands, which have
 * approved the Supplier Brand associated with the current Supplier user.
 *
 * Object contains Designer Brand IDs and their name.
 */
export const getApprovedByDesignerBrands =
  async (): Promise<SharedDesignerBrandsObject> => {
    return await api({
      method: 'GET',
      url: 'v1/brand/designer',
      showErrMsg: true,
    });
  };
