import { api } from '../api/api';
import { AdminLabelsList } from './AdminTypes';

/**
 * Get all fabric ML Training labels as admin.
 */
export async function getFabricLabels(): Promise<AdminLabelsList> {
  const response = await api({
    method: 'GET',
    url: 'v1/superuser/training_label',
    showErrMsg: true,
  });
  return response;
}
