import { useEffect, useState } from 'react';

import { TabTitle } from '../../TabTitle';
import { TableGeneric } from '../TableGeneric';
import { AdminLinksListProps, LabelColumn } from '../TableTypes';
import { linksColumns } from './Columns';

/**
 * Displays a table of shared collection links
 */
export function AdminSharedLinksList({ data, title }: AdminLinksListProps) {
  const [columns, setColumns] = useState<LabelColumn[]>([]);

  useEffect(() => {
    setColumns(linksColumns());
  }, []);

  return (
    <>
      {data[0] && <TabTitle title={title} />}
      <TableGeneric columns={columns} data={data} isBackendPaginated={false} />
    </>
  );
}
