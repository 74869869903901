import { Input, Space } from 'antd';
import i18n from 'i18next';
import { useState } from 'react';
import { MLCheckBox } from './MLCheckBox';

import { MobileSearchBarWrapper } from '../../styles/wrappers/MobileSearchbarWrapper';
import { SearchbarProps } from './SearchTypes';

export function MobileSearchbar({ onSearch }: SearchbarProps) {
  const { Search } = Input;
  const [includeMLFields, setIncludeMLFields] = useState(true);

  return (
    <>
      <Space.Compact>
        <MobileSearchBarWrapper>
          <Search
            onSearch={(value) => onSearch(value, includeMLFields)}
            placeholder={i18n.t('long_message:message_input_search_text')}
            style={{ width: '100%' }}
            suffix={
              <MLCheckBox
                includeMLFields={includeMLFields}
                setIncludeMLFields={setIncludeMLFields}
              />
            }
          />
        </MobileSearchBarWrapper>
      </Space.Compact>
    </>
  );
}
