import { Card, Popover } from 'antd';
import { useSelector } from 'react-redux';
import placeholderImage from '../../assets/images/placeholder-image.png';
import {
  CardCoverContainer,
  CardFooterContainer,
  CardImageContainer,
  CardSelectCheckbox,
  ColourCircle,
  ColoursContainer,
  FooterButtonsContainer,
  FooterDivider,
  GenericGalleryCard,
  ImageCardCover,
  PopoverContainer,
} from '../../elements/gallery/StyledGallery';
import { MoreColoursIcon } from '../../elements/icons/StyledIcons';
import { record, RootAuth, Setter } from '../../utils/types/Types';
import { FabricDetailsLink } from '../fabric/detail/FabricDetailsLink';
import { EditFabricLink } from '../fabric/edit/EditFabricLink';

interface GalleryCardProps {
  fabric: record;
  index: number;
  selectedKeys: number[];
  setSelectedKeys: Setter<number[]>;
  pageType: string;
  isMobile: boolean;
}

const MAXCOLOURS = 6;

export const GalleryCard = ({
  fabric,
  index,
  setSelectedKeys,
  selectedKeys,
  pageType,
  isMobile,
}: GalleryCardProps) => {
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  const role = useSelector((state: RootAuth) => state.auth?.role);

  // Update checked state based on whether the index is in selectedKeys
  const isChecked = selectedKeys.includes(index);

  const handleCardClick = () => {
    if (isChecked) {
      setSelectedKeys((prevSelectedKeys) =>
        prevSelectedKeys.filter((key) => key !== index)
      );
    } else {
      setSelectedKeys((prevSelectedKeys) => [...prevSelectedKeys, index]);
    }
  };

  const displayColours = () => {
    return (
      <ColoursContainer>
        {fabric.colours.slice(0, MAXCOLOURS).map((colour) => {
          const colorHex =
            typeof colour === 'string' ? colour : colour.hex_value || '#000';
          return (
            <ColourCircle
              key={typeof colour === 'string' ? colour : colour.id}
              title={typeof colour === 'string' ? colour : colour.name}
              $colorHex={colorHex}
            />
          );
        })}
        {fabric.colours.length > MAXCOLOURS && (
          <Popover
            content={
              <PopoverContainer>
                {fabric.colours.slice(MAXCOLOURS).map((colour) => {
                  const colorHex =
                    typeof colour === 'string'
                      ? colour
                      : colour.hex_value || '#000';
                  return (
                    <ColourCircle
                      key={typeof colour === 'string' ? colour : colour.id}
                      title={typeof colour === 'string' ? colour : colour.name}
                      $colorHex={colorHex}
                    />
                  );
                })}
              </PopoverContainer>
            }
          >
            <ColourCircle $colorHex="#e0e0e0">
              <MoreColoursIcon />
            </ColourCircle>
          </Popover>
        )}
      </ColoursContainer>
    );
  };

  return (
    <GenericGalleryCard
      hoverable
      $isMobile={isMobile}
      cover={
        <CardCoverContainer>
          <CardImageContainer>
            <ImageCardCover
              alt={fabric.name}
              src={
                fabric.image_url_front
                  ? fabric.image_url_front
                  : placeholderImage
              }
            />
          </CardImageContainer>
          <CardSelectCheckbox checked={isChecked} onClick={handleCardClick} />
        </CardCoverContainer>
      }
    >
      <div>
        <Card.Meta title={fabric.name} description={displayColours()} />
      </div>
      <CardFooterContainer>
        <FooterDivider />
        <FooterButtonsContainer>
          {(pageType !== 'collection' || role === 'designer') && (
            <FabricDetailsLink pageType={pageType} record={fabric} />
          )}
          {role === 'supplier' && account !== 'viewer' && (
            <EditFabricLink pageType={pageType} record={fabric} />
          )}
        </FooterButtonsContainer>
      </CardFooterContainer>
    </GenericGalleryCard>
  );
};
