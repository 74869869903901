import { FilterValues } from '../FilterTypes';

export const createInitialFilterValues = () => {
  const initialFilterValues: FilterValues = {
    created_at: {
      filter_type: 'date',
      values: [],
      has_null: true,
      required: true,
      grouping: 'other',
    },
    is_owner: {
      filter_type: 'checkboxBoolean',
      values: [true, false],
      render: ['me', 'shared'],
      has_null: true,
      required: true,
      grouping: 'other',
    },
  };
  return initialFilterValues;
};
