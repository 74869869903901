import { Input } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { SupplierType } from '../../../utils/backendRequests/suppliers/supplierTypes';
import { updateSupplierComment } from '../../../utils/backendRequests/suppliers/updateSupplierComment';
import { Setter } from '../../../utils/types/Types';
import { Modal } from '../../Modal';

interface UpdateSupplierCommentModalProps {
  isModalOpen: { visible: boolean };
  setIsModalOpen: Setter<{ visible: boolean }>;
  supplierBrand: SupplierType;
  getSupplierData: () => Promise<void>;
}

export function UpdateSupplierCommentModal({
  isModalOpen,
  setIsModalOpen,
  supplierBrand,
  getSupplierData,
}: UpdateSupplierCommentModalProps) {
  const [newComment, setNewComment] = useState<string | null>(
    supplierBrand.comment_from_designer_brand || ''
  );

  useEffect(() => {
    setNewComment(supplierBrand.comment_from_designer_brand);
  }, [supplierBrand]);

  // Submits a request to update the comment, and resets all fields
  // If successful, closes modal
  const handleSubmit = async () => {
    // If newComment is an empty string, submit null
    const updatedComment = newComment === '' ? null : newComment;
    const updateUserResponse = await updateSupplierComment(
      supplierBrand.id,
      updatedComment
    );

    if (updateUserResponse) {
      getSupplierData();
      setNewComment('');
      setIsModalOpen({ visible: false });
    }
  };

  const handleCancel = () => {
    setIsModalOpen({ visible: false });
  };

  return (
    <Modal
      title={i18n.t('headers:edit_comment')}
      open={isModalOpen.visible}
      onCancel={handleCancel}
      cancelButtonProps={{ danger: true }}
      onOk={handleSubmit}
    >
      <Input
        value={newComment as string}
        onChange={(e) => setNewComment(e.target.value)}
      />
    </Modal>
  );
}
