import {
  StyledIframe,
  StyledIframeContainer,
  StyledTextB2,
  TitleH7,
} from '../../../elements/3dModel/Styled3DModel';
import i18n from '../../../plugins/i18n';
import { Fabric3DModelProps } from '../FabricTypes';

/**
 * A tab component to display 3D Models of fabric images integrated into either
 * a Fabric Ball iframe or Fabric shirt iframe container.
 */
export function Fabric3DModel({ isMobile }: Fabric3DModelProps) {
  // TODO: Used images passed to component to pass to iframe component
  // to dislpay that fabric projected into the 3D Model.
  return (
    <>
      <StyledTextB2 $isMobile={isMobile}>
        {i18n.t('long_messages:message_3d_model_demo')}
      </StyledTextB2>
      <TitleH7 $isMobile={isMobile}>{i18n.t('headers:ball_model')}</TitleH7>
      <StyledIframeContainer $isMobile={isMobile}>
        <StyledIframe
          src="https://virtualtouch.tech/fabric?id=0f609996045d8ee2b2bfe576ae587c8dc0f38d03"
          $isMobile={isMobile}
          title={i18n.t('headers:ball_model')}
          loading="lazy"
        />
      </StyledIframeContainer>
      <StyledTextB2 $isMobile={isMobile}>
        {i18n.t('long_messages:message_ball_model')}
      </StyledTextB2>
      <TitleH7 $isMobile={isMobile}>{i18n.t('headers:shirt_model')}</TitleH7>
      <StyledIframeContainer $isMobile={isMobile}>
        <StyledIframe
          src="https://virtualtouch.tech/style?id=0dba37c22db3e66b542806c6f4834d8588809e15"
          $isMobile={isMobile}
          title={i18n.t('headers:shirt_model')}
          loading="lazy"
        />
      </StyledIframeContainer>
      <StyledTextB2 $isMobile={isMobile} style={{ marginBottom: '50px' }}>
        {i18n.t('long_messages:message_shirt_model')}
      </StyledTextB2>
    </>
  );
}
