import { Row } from 'antd';
import i18n from 'i18next';
import { useNavigate } from 'react-router-dom';

import { TabTitle } from '../../components/TabTitle';
import { MobileSearchbar } from '../../components/search/MobileSearchbar';
import { MobileFabricSearch } from '../../components/search/fabric/MobileFabricSearch';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { TitleH7 } from '../../elements/typography/TitleH7';
import { MainLayout } from '../../layouts/Main';
import { routesNamedDict } from '../../routes';
import { SearchWrapper } from '../../styles/wrappers/SearchWrapper';
import { searchTextFabric } from '../../utils/backendRequests/textSearch/fabric';
import { stringValid } from '../../utils/validations/stringValidator';
import { TitleProp } from '../PagesTypes';

export function SearchMobileLaunchPage({ title }: TitleProp) {
  const navigate = useNavigate();

  const onSearch = (value: string, includeMLFields: boolean) => {
    if (stringValid(value, 150, i18n.t('column_titles:text_query'), false)) {
      const query = {
        text_query: value,
        exclude_ml_fields: !includeMLFields,
        new_search: true,
      };
      searchTextFabric(query, navigate, routesNamedDict);
    }
  };

  return (
    <MainLayout>
      <HeaderTitle
        title={i18n.t('page_titles:mobile_search_page')}
        isCenter={true}
      />
      <TabTitle title={title} />
      <Row align="middle" justify="center">
        <MobileSearchbar onSearch={onSearch} />
      </Row>
      <Row align="middle" justify="center">
        <SearchWrapper>
          <TitleH7>{i18n.t('headers:lace_image_search')}</TitleH7>
          <MobileFabricSearch />
        </SearchWrapper>
      </Row>
    </MainLayout>
  );
}
