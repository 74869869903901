import i18n from 'i18next';
import { useEffect, useState } from 'react';

import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { addCollectionFabrics } from '../../../utils/backendRequests/collection/addCollectionFabrics';
import {
  CollectionContainedResp,
  CollectionListItem,
} from '../../../utils/backendRequests/collection/CollectionTypes';
import { copyCollectionToCollection } from '../../../utils/backendRequests/collection/copyCollectionToCollection';
import { getCollections } from '../../../utils/backendRequests/collection/getCollections';
import { getContainedCollections } from '../../../utils/backendRequests/collection/getContainedCollections';
import { RootAuth, Setter } from '../../../utils/types/Types';
import { Modal } from '../../Modal';
import { CollectionList } from './CollectionList';
import { CreateCollectionModal } from './CreateCollectionModal';

type CopyToCollectionModalProps = {
  modal: { visible: boolean };
  setModal: Setter<{ visible: boolean }>;
  fabricIds?: string[];
  collectionId?: string | null;
  getCollectionsList?: () => void | null;
};

export function CopyToCollectionModal({
  modal,
  setModal,
  fabricIds = [],
  collectionId = null,
  getCollectionsList,
}: CopyToCollectionModalProps) {
  const [newCollectionModal, setNewCollectionModal] = useState({
    visible: false,
  });
  const [collections, setCollections] = useState<CollectionListItem[]>([]);
  const [containedFabricCollections, setContainedFabricCollections] =
    useState<CollectionContainedResp>();
  const role = useSelector((state: RootAuth) => state.auth?.role);

  const fetchCollections = async () => {
    await getCollections().then((response) => {
      setCollections(response);
    });
  };

  const _getContainedCollections = async () => {
    if (fabricIds.length !== 0) {
      const containedCollections = await getContainedCollections({
        fabricIds: fabricIds,
      });
      setContainedFabricCollections(containedCollections);
    } else if (collectionId !== null) {
      const containedCollections = await getContainedCollections({
        collectionSrcId: collectionId,
      });
      setContainedFabricCollections(containedCollections);
    }
  };

  useEffect(() => {
    if (modal.visible) {
      // Only fetch collections if the modal is visible
      fetchCollections();
      _getContainedCollections();
    } else {
      // Clear collections when modal is closed
      setCollections([]);
    }
  }, [modal.visible]);

  // Close modal
  const handleCancel = () => {
    setModal({
      visible: false,
    });
  };

  // Open create new collection modal to create collection and save selected fabrics to it. Also closes the AddToCollectionModal
  const createNewCollection = () => {
    setNewCollectionModal({
      visible: true,
    });
  };

  // Saves fabrics to selected existing collection
  const handleSaveToExisting = async (collection: CollectionListItem) => {
    /**
     * Either:
     * - Adds all the fabrics in a collection to an existing collection.
     * - Adds the seleted collection fabrics to an existing collection.
     */

    collectionId !== null
      ? await copyCollectionToCollection(collectionId, collection.id).then(
          () => {
            if (getCollectionsList) {
              getCollectionsList();
            }
            setModal({
              visible: false,
            });
          }
        )
      : await addCollectionFabrics(collection.id, fabricIds).then(() => {
          setModal({
            visible: false,
          });
        });
  };

  return (
    <>
      <Modal
        title={i18n.t('headers:add_to_collection')}
        open={modal.visible}
        onCancel={handleCancel}
        footer={
          <>
            <Button danger onClick={handleCancel}>
              {i18n.t('buttons:cancel')}
            </Button>
            {role === 'supplier' && (
              <Button type="primary" onClick={createNewCollection}>
                {i18n.t('headers:new_collection')}
              </Button>
            )}
          </>
        }
      >
        <CollectionList
          handleSave={handleSaveToExisting}
          collections={collections}
          containedFabricCollections={
            containedFabricCollections?.collection_ids as string[]
          }
          srcCollectionId={collectionId}
        />
      </Modal>
      {role === 'supplier' && (
        <CreateCollectionModal
          modal={newCollectionModal}
          setModal={setNewCollectionModal}
          setParentModal={setModal}
          fabricIds={fabricIds}
          collectionId={collectionId}
          getData={getCollectionsList || (() => {})} // Provide default function on fabric details page
        />
      )}
    </>
  );
}
