import { SharedCollectionPage } from '../pages/noAuth/SharedCollectionPage';
import { RouteProps } from './RouteTypes';

export const routesSharedCollection: RouteProps[] = [
  {
    path: '/shared_collection/:id',
    title: 'Shared Collection',
    key: 'shared_collection_page',
    page: SharedCollectionPage,
  },
];
