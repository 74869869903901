import { api } from '../api/api';
import { Fabrics } from './AdminTypes';

/**
 * Get all fabrics with a given ML Training label or null or all as admin.
 */
export async function getAllFabrics(
  trainingLabel?: string,
  isNull?: boolean
): Promise<Fabrics> {
  let param = {};
  if (isNull && trainingLabel) {
    param = { ml_training_label: 'null' };
  } else if (!isNull && trainingLabel) {
    param = { ml_training_label: trainingLabel };
  }
  const response = await api({
    method: 'GET',
    url: 'v1/superuser/training_label/fabric',
    params: param,
    showErrMsg: true,
  });
  return response;
}
