import { useEffect, useState } from 'react';
import { DeactivateSupplierModal } from '../../components/modal/supplier/DeactivateSupplierModal';
import { UpdateSupplierCommentModal } from '../../components/modal/supplier/UpdateSupplierCommentModal';
import { SuppliersList } from '../../components/table/suppliersTable/SuppliersList';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { MainLayout } from '../../layouts/Main';
import i18n from '../../plugins/i18n';
import { getSuppliers } from '../../utils/backendRequests/suppliers/getSuppliers';
import { SupplierType } from '../../utils/backendRequests/suppliers/supplierTypes';
import { TitleProp } from '../PagesTypes';

export function SuppliersPage({ title }: TitleProp) {
  const [data, setData] = useState<SupplierType[]>([]);
  const [edtiSupplierRecord, setEdtiSupplierRecord] =
    useState<SupplierType | null>(null);
  const [removeSupplierId, setRemoveSupplierId] = useState<string | null>(null);
  const [editCommentModal, setEditCommentModal] = useState({ visible: false });
  const [removeBrandModal, setRemoveBrandModal] = useState({ visible: false });

  const editSupplierCommentOnClick = (record: SupplierType) => {
    setEdtiSupplierRecord(record);
    setEditCommentModal({ visible: true });
  };

  const removeSupplierOnClick = (supplierId: string) => {
    setRemoveSupplierId(supplierId);
    setRemoveBrandModal({ visible: true });
  };

  const getSupplierData = async () => {
    const supplierData = await getSuppliers();
    setData(supplierData);
  };

  useEffect(() => {
    getSupplierData();
  }, []);

  return (
    <MainLayout>
      <HeaderTitle
        title={title}
        subtitle={i18n.t('page_subtitles:suppliers_page_sub')}
      />
      <SuppliersList
        title={title}
        data={data}
        editComment={editSupplierCommentOnClick}
        removeSupplier={removeSupplierOnClick}
      />
      {edtiSupplierRecord && (
        <UpdateSupplierCommentModal
          isModalOpen={editCommentModal}
          setIsModalOpen={setEditCommentModal}
          supplierBrand={edtiSupplierRecord}
          getSupplierData={getSupplierData}
        />
      )}
      {removeSupplierId && (
        <DeactivateSupplierModal
          isModalOpen={removeBrandModal}
          setIsModalOpen={setRemoveBrandModal}
          supplierId={removeSupplierId}
          getSupplierData={getSupplierData}
        />
      )}
    </MainLayout>
  );
}
