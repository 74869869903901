import { message } from 'antd';

import i18n from '../../../plugins/i18n';
import { api } from '../api/api';

/**
 * Resets the HopLun login credentials of the current user.
 * Returns true if the logout operation was successful; otherwise,
 * returns false.
 */

export async function logoutHoplunUser(): Promise<boolean> {
  try {
    return await api({
      method: 'DELETE',
      url: 'v1/user/hoplun_credentials',
      showErrMsg: true,
    }).then(() => {
      message.success(i18n.t('long_messages:hoplun_logout_success_message'));
      return true;
    });
  } catch (error) {
    return false;
  }
}
