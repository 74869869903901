import { message } from 'antd';

import i18n from '../../../plugins/i18n';
import { api } from '../api/api';

/**
 * Permanently deletes one or more colours by their IDs.
 */
export async function deleteColour(colour_ids: string[]): Promise<boolean> {
  const data = { colour_ids: colour_ids };
  try {
    return await api({
      method: 'DELETE',
      url: 'v1/colour',
      showErrMsg: true,
      body: data,
    }).then(() => {
      message.success(i18n.t('long_messages:message_colour_deleted_success'));
      return true;
    });
  } catch (error) {
    return false;
  }
}
