import {
  AppstoreOutlined,
  BgColorsOutlined,
  BorderOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  FolderOutlined,
  HomeOutlined,
  LoadingOutlined,
  MoreOutlined,
  PictureOutlined,
  ProjectOutlined,
  QuestionCircleOutlined,
  SafetyCertificateOutlined,
  ScissorOutlined,
  SearchOutlined,
  SendOutlined,
  ShoppingOutlined,
  SkinOutlined,
  SolutionOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UploadOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Dropdown, Tooltip } from 'antd';
import styled from 'styled-components';
import HopLunLogo from '../../assets/images/logos/hoplun.png';
import i18n from '../../plugins/i18n';
import { Color } from '../base/Color';

export const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 24px;
  color: ${Color.P5};
`;

export const GreenCheckedIcon = styled(CheckCircleOutlined)`
  color: green;
  font-size: 22px;
`;

export const RedUnCheckedIcon = styled(CloseCircleOutlined)`
  color: red;
  font-size: 22px;
`;

export const MobileLogoutIcon = styled.div`
  font-size: 24px;
  color: dimgrey;
`;

// Higher-order function to create styled icons
const createStyledIcon = (IconComponent: React.ComponentType) => styled(
  IconComponent
)<{ $isMobileFlag: boolean }>`
  font-size: ${({ $isMobileFlag }) =>
    $isMobileFlag ? '30px' : '24px'} !important;
  color: ${Color['G2']} !important;
`;

// Create styled icons using the higher-order function
export const FabricsPageIcon = createStyledIcon(BorderOutlined);
export const ImportPageIcon = createStyledIcon(UploadOutlined);
export const UserManagementPageIcon = createStyledIcon(UsergroupAddOutlined);
export const HangersPageIcon = createStyledIcon(ShoppingOutlined);
export const SuppliersPageIcon = createStyledIcon(SolutionOutlined);
export const HomePageIcon = createStyledIcon(HomeOutlined);
export const ProjectsPageIcon = createStyledIcon(ProjectOutlined);
export const TrimsPageIcon = createStyledIcon(ScissorOutlined);
export const GarmentsPageIcon = createStyledIcon(SkinOutlined);
export const UploadsPageIcon = createStyledIcon(PictureOutlined);
export const CertificationsPageIcon = createStyledIcon(
  SafetyCertificateOutlined
);
export const ColoursPageIcon = createStyledIcon(BgColorsOutlined);
export const BinPageIcon = createStyledIcon(DeleteOutlined);
export const HistoryPageIcon = createStyledIcon(SearchOutlined);
export const CollectionsPageIcon = createStyledIcon(FolderOutlined);

const HoplunPortalIconStyle = styled.img<{ $isMobileFlag: boolean }>`
  height: ${({ $isMobileFlag }) =>
    $isMobileFlag ? '30px' : '24px'} !important;
  padding-right: 9px;
`;

type HoplunPortalIconProps = {
  isMobileFlag: boolean;
};

export const HoplunPortalIcon = ({ isMobileFlag }: HoplunPortalIconProps) => {
  return (
    <HoplunPortalIconStyle
      $isMobileFlag={isMobileFlag}
      alt="HopLun Logo"
      src={HopLunLogo}
    />
  );
};

interface HelpIconProps {
  title?: string;
  fontSize?: string;
  margin?: string;
  pos?: 'top' | 'bottom' | 'left' | 'right';
}

// Ensure proper prop typing for styled component
const StyledQuestionIcon = styled(QuestionCircleOutlined)<HelpIconProps>`
  font-size: ${({ fontSize }) => fontSize || '14px'};
  margin: ${({ margin }) => margin || '0 3px 0 -3px'};
  cursor: pointer;
`;

export const HelpIcon = ({ title, fontSize, margin, pos }: HelpIconProps) => (
  <Tooltip title={title} placement={pos || 'top'}>
    <StyledQuestionIcon fontSize={fontSize} margin={margin} />
  </Tooltip>
);

export const UserManagementHelpIcon = ({
  fontSize,
  margin,
  pos,
}: HelpIconProps) => (
  <Tooltip
    title={
      <ul style={{ paddingLeft: '20px', margin: 0 }}>
        <li> {i18n.t('long_messages:managers_description')}</li>
        <li> {i18n.t('long_messages:editors_description')}</li>
        <li> {i18n.t('long_messages:viewers_description')}</li>
      </ul>
    }
    placement={pos || 'right'}
    overlayStyle={{ maxWidth: '350px', width: '350px' }}
  >
    <StyledQuestionIcon fontSize={fontSize} margin={margin} />
  </Tooltip>
);

export const ProfileDropDownIcon = styled(UserOutlined)`
  height: 100%;
  padding-right: 10px;
`;

interface LogoutDropDownIconProps {
  children: React.ReactNode;
  menu: {
    items: {
      key: string;
      label: React.ReactNode;
    }[];
    onClick: ({ key }: { key: string }) => void;
  };
}

export const LogoutDropDownIcon = ({
  children,
  menu,
}: LogoutDropDownIconProps) => {
  return (
    <Dropdown
      overlayStyle={{ width: 'auto', minWidth: '150px' }}
      placement="bottomRight"
      arrow
      menu={menu}
    >
      {children}
    </Dropdown>
  );
};

export const SendLinkIcon = styled(SendOutlined)``;

export const DesignerBrandsIcon = styled(TeamOutlined)`
  font-size: 22px;
`;

export const SendMsgIcon = styled(SendOutlined)`
  margin-left: 6px;
`;

export const MoreColoursIcon = styled(MoreOutlined)`
  font-weight: bold;
  color: #4a4a4a; /* Dark grey */
  transform: rotate(90deg);
`;

export const TableViewIcon = styled(UnorderedListOutlined)`
  margin-top: 5px;
  font-size: 1.05rem;
`;

export const GalleryViewIcon = styled(AppstoreOutlined)`
  margin-top: 5px;
  font-size: 1.05rem;
`;
