import { api } from '../api/api';

/**
 * Gets possible hanger request status types.
 */
export const getStatusTypes = async (): Promise<Record<string, string>> => {
  return await api({
    method: 'GET',
    url: `v1/list/hanger_request_status`,
    showErrMsg: true,
  });
};
