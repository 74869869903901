import { returnOrderedFields } from '../../../utils/fabrics/parseFabricFields';
import {
  BackendDateFilter,
  BackendFilterProps,
  BackendNumberFilter,
} from '../FilterTypes';

export const createTransformedFields = (
  filterableFields: BackendFilterProps
) => {
  const transformedInitialFields: BackendFilterProps = {}; // Store transformed fields
  const filterableFieldsKeys = Object.keys(filterableFields);
  const groupedFilterableFields = returnOrderedFields(filterableFieldsKeys);

  for (const [key, filterableValue] of Object.entries(filterableFields)) {
    const matchingGroup = groupedFilterableFields.find(
      (groupedField) => groupedField.name === key
    );
    // If a match is found, set the grouping property
    if (matchingGroup) {
      filterableValue.grouping = matchingGroup.grouping as string;
    }
    if (filterableValue.filter_type === 'number') {
      // For number type, create a values array with min and max
      const filterObject = filterableValue as BackendNumberFilter;
      const numberMin = filterObject.min;
      const numberMax = filterObject.max;
      filterableValue.required = !filterableValue.has_null;
      transformedInitialFields[key] = {
        ...filterObject,
        values: [numberMin, numberMax], // Set min and max in values array
      };
    } else if (filterableValue.filter_type === 'date') {
      // For date type, create a values array with min and max
      const filterObject = filterableValue as BackendDateFilter;
      const dateMin = filterObject.min;
      const dateMax = filterObject.max;
      filterableValue.required = !filterableValue.has_null;
      transformedInitialFields[key] = {
        ...filterObject,
        values: [dateMin, dateMax], // Set min and max in values array
      };
    } else {
      // For other types, leave the object unchanged
      filterableValue.required = !filterableValue.has_null;
      transformedInitialFields[key] = filterableValue;
    }
  }
  return transformedInitialFields; // Return the transformed fields
};
