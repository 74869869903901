import { message } from 'antd';
import i18n from 'i18next';
import { MsgProp } from '../../types/Types';
import { api } from '../api/api';

/**
 * Creates a certification details entry for a certification by uploading a file.
 * This certifications details belongs to the supplier brand and will be pending for review.
 */
export async function createCertDetails(
  certification_id: string,
  certification_file: FormData
): Promise<MsgProp> {
  return await api({
    method: 'POST',
    url: `v1/certification/${certification_id}/details`,
    body: certification_file,
    showErrMsg: true,
  }).then((res: MsgProp) => {
    message.success(
      i18n.t('long_messages:message_cert_file_upload_under_review')
    );
    return res;
  });
}
