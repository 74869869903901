import { Checkbox, Col, Row, Space } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { Color } from '../base/Color';
import { FontSize, FontWeight } from '../base/Font';
import { TextB1 } from '../typography/TextB1';
import { TextB2 } from '../typography/TextB2';
import { TextB3 } from '../typography/TextB3';
import { TitleH7 } from '../typography/TitleH7';

// Styled component for the horizontal Space with default props
export const StyledHorizontalSpace = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 50px;
`;

export const FabricFormAttachmentContainer = styled(Col)`
  display: flex;
  align-items: center;
`;

export const AttachmentsFormText = styled(TextB3)`
  width: 70%;
`;

export const UploadFormText = styled(TextB1)`
  font-weight: 600;
`;

export const MobileUploadContainer = styled.div`
  width: auto;
`;

export const FabricFormUploadContainer = styled(Col)`
  width: 50%;
  margin-left: 10%;
`;

// Styled component for the vertical Space with default props
export const StyledVerticalSpace = styled.div`
  width: 80%;
  margin-left: 20px;
`;

export const FabricCardText = styled(TextB2)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

// Styled Fabric Technical Tab
const StyledRow = styled(Row)`
  margin-bottom: 16px;
`;

// Styled Col wrapper component
const StyledCol = styled(Col)`
  span: 24;
`;

// Define the props type, including children
interface TechnicalFieldsWrapperProps {
  children: ReactNode;
}

// Wrapper component that accepts children
export const TechnicalFieldsWrapper = ({
  children,
}: TechnicalFieldsWrapperProps) => {
  return (
    <StyledRow gutter={[16, 16]}>
      <StyledCol>{children}</StyledCol>
    </StyledRow>
  );
};

export const StyledTechicalField = styled.div`
  white-space: pre-wrap;
  margin-right: 10px;
  color: ${Color.G5} !important;
  font-size: ${FontSize.Body1};
  font-weight: ${FontWeight.Regular};
  line-height: 24px;
  white-space: pre-line;
`;

// Show Fabric Card
export const FabricCardContainer = styled.div<{ $isMobile: boolean }>`
  cursor: pointer;
  width: ${({ $isMobile }) => ($isMobile ? '70vw' : '12vw')};
  margin: ${({ $isMobile }) => ($isMobile ? '0 5px' : '0 10px')};
`;

export const FabricCardTextContainer = styled.div<{ $isMobile: boolean }>`
  ${({ $isMobile }) =>
    $isMobile &&
    `
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`;

// Fabric Technical Fields
export const DesktopTechnicalFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
`;

export const TechnicalFieldNameText = styled.div`
  font-weight: bold;
`;

export const GenericTechnicalFieldNameText = styled.span`
  font-weight: bold;
`;

export const TechnicalFieldsText = styled(TitleH7)`
  max-width: 180px;
  margin-left: 8px;
  margin-bottom: 0;
  color: ${Color.Logo} !important;
`;

export const TechnicalFieldsRowDesktop = styled(Row)`
  display: flex;
  flex-wrap: wrap;
`;

export const DesktopChunkedCol = styled(Col)`
  flex: 1;
  min-width: 300px;
`;

export const DesktopChunkedItemCol = styled(Col)`
  width: 100%;
  margin-top: 8px;
`;

export const MobileTechnicalFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TechnicalMobileFieldsText = styled(TitleH7)`
  max-width: 150px;
  margin-left: 10px;
  margin-bottom: 8px;
  color: ${Color.Logo} !important;
`;

export const TechnicalFieldsRowMobile = styled(Row)`
  max-height: unset;
`;

export const MobileGroupItemCol = styled(Col)`
  flex: 1;
  min-width: 100%;
`;

export const MobileFieldContainer = styled.div`
  width: 100%;
  margin-top: 8px;
  padding: 4px;
  margin-left: 8px;
`;

export const NoGroupTechnicalTitle = styled(TitleH7)`
  max-width: 150px;
  margin-left: 8px;
  color: ${Color.Logo} !important;
`;

export const NoGroupFieldContainer = styled.div`
  margin-left: 8px;
`;

export const TechnicalTitleFieldContainer = styled(StyledTechicalField)`
  margin: 0;
  max-width: 250px;
`;

export const TechnicalColourContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TechnicalObjectArrayContainer = styled(StyledTechicalField)`
  display: flex;
  flex-direction: column;
  margin-top: 0px;
`;

export const TechnicalFieldsGroupedRow = styled(Row)<{ $isMobile: boolean }>`
  padding-bottom: ${({ $isMobile }) => ($isMobile ? '80px' : '0')};
`;

export const NonGroupedContainer = styled.div<{ $hasGroups: boolean }>`
  margin-top: ${({ $hasGroups }) => ($hasGroups ? '20px' : '-50px')};
  margin-left: -6px;
`;

// Edit/Create fabric form
export const FormTitleFieldsText = styled(TitleH7)`
  font-size: 18px;
`;

// Basic fabric fields for fabric details
export const DesktopBasicFieldsContainer = styled.div`
  margin-bottom: 25px;
`;

export const BasicFieldsTextContainer = styled(TextB2)`
  word-wrap: break-word;
`;

export const BasicFieldsBoldText = styled.span`
  font-weight: bold;
`;

// Fabric table generic grouping
export const GroupingTitleContainer = styled.div`
  text-align: center;
`;

// Fabric Gallery
export const FabricSwitchContainer = styled.div`
  width: 100%;
`;

export const SelectGalleryCardsContainer = styled(Space)`
  margin-left: 20px;
`;

export const SelectGalleryCardsCheckbox = styled(Checkbox)`
  margin-bottom: 16px;
  font-size: 16px;
`;

export const ToggleButtonsContainer = styled(Col)`
  margin-right: 35px;
  margin-top: 10px;
`;

export const FabricFormPaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding-bottom: 30px;
`;

export const ExportFabricsExcelContainer = styled.div`
  padding: 10px 0;
  text-align: justify;
`;

export const PDFFieldsTextContainer = styled(Space)`
  padding-bottom: 10px;
  margin-top: 10px;
`;

export const ImageSizeContainer = styled(Row)`
  margin-top: 5px;
`;
