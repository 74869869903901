import i18n from 'i18next';
import { useState } from 'react';

import { AddToProjectModal } from '../../modal/project/AddToProjectModal';

type CopyProjectToProjectLinkProps = {
  srcProjectId: string;
  getNewProjects: () => Promise<void>;
};

export function CopyProjectToProjectLink({
  srcProjectId,
  getNewProjects,
}: CopyProjectToProjectLinkProps) {
  const [projectModal, setProjectModal] = useState({ visible: false });
  return (
    <>
      <a
        onClick={() =>
          setProjectModal({
            visible: true,
          })
        }
        role="button"
      >
        {i18n.t('buttons:copy_to_project')}
      </a>
      <AddToProjectModal
        modal={projectModal}
        setModal={setProjectModal}
        srcProjectId={srcProjectId}
        getNewProjects={getNewProjects}
      />
    </>
  );
}
