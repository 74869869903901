import { api } from '../api/api';
import { sharedUsersProp } from './ProjectTypes';
/**
 * Gets the list of users that the project is shared with
 */
export async function getSharedUsers(
  projectId: string
): Promise<sharedUsersProp> {
  const response = await api({
    method: 'GET',
    url: `v1/project/${projectId}/shared_with_users`,
    showErrMsg: true,
  });
  const sharedUsers = Object.entries(response).map(([key, value]) => ({
    key,
    value,
  })) as sharedUsersProp;
  return sharedUsers;
}
