import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TableGeneric } from '../../../components/table/TableGeneric';
import { columnFields } from '../../../components/table/TableTypes';
import { RootAuth, RootState } from '../../../utils/types/Types';
import { TabTitle } from '../../TabTitle';
import { FabricLibraryListProps } from '../TableTypes';
import { columnsDesktop } from './ColumnsDesktop';
import { columnsMobile } from './ColumnsMobile';

export function FabricLibraryList({
  data,
  title,
  getLibraryList,
}: FabricLibraryListProps) {
  const [columns, setColumns] = useState<columnFields[]>([]);
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const account = useSelector((state: RootAuth) => state.auth.accountType);

  useEffect(() => {
    if (!isMobileFlag) {
      setColumns(columnsDesktop(account, getLibraryList));
    } else {
      setColumns(columnsMobile(account, getLibraryList));
    }
  }, [isMobileFlag]);

  return (
    <>
      {/* if no data's fed in, show parent component's title for browser tab, i.e. use 'Fabric' instead of 'Library :id', otherwise child will overide parent tab's title */}
      {data[0] && <TabTitle title={title} />}
      <TableGeneric columns={columns} data={data} isBackendPaginated={false} />
    </>
  );
}
