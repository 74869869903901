import styled from 'styled-components';

import { Color } from '../base/Color';
import { FontWeight } from '../base/Font';

export const WarningText = styled.p`
  color: ${Color.Red} !important;
  font-weight: ${FontWeight.Regular};
  line-height: 24px;
  white-space: pre-line;
`;
