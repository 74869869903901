import { TransformedFilterProps } from '../../../components/filter/FilterTypes';
import { FabricTableReqProps, TableStateObj } from '../../types/Types';
import { api } from '../api/api';
import { FabricResponse } from './ProjectTypes';

/**
 * Returns fabrics of a Fabric Project, specified by name.
 * Also indicates if the user owns the project.
 */
export async function getFabrics(
  name: string,
  tableState: TableStateObj,
  filterableColumns: TransformedFilterProps
): Promise<FabricResponse> {
  const params: FabricTableReqProps = {
    fabrics_page: tableState.current,
    fabrics_page_size: tableState.pageSize,
  };
  // Add sort parameters only if both sortField and sortOrder have values
  if (tableState.sortField && tableState.sortOrder) {
    params.fabrics_sort_by = tableState.sortField;
    params.fabrics_sort_order = tableState.sortOrder;
  }
  if (filterableColumns) {
    params.fabrics_filter = JSON.stringify(filterableColumns);
  }
  return await api({
    method: 'GET',
    url: `v1/project/${name}`,
    params: params,
    showErrMsg: true,
  });
}
