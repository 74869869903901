import { api } from '../api/api';
import { getCertObj } from './CertificationTypes';

/**
 * Gets all certifications available to the supplier user.
 */
export async function getCertifications(): Promise<getCertObj[]> {
  return await api({
    method: 'GET',
    url: `v1/certification`,
    showErrMsg: true,
  }).then((res: getCertObj[]) => {
    return res;
  });
}
