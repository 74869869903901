import { Col, Row } from 'antd';
import { useState } from 'react';

import placeholderImage from '../assets/images/placeholder-image.png';
import {
  StyledImageMain,
  StyledImageSide,
} from '../elements/images/StyledImages';
import {
  DisplayImagesProps,
  ImageSideComponentProps,
  ImageSideMainProps,
} from './table/TableTypes';

export function DisplayImages({ ImageArray, isMobile }: DisplayImagesProps) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const filteredImageArray = ImageArray.filter((image) => image !== null);

  const ImageSideComponent = ({ keyValue, src }: ImageSideComponentProps) => {
    return (
      <StyledImageSide
        $itemKey={keyValue}
        isMobile={isMobile}
        $isSelected={selectedImageIndex}
        src={src}
        onClick={() => {
          setSelectedImageIndex(keyValue);
        }}
      />
    );
  };

  const ImageSideMain = ({ src }: ImageSideMainProps) => {
    return <StyledImageMain src={src} isMobile={isMobile} />;
  };

  if (isMobile) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Row justify="center" style={{ marginBottom: '20px' }}>
          <Col>
            <ImageSideMain
              src={
                filteredImageArray[selectedImageIndex]
                  ? filteredImageArray[selectedImageIndex]
                  : placeholderImage
              }
            />
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            {filteredImageArray.map((imageURL, i) => {
              const hasImage = imageURL !== null;
              return (
                <ImageSideComponent
                  key={i}
                  keyValue={i}
                  src={hasImage ? imageURL : placeholderImage}
                />
              );
            })}
          </Col>
        </Row>
      </div>
    );
  } else {
    return (
      <Row>
        <Col>
          {filteredImageArray.map((imageURL, i) => {
            const hasImage = imageURL !== null;
            return (
              <Row key={i} style={{ marginBottom: '10px' }}>
                <ImageSideComponent
                  keyValue={i}
                  src={hasImage ? imageURL : placeholderImage}
                />
              </Row>
            );
          })}
        </Col>

        <Col offset={1}>
          <ImageSideMain
            src={
              filteredImageArray[selectedImageIndex]
                ? filteredImageArray[selectedImageIndex]
                : placeholderImage
            }
          />
        </Col>
      </Row>
    );
  }
}
