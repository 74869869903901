import { Row } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';

import { CreateInspirationModal } from '../../components/modal/inspirations/CreateInspirationModal';
import { InspirationsList } from '../../components/table/inspirationsList/InspirationsList';
import { TabTitle } from '../../components/TabTitle';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { ButtonBasic } from '../../elements/buttons/StyledButtons';
import { MainLayout } from '../../layouts/Main';
import {
  getInspirations,
  inspirationResponse,
} from '../../utils/backendRequests/inspirations/getInspirations';
import { TitleProp } from '../PagesTypes';

export function InspirationsListPage({ title }: TitleProp) {
  const [data, setData] = useState<inspirationResponse>([]);
  const [modal, setModal] = useState({ visible: false });

  const newOnClick = () => {
    {
      setModal({ visible: true });
    }
  };

  const _getInspirations = async () => {
    const inspirationData = await getInspirations();
    setData(inspirationData);
  };

  useEffect(() => {
    _getInspirations();
  }, []);

  return (
    <MainLayout>
      <HeaderTitle
        title={i18n.t('page_titles:inspirations_page')}
        subtitle={i18n.t('page_subtitles:inspirations_sub')}
      />
      <TabTitle title={title} />
      <Row justify="start">
        <ButtonBasic onClick={newOnClick}>
          {i18n.t('buttons:create')}
        </ButtonBasic>
      </Row>
      <InspirationsList
        title={title}
        data={data}
        getInspirations={_getInspirations}
      />
      <CreateInspirationModal
        modal={modal}
        onSuccess={_getInspirations}
        setModal={setModal}
      />
    </MainLayout>
  );
}
