import { ProjectData } from '../../../components/table/TableTypes';
import { api } from '../api/api';

/**
 * Makes an API call for available projects the current
 * user can view.
 */
export async function getProjects(): Promise<ProjectData[]> {
  let results: ProjectData[] = [];
  const response = await api({
    method: 'GET',
    url: 'v1/project',
    showErrMsg: true,
  });
  results = response;
  return results;
}
