import { Card, Checkbox, Col, Divider, Image, Row } from 'antd';
import styled from 'styled-components';

export const CardCoverContainer = styled.div``;

export const CardImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensure the container takes full width */
  height: 170px; /* Fixed height for the container */
  background-color: #f0f0f0; /* Optional background */
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: none;
  position: relative; /* Ensure the container stays within bounds */
`;

export const ImageCardCover = styled(Image)`
  width: 100%; /* Ensures the image takes the container's full width */
  height: 170px !important; /* Important needed to overwrite antd, scale image to fit */
  max-width: 100%; /* Prevent the image from overflowing beyond the container's width */
  object-fit: contain; /* Ensures the image maintains its aspect ratio and fits within the container */
  object-position: center; /* Centers the image within the container */
  border-radius: 0 !important; /* Important needed to overwrite antd border */
`;

export const CardSelectCheckbox = styled(Checkbox)`
  position: absolute;
  top: 8px;
  left: 8px;
  transform: scale(1.3);
  z-index: 1;
`;

export const GenericGalleryCard = styled(Card)<{ $isMobile: boolean }>`
  width: ${({ $isMobile }) => ($isMobile ? '350px' : '250px')};
  cursor: default;
  height: 325px;
  margin-top: 20px;
  border: 2px solid #ddd;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ColoursContainer = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 12px;
`;

export const ColourCircle = styled.div<{ $colorHex: string }>`
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid black;
  background-color: ${({ $colorHex }) => $colorHex};
  display: flex;
  justify-content: center;
`;

export const CardFooterContainer = styled.div`
  position: absolute;
  bottom: 25px;
  width: 80%;
`;

export const FooterDivider = styled(Divider)`
  margin: 16px 0;
`;

export const FooterButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
`;

export const GalleryViewContainer = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const GalleryCardCol = styled(Col)<{ $isMobile: boolean }>`
  display: flex;
  justify-content: ${({ $isMobile }) => ($isMobile ? 'center' : 'flex-start')};
  @media (min-width: 1840px) {
    // Manually handle extra large screens bigger than antd xxl (>1600px)
    flex: 0 0 16.66%; // Equivalent to 4/24
    max-width: 16.66%;
  }
`;

export const PaginationContainer = styled(Row)`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
  padding-bottom: 20px;
`;

export const EmptyGalleryContainer = styled.div`
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopoverContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 5px;
  width: 30px;
`;
