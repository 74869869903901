import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  EditHangerRequestContainer,
  StatusSelector,
} from '../../../elements/modals/StyledModal';
import i18n from '../../../plugins/i18n';
import { editHangerRequest } from '../../../utils/backendRequests/hangers/editHangerRequest';
import { getStatusTypes } from '../../../utils/backendRequests/list/getStatusTypes';
import { RootAuth, Setter } from '../../../utils/types/Types';
import { hangerRequestResp } from '../../table/TableTypes';

interface HangersEditProps {
  data: hangerRequestResp[];
  isModalOpen: boolean;
  getHangerData: () => void;
  closeModal: () => void;
  setSelectedRowKeys: Setter<number[]>;
}

export type dropDownDataType = {
  value: number;
  label: string;
}[];

// Add a type for the selected status
type SelectedStatus = number | null;

export const HangersEditModal = ({
  data,
  isModalOpen,
  closeModal,
  getHangerData,
  setSelectedRowKeys,
}: HangersEditProps) => {
  const [selectedStatus, setSelectedStatus] = useState<SelectedStatus>(null);
  const [filteredDropdownOptions, setFilteredDropdownOptions] =
    useState<dropDownDataType>();
  const [statusDropdownData, setStatusDropdownData] =
    useState<dropDownDataType | null>(null);
  const role = useSelector((state: RootAuth) => state.auth?.role);

  const getDropdownData = async () => {
    const res = await getStatusTypes();
    const dropdownData = Object.entries(res).map(([value, label]) => ({
      value: parseInt(value),
      label,
    }));
    setStatusDropdownData(dropdownData);
  };

  useEffect(() => {
    getDropdownData();
  }, []);

  // Effect to filter dropdown options based on role
  useEffect(() => {
    if (statusDropdownData) {
      const filteredOptions = statusDropdownData.filter((option) => {
        if (role === 'supplier') {
          return ['cancelled', 'sent', 'in_progress'].includes(option.label);
        } else if (role === 'designer') {
          return ['cancelled', 'received', 'in_progress'].includes(
            option.label
          ); // Ensure this matches your data
        }
        return false;
      });

      setFilteredDropdownOptions(filteredOptions);
    }
  }, [statusDropdownData, role]);

  // Effect to set the selected status when the modal opens
  useEffect(() => {
    if (filteredDropdownOptions && data) {
      const currentStatus = filteredDropdownOptions.find(
        (option) => option.label === data[0].status
      );
      setSelectedStatus(currentStatus ? currentStatus.value : null);
    }
  }, [filteredDropdownOptions, data]);

  const handleOk = async () => {
    const hangerRequestIds = data.map((item) => item.hanger_request_id);
    if (selectedStatus) {
      const success = await editHangerRequest(hangerRequestIds, selectedStatus);
      if (success) {
        getHangerData();
        setSelectedRowKeys([]);
        closeModal();
      }
    }
  };

  const handleCancel = () => {
    closeModal();
  };

  return (
    <>
      {filteredDropdownOptions && (
        <Modal
          title={i18n.t('headers:edit_status')}
          open={isModalOpen}
          width={600}
          onCancel={handleCancel}
          cancelButtonProps={{ danger: true }}
          onOk={handleOk}
        >
          <EditHangerRequestContainer>
            <StatusSelector
              value={selectedStatus}
              onChange={setSelectedStatus}
              placeholder={i18n.t('headers:select_status')}
              options={filteredDropdownOptions.map(({ label, value }) => ({
                label: `hanger_status_values:${label}`,
                value,
              }))}
              isTranslatable={true}
            />
          </EditHangerRequestContainer>
        </Modal>
      )}
    </>
  );
};
