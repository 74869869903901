import { Button, Checkbox, Col, Input, Pagination, Row, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HelpIcon } from '../../../elements/icons/StyledIcons';
import {
  CollectionFieldsContainer,
  SelectableFieldContainer,
} from '../../../elements/modals/StyledModal';
import { updateCollectionDetails } from '../../../utils/backendRequests/collection/updateCollectionDetails';
import {
  parseFabricTitles,
  returnOrderedFields,
} from '../../../utils/fabrics/parseFabricFields';
import { RootAuth } from '../../../utils/types/Types';
import { stringValid } from '../../../utils/validations/stringValidator';
import { formFieldsProp } from '../../form/FormTypes';
import { Modal } from '../../Modal';
import { EditCollectionModalWithLinkProps } from '../../table/collectionList/CollectionListTypes';
import {
  defaultFieldsToShowOptions,
  fieldToShowOptions,
} from './CreateCollectionModal';

const MAX_GROUPS_PER_PAGE = 2; // Maximum of 2 groups per page (except for 'basic')

export function EditCollectionModalWithLink({
  record,
  getCollectionsList,
}: EditCollectionModalWithLinkProps) {
  const [modal, setModal] = useState<{ visible: boolean }>({ visible: false });
  const { name, id, description, fields_to_show } = record;
  const [newName, setNewName] = useState<string>(name);
  const [newDesc, setNewDesc] = useState<string | null>(description);
  const [currentPage, setCurrentPage] = useState<number>(1); // For pagination
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const [selectAllMode, setSelectAllMode] = useState<boolean>(false);
  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);

  const handleCancel = () => {
    setModal({ visible: false });
  };

  // Reset newName when name changes
  useEffect(() => {
    setNewName(name);
    setNewDesc(description);
    setSelectedFields(fields_to_show);
  }, [name, description, fields_to_show]);

  useEffect(() => {
    // Determine whether to show "Select All" or "Deselect All" based on current selected fields
    setSelectAllMode(
      selectedFields.length < combinedFieldOptions.length &&
        selectedFields.length > defaultFieldsToShowOptions.length
    );
  }, [selectedFields]);

  const handleSelectAll = () => {
    setSelectedFields((prev) => [
      ...new Set([
        ...prev,
        ...combinedFieldOptions.filter(
          (field) => !defaultFieldsToShowOptions.includes(field)
        ),
      ]),
    ]);
  };

  const handleDeselectAll = () => {
    setSelectedFields((prev) =>
      prev.filter((field) => defaultFieldsToShowOptions.includes(field))
    );
  };

  const handleToggleAll = () => {
    if (selectAllMode) {
      handleDeselectAll();
    } else {
      handleSelectAll();
    }
  };

  const handleOK = async () => {
    const isNameValid = stringValid(newName, 50, i18n.t('common:name'), false);
    const isDescriptionValid = stringValid(
      newDesc,
      500,
      i18n.t('column_titles:description'),
      true
    );
    // Only send non default fields to show to backend
    const selectedCustomFields = selectedFields.filter(
      (field) => !defaultFieldsToShowOptions.includes(field)
    );

    if (isNameValid && isDescriptionValid) {
      const descriptionValue = newDesc === '' ? null : newDesc;
      const response = await updateCollectionDetails(
        id,
        newName,
        descriptionValue,
        selectedCustomFields
      );
      if (response) {
        setModal({ visible: false });
        getCollectionsList();
      } else {
        setModal({ visible: true });
      }
    }
  };

  const showModal = () => {
    setModal({ visible: true });
    setNewName(name);
  };

  const handleFieldChange = (field: string, checked: boolean) => {
    if (checked) {
      setSelectedFields((prev) => [...prev, field]);
    } else {
      setSelectedFields((prev) => prev.filter((item) => item !== field));
    }
  };

  // Paginate the combinedFieldOptions
  const combinedFieldOptions = [
    ...defaultFieldsToShowOptions,
    ...fieldToShowOptions,
  ];

  const combinedColumns = returnOrderedFields(combinedFieldOptions);

  const groupColumnsByType = (columns: formFieldsProp[]) => {
    return columns.reduce(
      (acc, column) => {
        const group = column.grouping as string;
        if (!acc[group]) {
          acc[group] = [];
        }
        acc[group].push(column);
        return acc;
      },
      {} as Record<string, formFieldsProp[]>
    );
  };

  const groupedColumns = groupColumnsByType(combinedColumns);

  const getPaginatedColumns = (
    groupedColumns: Record<string, formFieldsProp[]>,
    currentPage: number
  ) => {
    const groups = Object.entries(groupedColumns);

    // Separate basic group from others
    const basicGroup = groups.find(([group]) => group === 'basic');
    const otherGroups = groups.filter(([group]) => group !== 'basic');

    // Adjust pagination based on group types
    const isBasicPage = currentPage === 1 && basicGroup;

    // Calculate start and end indices for pagination
    const startIndex = isBasicPage
      ? 0
      : (currentPage - 2) * MAX_GROUPS_PER_PAGE; // Subtract 2 for other groups
    const endIndex = startIndex + MAX_GROUPS_PER_PAGE;

    // Get the paginated groups from the other groups
    const paginatedOtherGroups = otherGroups
      .slice(startIndex, endIndex)
      .map(([group, columns]) => ({
        group,
        columns,
      }));

    // Combine groups: Add basic group (if it's a basic page) and an empty grouping
    const combinedGroups = isBasicPage
      ? [
          { group: basicGroup[0], columns: basicGroup[1] }, // Add the basic group
        ]
      : paginatedOtherGroups; // If not basic page, just return other groups

    // Calculate total pages
    const totalPages = Math.ceil(
      // Count the groups for pagination
      (basicGroup ? 1 : 0) + Math.ceil(otherGroups.length / MAX_GROUPS_PER_PAGE)
    );

    return { combinedGroups, totalPages }; // Return both combined groups and total pages
  };

  const { combinedGroups, totalPages } = getPaginatedColumns(
    groupedColumns,
    currentPage
  );

  const handleGroupSelectAll = (group: string) => {
    const columns = groupedColumns[group];
    const allFieldNames = columns.map((col) => col.name);
    const allFieldsSelected = allFieldNames.every((name) =>
      selectedFields.includes(name)
    );

    if (allFieldsSelected) {
      // Deselect all fields in the group
      setSelectedFields((prev) =>
        prev.filter((name) => !allFieldNames.includes(name))
      );
    } else {
      // Select all fields in the group
      setSelectedFields((prev) => [
        ...prev,
        ...allFieldNames.filter((name) => !prev.includes(name)),
      ]);
    }
  };

  const renderGroups = combinedGroups.map(({ group, columns }) => {
    // Split the columns into three separate arrays for each column
    const columnCount = 3;
    const columnsPerGroup = Math.ceil(columns.length / columnCount);
    const columnsInGroups = Array.from({ length: columnCount }, (_, i) =>
      columns.slice(i * columnsPerGroup, (i + 1) * columnsPerGroup)
    );

    return (
      <Col span={24} key={group}>
        <Space>
          <h4 style={{ margin: '0 0 0px 0' }}>{i18n.t(`headers:${group}`)}</h4>
          <Checkbox
            checked={columns.every((col) => selectedFields.includes(col.name))}
            onChange={() => handleGroupSelectAll(group)}
          ></Checkbox>
        </Space>
        <Row style={{ marginTop: '10px' }} gutter={[8, 8]}>
          {/* Render each group of columns */}
          {columnsInGroups.map((colGroup, colIndex) => (
            <Col
              key={`group-col-${group}-${colIndex}`}
              span={Math.floor(24 / columnCount)} // Each column takes up 1/3 of the width
            >
              {colGroup.map((col, index) => (
                <SelectableFieldContainer key={`${group}-${col.name}-${index}`}>
                  <Checkbox
                    checked={
                      selectedFields.includes(col.name) ||
                      defaultFieldsToShowOptions.includes(col.name)
                    }
                    disabled={defaultFieldsToShowOptions.includes(col.name)}
                    onChange={(e) =>
                      handleFieldChange(col.name, e.target.checked)
                    }
                  />
                  <span style={{ marginLeft: '8px' }}>
                    {parseFabricTitles(col.name, measurementUnit)}
                  </span>
                </SelectableFieldContainer>
              ))}
            </Col>
          ))}
        </Row>
      </Col>
    );
  });

  return (
    <>
      <a role="button" onClick={showModal}>
        {i18n.t('buttons:edit')}
      </a>
      <Modal
        title={i18n.t('headers:edit_collection')}
        open={modal.visible}
        width={700}
        onCancel={handleCancel}
        footer={
          <>
            <Button onClick={handleToggleAll}>
              {selectAllMode
                ? i18n.t('buttons:deselect_all')
                : i18n.t('buttons:select_all')}
            </Button>
            <Button danger onClick={handleCancel}>
              {i18n.t('buttons:cancel')}
            </Button>
            <Button type="primary" onClick={handleOK}>
              {i18n.t('buttons:ok')}
            </Button>
          </>
        }
      >
        {`${i18n.t('common:name')}:`}
        <Input
          allowClear
          onChange={(evt) => setNewName(evt.target.value)}
          value={newName}
          style={{ marginBottom: '10px' }}
        />
        {`${i18n.t('column_titles:description')}:`}
        <Input
          showCount
          value={newDesc !== null ? newDesc : ''}
          style={{ marginTop: '10px', marginBottom: '10px' }}
          onChange={(evt) => setNewDesc(evt.target.value)}
        />
        <Space style={{ paddingBottom: '10px' }}>
          {i18n.t('column_titles:fabric_fields_to_show')}
          <HelpIcon
            title={i18n.t('long_messages:message_fabric_show_coumns_message')}
            margin="0 0px 0 -3px"
          />
        </Space>
        <Row gutter={[8, 8]}>{renderGroups}</Row>
        <CollectionFieldsContainer>
          <Pagination
            current={currentPage}
            total={totalPages}
            pageSize={1}
            onChange={(page) => setCurrentPage(page)}
            style={{ marginTop: '20px', textAlign: 'center' }}
            showSizeChanger={false}
          />
        </CollectionFieldsContainer>
      </Modal>
    </>
  );
}
