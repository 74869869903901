import { api } from '../api/api';
import { BinContentsType } from './BinTypes';

/**
 * Gets the bin list object from the backend. This object contains
 * all the binned libraries, fabrics, projects and Uploads.
 * @returns {Object}
 */
export const getBinList = async (): Promise<BinContentsType> => {
  return api({ method: 'GET', url: 'v1/bin', showErrMsg: true });
};
