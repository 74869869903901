import { message } from 'antd';
import i18n from 'i18next';

import { api } from '../api/api';

export type CreateFabricsExportData = {
  library_id: string | null;
  project_id: string | null;
  collection_id: string | null;
  image_search_id: string | null;
  fabric_ids: string[] | null;
};

/**
 * Sends a request to export fabric data to an Excel file
 */
export async function createFabricsExport(
  data: CreateFabricsExportData
): Promise<void> {
  await api({
    method: 'POST',
    url: 'v1/fabric/export/excel',
    body: data,
    showErrMsg: true,
  });
  message.success(i18n.t('long_messages:message_export_fabrics_excel_success'));
}
