import { itemProp } from '../../../components/carousel/CarouselTypes';
import { api } from '../api/api';
/**
 * Finds fabrics similar to the fabric with the provided id
 * which the current user has access to. Is used for the recommendation
 * carousel. Saves result to a state.
 */
export async function getSimilarFabrics(fabricId: string): Promise<itemProp[]> {
  const response = await api({
    method: 'GET',
    url: `v1/fabric/${fabricId}/similar`,
    showErrMsg: true,
  });
  return response;
}
