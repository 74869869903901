import { api } from '../api/api';

/**
 * Makes an API call for available fabric libraries the current
 * user can view.
 */
export type libraryProp = {
  owner: string;
  name: string;
  is_owner: boolean;
  id: string;
  description: string | null;
  created_at: string;
};

export type LibraryListType = libraryProp[];

export async function getLibraries(): Promise<LibraryListType> {
  const response = await api({
    method: 'GET',
    url: 'v1/library/fabric',
    showErrMsg: true,
  });
  return response;
}
