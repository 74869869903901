import { Checkbox, Input, message } from 'antd';
import i18n from 'i18next';
import { useState } from 'react';
import { editFabrics } from '../../../utils/backendRequests/admin/editFabrics';
import { Modal } from '../../Modal';
import { UpdatingTrainingLabelModalProps } from '../ModalTypes';

/**
 * Modal component for updating the machine learning training label
 * of selected lace fabrics. Allows adding or removing the label.
 */
export function AdminUpdateTrainingLabelModal({
  isModalOpen,
  setIsModalOpen,
  hasSelected,
  selectedRowKeys,
  data,
  setData,
}: UpdatingTrainingLabelModalProps) {
  const [trainingLabelText, setTrainingLabelText] = useState<string | null>(
    null
  );
  const [removeLabel, setRemoveLabel] = useState(false);

  const handleSubmit = async () => {
    if (!hasSelected) {
      message.error(i18n.t('error_messages:no_rows_selected'));
      return;
    }
    const labelToUpdate = removeLabel ? null : trainingLabelText;

    // Map through selectedRowKeys to get the corresponding data rows
    const selectedDataRows = selectedRowKeys.map((key) => data[key - 1].id);

    const updatedFabrics = await editFabrics({
      fabricIds: selectedDataRows,
      label: labelToUpdate,
    });

    if (updatedFabrics) {
      message.success('Successfully updated training label.');
      setIsModalOpen(false);
      setData((prevData) =>
        prevData.map((fabric) => {
          if (selectedDataRows.includes(fabric.id)) {
            return { ...fabric, ml_training_label: labelToUpdate };
          }
          return fabric;
        })
      );
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title={i18n.t('headers:update_training_label')}
      open={isModalOpen}
      onOk={handleSubmit}
      onCancel={handleCancel}
      cancelButtonProps={{ danger: true }}
      okButtonProps={{
        disabled: trainingLabelText === '' && !removeLabel,
      }}
    >
      <span>{i18n.t('headers:ml_training_label')}:</span>
      <Input
        value={trainingLabelText as string}
        onChange={(e) => setTrainingLabelText(e.target.value)}
        disabled={removeLabel}
      />
      <Checkbox
        style={{ marginTop: '5px' }}
        onChange={(e) => setRemoveLabel(e.target.checked)}
      >
        {i18n.t('labels:remove_training_label')}
      </Checkbox>
    </Modal>
  );
}
