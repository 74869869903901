import { routes404 } from './404';
import { RouteProps, RoutesNamedDict } from './RouteTypes';
import { routesAdmin } from './admin';
import { allFabrics } from './allFabrics';
import { routesAuth } from './auth';
import { routesBin } from './bin';
import { routesBulkUpload } from './bulk_upload';
import { routesCertifications } from './certifications';
import { routesCollections } from './collections';
import { routesColours } from './colours';
import { routesFabrics } from './fabrics';
import { routesGarments } from './garments';
import { routesHangers } from './hangers';
import { routesHome } from './home';
import { routesInspirations } from './inspirations';
import { routesProjects } from './projects';
import { routesSearch } from './search';
import { routesSharedCollection } from './sharedCollection';
import { routesSuppliers } from './suppliers';
import { routesTrims } from './trims';
import { routesUserManagement } from './userManagement';
import { routesUserSettings } from './userSettings';

// Convert route title to uppercase with underscores
function parseNamedRoute(route: string) {
  return route.toUpperCase().replace(/ /g, '_');
}

// Routes for Sidebar
// Order of route groups, must be in the order of the sidebar's intended title order
export const getSidebarRoutes = (role: string | null) => {
  let sideBarRoutes = routesHome.concat(
    routesCollections,
    routesUserManagement,
    routesHangers,
    routesSearch,
    routesInspirations,
    routesBin
  );

  // Only suppliers will see the following items on the side bar
  if (role === 'supplier')
    sideBarRoutes = [
      ...sideBarRoutes,
      ...routesTrims,
      ...routesGarments,
      ...routesFabrics,
      ...routesColours,
      ...routesCertifications,
      ...routesBulkUpload,
    ];

  // Only designers will see the following items on the side bar
  if (role === 'designer')
    sideBarRoutes = [...sideBarRoutes, ...routesProjects, ...routesSuppliers];

  // Order of route groups
  const routeGroupOrder = [
    'home',
    'fabrics_page',
    'trims_page',
    'garments_page',
    'import_page',
    'collections_page',
    'projects_page',
    'history_page',
    'certifications_page',
    'colours_page',
    'inspirations_page',
    'user_management_page',
    'hangers_page',
    'suppliers_page',
    'bin_page',
  ];

  // Function to get group order index
  const getGroupOrderIndex = (key: string) => {
    const index = routeGroupOrder.indexOf(key);
    return index !== -1 ? index : routeGroupOrder.length;
  };

  sideBarRoutes.sort((a, b) => {
    const keyA = a.key || ''; // If key is not present, assign empty string
    const keyB = b.key || ''; // If key is not present, assign empty string

    const groupA = getGroupOrderIndex(keyA);
    const groupB = getGroupOrderIndex(keyB);

    return groupA - groupB;
  });

  return sideBarRoutes;
};

// Routes passed to React Router
let routes: RouteProps[] = [];
const routesNamedDict: RoutesNamedDict = {};

[
  routes404,
  routesHome,
  routesAuth,
  routesCertifications,
  routesTrims,
  routesGarments,
  routesColours,
  routesCollections,
  routesFabrics,
  routesBulkUpload,
  routesProjects,
  routesUserSettings,
  routesInspirations,
  routesSearch,
  routesAdmin,
  routesBin,
  routesUserManagement,
  routesHangers,
  routesSuppliers,
  allFabrics,
  routesSharedCollection,
].forEach((routeGroup) => {
  routeGroup.forEach((route) => {
    if (route.children) {
      route.children.forEach((tab) => {
        routes.push({
          path: route.path + tab.path,
          title: tab.title,
          page: tab.page,
        });
        routesNamedDict[parseNamedRoute(route.title + '_' + tab.title)] =
          route.path + tab.path;
      });
    }

    routes.push({
      path: route.path,
      title: route.title,
      page: route.page,
    });
    routesNamedDict[parseNamedRoute(route.title)] = route.path;
  });
});

routes = routes.concat(routes404);

export { routes, routesNamedDict };
