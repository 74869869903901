import { message } from 'antd';
import i18n from 'i18next';

import { Rule } from 'antd/es/form';
import { parseFabricTitles } from '../../utils/fabrics/parseFabricFields';
import {
  dateValidRangeRule,
  maxLengthRule,
  minMaxRule,
  requiredRule,
} from '../../utils/validations/formRules';
import { CompositeFieldCheckProps } from './FabricTypes';
import {
  fieldMaxLengths,
  fieldNumberMinMax,
  fieldValidDateRange,
  formFields,
} from './formFields';

export const fabricFieldRules = (measurementUnit: string | null) => {
  const rules: Record<string, Rule[]> = {};
  for (const field of formFields) {
    // initialize rules object with empty arrays for each field
    rules[field.name] = [];

    // Add required rule
    if (field.required)
      rules[field.name] = rules[field.name].concat(
        requiredRule(parseFabricTitles(field.name, measurementUnit))
      );
  }
  // Add max length rules
  for (const field in fieldMaxLengths) {
    rules[field] = rules[field].concat(
      maxLengthRule(
        parseFabricTitles(field, measurementUnit),
        fieldMaxLengths[field]
      )
    );
  }
  // Add number min/max rule
  for (const field in fieldNumberMinMax) {
    rules[field] = rules[field].concat(
      minMaxRule(
        parseFabricTitles(field, measurementUnit),
        fieldNumberMinMax[field].min,
        fieldNumberMinMax[field].max,
        fieldNumberMinMax[field].minStrict,
        fieldNumberMinMax[field].maxStrict
      )
    );
  }
  // Add date valid range rule
  for (const field in fieldValidDateRange) {
    rules[field] = rules[field].concat(
      dateValidRangeRule(
        parseFabricTitles(field, measurementUnit),
        fieldValidDateRange[field].min,
        fieldValidDateRange[field].max
      )
    );
  }
  return rules;
};

/**
 * Validates multiple fields of the provided fabric data object. The function checks the following:
 * 1. Percentages of all compositions add up to 100.
 * 2. Every composition id-percentage pair are either both null or both not null.
 * 3. All compositions are different.
 * 4. If a composition is null, all subsequent compositions are also null.
 * 5. Currency is not null when some price is given.
 */
export const compositeFabricFieldCheck = (
  fabricData: CompositeFieldCheckProps
) => {
  let totalPercentage = 0;
  let hasInvalidIdPercentagePairs = false;
  let nextCompositionShouldBeNone = false;
  let compositionsPresent = false;

  const seenCompositionIds = new Set();

  const compositions = [
    {
      id: fabricData.composition1_id,
      percentage: fabricData.composition1_percentage,
    },
    {
      id: fabricData.composition2_id,
      percentage: fabricData.composition2_percentage,
    },
    {
      id: fabricData.composition3_id,
      percentage: fabricData.composition3_percentage,
    },
    {
      id: fabricData.composition4_id,
      percentage: fabricData.composition4_percentage,
    },
  ];

  for (let index = 0; index < compositions.length; index++) {
    const { id: compositionId, percentage: compositionPercentage } =
      compositions[index];

    if (compositionId === null) {
      nextCompositionShouldBeNone = true;
    } else {
      if (nextCompositionShouldBeNone) {
        message.error(
          i18n.t('error_messages:error_null_composition_seq', {
            higher_id: index + 1,
            lower_id: index,
          })
        );
        return false;
      }
      if (seenCompositionIds.has(compositionId)) {
        message.error(i18n.t('error_messages:error_same_composition'));
        return false;
      }

      seenCompositionIds.add(compositionId);
    }

    if (compositionId !== null && compositionPercentage !== null) {
      compositionsPresent = true;
      totalPercentage += compositionPercentage;
    } else if (
      (compositionId === null && compositionPercentage !== null) ||
      (compositionId !== null && compositionPercentage === null)
    ) {
      hasInvalidIdPercentagePairs = true;
    }
  }

  if (compositionsPresent && Math.abs(totalPercentage - 100) > 0.0001) {
    message.error(i18n.t('error_messages:error_composition_total'));
    return false;
  }
  if (hasInvalidIdPercentagePairs) {
    message.error(i18n.t('error_messages:error_composition_pair'));
    return false;
  }

  const hasPrice =
    fabricData.price_per_m !== null ||
    fabricData.price_per_sqm !== null ||
    fabricData.price_per_kg !== null ||
    fabricData.price_per_piece !== null;
  if (hasPrice !== (fabricData.currency_id !== null)) {
    message.error(i18n.t('error_messages:error_null_currency_price_pair'));
    return false;
  }

  if (fabricData.cuttable_width_cm !== null && fabricData.width_cm !== null) {
    const validWidth = fabricData.cuttable_width_cm <= fabricData.width_cm;
    if (!validWidth) {
      message.error(i18n.t('error_messages:error_cuttable_width'));
      return false;
    }
  }

  return true;
};
