import { Segmented } from 'antd';
import { FabricSwitchContainer } from '../../elements/fabrics/fabricStyledElement';
import {
  GalleryViewIcon,
  TableViewIcon,
} from '../../elements/icons/StyledIcons';

interface FabricSwitchToggleProps {
  fabricViewToggle: boolean;
  handleToggleChange: (checked: boolean) => void;
}

// Switch toggle component for selecting between table and gallery view
export const FabricSwitchToggle = ({
  fabricViewToggle,
  handleToggleChange,
}: FabricSwitchToggleProps) => {
  return (
    <FabricSwitchContainer>
      <Segmented
        style={{ backgroundColor: '#e8e8e8' }}
        options={[
          {
            value: false,
            icon: <TableViewIcon />,
          },
          {
            value: true,
            icon: <GalleryViewIcon />,
          },
        ]}
        value={fabricViewToggle}
        onChange={handleToggleChange}
      />
    </FabricSwitchContainer>
  );
};
