import { Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { GenericFilterDrawer } from '../../components/filter/generic/GenericFilterDrawer';
import { createInitialFilterValues } from '../../components/filter/generic/sharedLinksIntialValues';
import { AdminSharedLinksList } from '../../components/table/adminLinksList/AdminSharedLinksList';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { ButtonBasic } from '../../elements/buttons/StyledButtons';
import { AdminLayout } from '../../layouts/Admin';
import i18n from '../../plugins/i18n';
import { AdminSharedLinksResp } from '../../utils/backendRequests/admin/AdminTypes';
import { getsharedUserLinks } from '../../utils/backendRequests/admin/getsharedUserLinks';
import { TitleProp } from '../PagesTypes';

export function AdminSharedLinksPage({ title }: TitleProp) {
  const [data, setData] = useState<AdminSharedLinksResp>([]);
  const [filteredData, setFilteredData] = useState<AdminSharedLinksResp>([]);
  const [filterOpen, setFilterOpen] = useState(false);

  const getSharedLinks = async () => {
    const sharedLinksData = await getsharedUserLinks();
    setData(sharedLinksData);
  };

  useEffect(() => {
    getSharedLinks();
  }, []);

  // Update filteredData whenever shared links change
  useEffect(() => {
    if (data && data.length > 0) {
      setFilteredData(data);
    }
  }, [data]);

  return (
    <AdminLayout>
      <HeaderTitle title={title} />
      <Row justify="start">
        <Space>
          <ButtonBasic
            onClick={() => setFilterOpen(true)}
            disabled={data?.length === 0}
          >
            {i18n.t('buttons:filter')}
          </ButtonBasic>
        </Space>
      </Row>
      <GenericFilterDrawer
        initialData={data}
        setFilteredData={setFilteredData}
        filterOpen={filterOpen}
        closeFilter={() => setFilterOpen(false)}
        createInitialFilterValues={createInitialFilterValues}
      />
      <AdminSharedLinksList title={title} data={filteredData} />
    </AdminLayout>
  );
}
