import i18n from 'i18next';

import { restoreBin } from '../../../utils/backendRequests/bin/restoreBin';
import { Modal } from '../../Modal';
import { RestoreModalProps } from './BinModalTypes';

export function RestoreModal({
  modal,
  setModal,
  type,
  selectedRowKeys,
  data,
  getBinContents,
}: RestoreModalProps) {
  // Close modal
  const handleCancel = () => {
    setModal({
      visible: false,
    });
  };

  const handleSubmit = async () => {
    const successFlag = await restoreBin(type, data, selectedRowKeys);
    if (successFlag) {
      setModal({
        visible: false,
      });
      getBinContents();
    }
  };

  return (
    <Modal
      title={i18n.t('headers:confirm_restoration')}
      open={modal.visible}
      onCancel={handleCancel}
      cancelButtonProps={{ danger: true }}
      onOk={handleSubmit}
      okText={i18n.t('buttons:restore')}
    >
      {i18n.t('long_messages:message_confirm_restoration')}
    </Modal>
  );
}
