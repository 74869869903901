import { message } from 'antd';
import i18n from 'i18next';
import { MsgProp } from '../../types/Types';
import { api } from '../api/api';

/**
 * Send collection to bin.
 */
export async function binCollection(collectionId: string) {
  return await api({
    method: 'PUT',
    url: `v1/collection/${collectionId}/delete_to_bin`,
    showErrMsg: true,
  }).then((res: MsgProp) => {
    message.success(i18n.t('long_messages:message_collection_bin_success'));
    return res;
  });
}
