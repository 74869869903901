import { FabricDetailsPage } from '../pages/fabric/FabricDetailsPage';
import { HangersPage } from '../pages/hangers/HangersPage';
import { RouteProps } from './RouteTypes';

export const routesHangers: RouteProps[] = [
  {
    path: '/hangers',
    title: 'Hangers',
    key: 'hangers_page',
    page: HangersPage,
    children: [
      {
        path: '/:fabricId',
        title: 'Hangers Id',
        page: FabricDetailsPage,
      },
    ],
  },
];
