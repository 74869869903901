import { message } from 'antd';
import { api } from '../api/api';
import { EditFabricsProps } from './AdminTypes';

/**
 * Updates ML Training label for selected fabrics
 */
export async function editFabrics({
  fabricIds,
  label,
}: EditFabricsProps): Promise<boolean> {
  try {
    const parsedData = {
      fabric_ids: fabricIds,
      ml_training_label: label,
    };
    await api({
      method: 'PUT',
      url: 'v1/superuser/training_label/fabric',
      body: parsedData,
      showErrMsg: true,
    });
    message.success('Fabric details updated.');
    return true;
  } catch (error) {
    return false;
  }
}
