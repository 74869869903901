import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getSharedUsers } from '../../../utils/backendRequests/project/getSharedUsers';
import { shareProject } from '../../../utils/backendRequests/project/shareProject';
import { Modal } from '../../Modal';
import { SelectWithSearch } from '../../search/SelectWithSearch';
import { SharedUsers } from '../../table/projectList/SharedUsers';
import { sharedUser, ShareProjectProps } from '../../table/TableTypes';

export function ShareProjectModal({
  projectId,
  approvedUsers,
}: ShareProjectProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sharedUsers, setSharedUsers] = useState<sharedUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<sharedUser[]>([]);
  const [unsharedUsers, setUnsharedUsers] = useState<sharedUser[]>([]);

  useEffect(() => {
    async function _getSharedUsers() {
      const sharedUserData = await getSharedUsers(projectId);
      setSharedUsers(sharedUserData);
    }
    if (isModalOpen) _getSharedUsers(); // Only make API call when the modal is opened
  }, [projectId, isModalOpen]);

  useEffect(() => {
    // Converts list of users (Object) to user ids (int) for easy filtering
    const sharedUserIds = sharedUsers.map((user: sharedUser) => user.key);
    // This updates the list of users who have not been shared with previously
    // Users will be unable to select a user they have already shared the project with
    setUnsharedUsers(
      approvedUsers.filter((user) => !sharedUserIds.includes(user.key))
    );
  }, [approvedUsers, sharedUsers]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange = (value: string[]) => {
    // Update selected users directly based on the current selection
    const newSelectedUsers = unsharedUsers.filter((user) =>
      value.includes(user.key)
    );
    setSelectedUsers(newSelectedUsers);
  };

  const handleOk = async () => {
    // Merge selected and previously shared users
    const updatedSharedUsers = [...sharedUsers, ...selectedUsers];
    // Convert User objects to their Ids for backend API call
    const selectedUserIds = updatedSharedUsers.map(
      (user: sharedUser) => user.key
    );
    await shareProject(projectId, selectedUserIds);
    // Updates list of shared users after API call is completed
    setSharedUsers(updatedSharedUsers);
    // Clears the list of selected users to prevent resubmission of same users
    setSelectedUsers([]);
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Link onClick={showModal} to={''}>
        {i18n.t('buttons:share')}
      </Link>
      <Modal
        title={i18n.t('headers:share_with_users')}
        open={isModalOpen}
        onCancel={handleCancel}
        cancelButtonProps={{ danger: true }}
        onOk={handleOk}
        okText={i18n.t('buttons:share')}
      >
        <SelectWithSearch
          mode="multiple"
          style={{ width: '100%' }}
          placeholder={i18n.t('headers:add_more_users')}
          onChange={handleChange}
          options={unsharedUsers.map((user) => ({
            value: user.key,
            label: user.value,
          }))}
          value={selectedUsers.map((user) => user.key)} // Use user keys for value
        />
        <SharedUsers
          sharedUsers={sharedUsers}
          projectId={projectId}
          setSharedUsers={setSharedUsers}
        />
      </Modal>
    </>
  );
}
