import { configureStore } from '@reduxjs/toolkit';
import { authSliceReducer } from './auth';
import { commonSliceReducer } from './common';

export const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    common: commonSliceReducer,
  },
});
