import { message } from 'antd';
import i18n from 'i18next';

import { NavigateProp } from '../../types/Types';
import { api } from '../api/api';
import { RemoveFabricProp } from './FabricTypes';
/**
 * Send fabrics to the bin based on their id.
 */
export async function deleteToBinFabric(
  data: RemoveFabricProp,
  navigate: NavigateProp,
  refresh: boolean = false
): Promise<void> {
  try {
    await api({
      method: 'PUT',
      url: 'v1/fabric/delete_to_bin',
      body: data,
      headers: {
        'content-type': 'application/json',
      },
    });
    /**
     * An alternative way to implement this is to return a boolean to indicate if it succeeded then
     * based on that value the fabric details page would navigate back. For the fabric list if the
     * boolean is true then we would reset the tables selected rows and getFabrics again. - Andrew
     */
    message.success(i18n.t('long_messages:message_fabric_bin_success'));
    refresh ? navigate(0) : navigate(-1);
  } catch (error) {
    message.error(i18n.t('error_messages:error_fabric_bin_failed'));
  }
}
