import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TableGeneric } from '../../../components/table/TableGeneric';
import { columnFields } from '../../../components/table/TableTypes';
import {
  UserProps,
  getCurrentUser,
} from '../../../utils/backendRequests/user/getCurrentUser';
import { getAllUsers } from '../../../utils/backendRequests/user/getUsers';
import { RootAuth, RootState } from '../../../utils/types/Types';
import { ProjectTableListProps } from '../TableTypes';
import { columnsDesktop } from './ColumnsDesktop';
import { columnsMobile } from './ColumnsMobile';

type ApprovedUsers = {
  key: string;
  value: string;
}[];

export function ProjectTableList({
  data,
  getProjectList,
}: ProjectTableListProps) {
  const [approvedUsers, setApprovedUsers] = useState<ApprovedUsers>([]);
  const [columns, setColumns] = useState<columnFields[]>([]);
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const account = useSelector((state: RootAuth) => state.auth.accountType);

  const _getApprovedUsers = async () => {
    const users: UserProps[] = await getAllUsers();
    const currentUserData: UserProps = await getCurrentUser();
    // TODO: get rid of the parsing and just use the returned User type
    const parsedUsers: ApprovedUsers = users
      .filter((user) => user.id !== currentUserData.id)
      .map((user) => ({ key: user.id, value: user.name }));
    setApprovedUsers(parsedUsers);
  };

  useEffect(() => {
    // Gets list of approved users here
    // API call is done once here as all projects will use the same list of approved users
    // Prevents multiple API calls for the same list of approved users during project renders
    _getApprovedUsers();
  }, []);

  useEffect(() => {
    if (!isMobileFlag) {
      setColumns(columnsDesktop(approvedUsers, account, getProjectList));
    } else {
      setColumns(columnsMobile(approvedUsers, account, getProjectList));
    }
  }, [approvedUsers, isMobileFlag]);

  return (
    <>
      <TableGeneric columns={columns} data={data} isBackendPaginated={false} />
    </>
  );
}
