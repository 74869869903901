import { message } from 'antd';
import i18n from 'i18next';

import { MsgProp } from '../../types/Types';
import { api } from '../api/api';

/**
 * Adds fabrics by fabric id, to a collection, specified by ID.
 */
export async function addCollectionFabrics(
  collectionId: string,
  newFabrics: string[]
): Promise<MsgProp> {
  return await api({
    method: 'POST',
    url: `v1/collection/${collectionId}/fabric`,
    showErrMsg: true,
    body: {
      ids: newFabrics,
    },
  }).then((res: MsgProp) => {
    message.success(i18n.t('long_messages:message_collection_updated_success'));
    return res;
  });
}
