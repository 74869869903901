import { Button, Form } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { FormWrapper } from '../../../elements/wrapper/FormWrapper';
import i18n from '../../../plugins/i18n';
import {
  Brand,
  CreateUserData,
} from '../../../utils/backendRequests/admin/AdminTypes';
import { adminCreateUser } from '../../../utils/backendRequests/admin/createUser';
import {
  getBrands,
  parseBrandsResponse,
} from '../../../utils/backendRequests/admin/getBrands';
import { parseFirstCharToUpper } from '../../../utils/parsers/parseFirstCharToUpper';
import { nullParser } from '../../../utils/parsers/parseNullValues';
import { passwordValid } from '../../../utils/validations/passwordValidator';
import { Modal } from '../../Modal';
import { FormItems } from '../../form/FormItems';
import { DropDownObjProp } from '../../form/FormTypes';
import { SelectWithSearch } from '../../search/SelectWithSearch';
import {
  designerUserFields,
  enumProps,
  initialFormFields,
  supplierUserFields,
} from './userFormFields';
import { userFieldRules } from './userFormRules';

interface CreateUserModalProps {
  type: 'designer' | 'supplier';
  modal: { visible: boolean };
  setModal: (modal: { visible: boolean }) => void;
}

type LabelObjectProp = {
  country_id: {
    value: number;
    label: string;
  }[];
  measurement_unit: {
    value: number;
    label: string;
  }[];
};

export function CreateUserModal({
  type,
  modal,
  setModal,
}: CreateUserModalProps) {
  const [form] = Form.useForm();
  const [propsWithDropDownObj, setPropsWithDropDownObj] =
    useState<DropDownObjProp>({});
  const [brandOptions, setBrandOptions] = useState<
    Brand[] | parseBrandsResponse[]
  >([]);
  const inputFields =
    type === 'designer' ? designerUserFields : supplierUserFields;

  const fabricRulesConst = useMemo(
    () => userFieldRules(inputFields),
    [inputFields]
  );

  // Close modal
  const handleCancel = () => {
    setModal({ visible: false });
  };

  const getLabel = (
    obj: DropDownObjProp,
    key: keyof LabelObjectProp,
    value: number
  ) => {
    return obj[key]?.find((i) => i.value === value)?.label || null;
  };

  // Initial form values
  useEffect(() => {
    form.setFieldsValue(initialFormFields(inputFields));
  }, [form, inputFields]);

  const _getBrands = async () => {
    const brandsData = await getBrands(type, true);
    setBrandOptions(brandsData);
  };

  // Get brand_ids
  useEffect(() => {
    _getBrands();
  }, [type]);

  const handleSubmit = async (values: CreateUserData) => {
    const parsedUserData = nullParser(values) as CreateUserData;
    // Get the label of the enum value, required for backend_auth which uses sequelize
    for (const enumProp of enumProps as (keyof LabelObjectProp)[]) {
      (parsedUserData[enumProp] as string | null) = getLabel(
        propsWithDropDownObj,
        enumProp,
        parsedUserData[enumProp] as number
      );
    }

    // Validate password
    if (!passwordValid(parsedUserData.password)) return null;

    // Call adminCreateUser with the correctly typed values
    if (await adminCreateUser({ type: type, data: parsedUserData })) {
      // Reset form fields and hide modal on successful user creation
      form.resetFields();
      setModal({ visible: false });
    }
  };

  return (
    <Modal
      title={`New ${parseFirstCharToUpper(type)} User`}
      open={modal.visible}
      onCancel={handleCancel}
      footer={null}
    >
      <FormWrapper>
        <Form form={form} onFinish={handleSubmit}>
          <FormItems
            formFields={inputFields}
            propsWithDropDownObj={propsWithDropDownObj}
            setPropsWithDropDownObj={setPropsWithDropDownObj}
            formRules={fabricRulesConst}
          />
          {/*Brand cannot be added with FormItems component so it is declared here*/}
          <Form.Item
            name={`${type}_brand_id`}
            label={`${parseFirstCharToUpper(type)} Brand Name`}
            rules={[{ required: true, message: 'Brand is required.' }]}
          >
            <SelectWithSearch
              placeholder="Select Brand"
              options={brandOptions as parseBrandsResponse[]}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 20, span: 12 }}>
            <Button type="primary" htmlType="submit">
              {i18n.t('buttons:submit')}
            </Button>
          </Form.Item>
        </Form>
      </FormWrapper>
    </Modal>
  );
}
