import i18n from 'i18next';

import { api } from '../api/api';

/**
 * Edit images associated to a fabric entry in backend.
 */
export async function editFabricImage(
  fabricId: string,
  imageData: FormData
): Promise<void> {
  try {
    await api({
      method: 'PUT',
      url: `v1/fabric/${fabricId}/image`,
      body: imageData,
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  } catch (error) {
    throw new Error(i18n.t('error_messages:error_fabric_img_updated_failed'));
  }
}
