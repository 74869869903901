import { message } from 'antd';
import i18n from 'i18next';
import { MsgProp } from '../../types/Types';
import { api } from '../api/api';
import { createCertBody } from './CertificationTypes';

/**
 * Create a new certification which will be owned by
 * the user's supplier brand.
 */
export async function createCertification(
  body: createCertBody
): Promise<MsgProp> {
  return await api({
    method: 'POST',
    url: `v1/certification`,
    body: body,
    showErrMsg: true,
  }).then((res: MsgProp) => {
    message.success(i18n.t('long_messages:message_cert_created_success'));
    return res;
  });
}
