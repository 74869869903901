import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getTranslatedValuesAndUpdateData } from '../../../utils/fabrics/parseFabricValues';
import { record, RootAuth } from '../../../utils/types/Types';
import { TabTitle } from '../../TabTitle';
import { TableGeneric } from '../TableGeneric';
import { CertificationsListProps, columnFields } from '../TableTypes';
import { ColumnsCert } from './ColumnsCert';

export function CertificationsList({
  data,
  title,
  editCertOnClick,
  getCertifications,
}: CertificationsListProps) {
  const [columns, setColumns] = useState<columnFields[]>([]);
  const [translatedData, setTranslatedData] = useState<record[]>([]);
  const brandName = useSelector((state: RootAuth) => state.auth?.brandName);
  const account = useSelector((state: RootAuth) => state.auth.accountType);

  useEffect(() => {
    setColumns(
      ColumnsCert(
        editCertOnClick,
        brandName,
        getCertifications,
        account
      ) as columnFields[]
    );
  }, []);

  // Set translated data
  useEffect(() => {
    const translationData = getTranslatedValuesAndUpdateData(
      data,
      columns as columnFields[]
    );
    setTranslatedData(translationData as record[]);
  }, [data, columns]);

  return (
    <>
      {translatedData[0] && <TabTitle title={title} />}
      <TableGeneric
        columns={columns}
        data={translatedData}
        isBackendPaginated={false}
      />
    </>
  );
}
