import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DesktopSidebarButton } from '../../elements/buttons/StyledButtons';
import {
  DesktopHeaderStyled,
  DesktopSearchbarStyled,
  StyledProfileDropdown,
} from '../../elements/StyledUI';
import { toggleSidebar } from '../../plugins/redux/common';
import { LogoWrapper } from '../../styles/wrappers/LogoWrapper';
import { RootAuth, RootState } from '../../utils/types/Types';
import { DesktopSearchbar } from '../search/DesktopSearchbar';
import { ProfileDropDown } from './profileDropDown/ProfileDropDown';

interface HeaderDesktopProps {
  logo: ReactNode;
  userName: string;
  onSearch: (value: string, includeMLFields: boolean) => void;
}

export function HeaderDesktop({
  logo,
  userName,
  onSearch,
}: HeaderDesktopProps) {
  const dispatch = useDispatch();
  const isSidebarCollapsed = useSelector(
    (state: RootState) => state.common.isSidebarCollapsed
  );
  const role = useSelector((state: RootAuth) => state.auth?.role);
  const isAdmin = role === 'admin';

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar(!isSidebarCollapsed));
  };

  return (
    <>
      <DesktopHeaderStyled span={6}>
        {!isAdmin && (
          <DesktopSidebarButton type="text" onClick={handleToggleSidebar}>
            {isSidebarCollapsed ? <MenuOutlined /> : <CloseOutlined />}
          </DesktopSidebarButton>
        )}
        <LogoWrapper $mainlayout={true}>{logo ? logo : 'Logo'}</LogoWrapper>
      </DesktopHeaderStyled>

      {!isAdmin && (
        <DesktopSearchbarStyled span={12}>
          <DesktopSearchbar onSearch={onSearch} />
        </DesktopSearchbarStyled>
      )}
      <StyledProfileDropdown isAdmin={isAdmin}>
        <ProfileDropDown userName={userName} />
      </StyledProfileDropdown>
    </>
  );
}
