import { getTimeZones } from '@vvo/tzdb';
import { DateTime } from 'luxon';

// Fetch all time zones to find the one that matches
const timeZones = getTimeZones();

/**
 * Formats a UTC datetime string to the user's local timezone and
 * appends the timezone abbreviation only if the input includes a time.
 */
export const parseLocalDateTime = (value: string) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Check if the value contains a time part or is just a date
  const hasTime = value.includes('T');

  // Parse the UTC date string using Luxon and adjust to the user's timezone
  const date = DateTime.fromISO(value, { zone: 'utc' }).setZone(timeZone);

  // Format the date/time based on whether it has a time component
  const formattedDate = date.toFormat(
    hasTime ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'
  );

  // Function to find the abbreviation for the detected time zone
  const getAbbreviationForTimeZone = (tz: string) => {
    const timeZoneInfo = timeZones.find((zone) => zone.name === tz);
    return timeZoneInfo ? timeZoneInfo.abbreviation : '';
  };

  // Append the timezone abbreviation only if the input has a time component
  if (hasTime) {
    const abbreviation = getAbbreviationForTimeZone(timeZone) || 'UTC';
    return `${formattedDate} ${abbreviation}`;
  }

  return formattedDate;
};
