import { message } from 'antd';
import i18n from 'i18next';

import { api } from '../api/api';
/**
 * Creates an inspiration entry if the image can be successfully
 * uploaded to S3.
 */

export type onProp = () => void;

export async function createInspiration(
  data: FormData,
  onClose: onProp,
  onSuccess: onProp
): Promise<void> {
  await api({
    method: 'POST',
    url: 'v1/user_upload',
    body: data,
    showErrMsg: true,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
  message.success(i18n.t('long_messages:message_inspiration_created_success'));
  onClose();
  onSuccess();
}
