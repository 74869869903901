import { Checkbox } from 'antd';
import i18n from 'i18next';
import { HelpIcon } from '../../elements/icons/StyledIcons';
import { MLCheckboxProps } from './SearchTypes';

export function MLCheckBox({
  includeMLFields,
  setIncludeMLFields,
}: MLCheckboxProps) {
  return (
    <>
      <Checkbox
        checked={includeMLFields}
        onChange={(e) => setIncludeMLFields(e.target.checked)}
      >
        {i18n.t('common:ai')}
      </Checkbox>
      <HelpIcon
        title={i18n.t('long_messages:message_text_search_include_ai')}
        fontSize="10px"
        margin="-5px -6px 0 -13px"
      />
    </>
  );
}
