import { Checkbox, Row, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import i18n from 'i18next';
import { useState } from 'react';
import { HelpIcon } from '../../../elements/icons/StyledIcons';
import { Setter } from '../../../utils/types/Types';
import { Modal } from '../../Modal';

interface ExportFabricsModalProps {
  visible: boolean;
  setIncludeImages: Setter<'sm' | 'md' | 'lg' | 'excel' | null>;
  onCancel: () => void;
  onExportExcel: () => void;
}

export function ExportExcelModal({
  visible,
  setIncludeImages,
  onCancel,
  onExportExcel,
}: ExportFabricsModalProps) {
  const [includeImagesForExcel, setIncludeImagesForExcel] = useState(false);

  const handleIncludeImagesChange = (e: CheckboxChangeEvent) => {
    setIncludeImagesForExcel(e.target.checked);
    setIncludeImages(e.target.checked ? 'excel' : null);
  };

  return (
    <>
      <Modal
        title={i18n.t('headers:select_export_type')}
        open={visible}
        okText={i18n.t('buttons:export')}
        onOk={onExportExcel}
        onCancel={onCancel}
      >
        <Row>{i18n.t('long_messages:export_fabric_excel_modal_message')}</Row>
        <Row>
          <Checkbox
            checked={includeImagesForExcel}
            onChange={handleIncludeImagesChange}
          >
            <Space>
              {i18n.t('headers:include_images_excel_export')}
              <HelpIcon
                title={i18n.t('long_messages:message_explain_include_images')}
              />
            </Space>
          </Checkbox>
        </Row>
      </Modal>
    </>
  );
}
