import { useState } from 'react';
import { Link } from 'react-router-dom';

import { UpdateSupplierUserModal } from '../../modal/createUser/UpdateSupplierUserModal';
import { SupplierUserValues } from '../TableTypes';

interface EditSupplierUser {
  user: SupplierUserValues;
  supplierUserId: string;
  getUsers: () => void;
}

export function EditSupplierUser({
  user,
  supplierUserId,
  getUsers,
}: EditSupplierUser) {
  const [updateModal, setUpdateModal] = useState({ visible: false });
  const handleUpdate = () => {
    setUpdateModal({ visible: true });
  };

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <Link onClick={handleUpdate} to={''}>
          Edit
        </Link>
      </div>
      <UpdateSupplierUserModal
        modal={updateModal}
        setModal={setUpdateModal}
        user={user}
        userId={supplierUserId}
        getUsers={getUsers}
      />
    </>
  );
}
