import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';

import { TabTitle } from '../../components/TabTitle';
import { CreateBrandModal } from '../../components/modal/createBrand/CreateBrandModal';
import { AdminBrandList } from '../../components/table/adminBrandList/AdminBrandList';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { ButtonBasic } from '../../elements/buttons/StyledButtons';
import { AdminLayout } from '../../layouts/Admin';
import i18n from '../../plugins/i18n';
import { Brand } from '../../utils/backendRequests/admin/AdminTypes';
import {
  getBrands,
  parseBrandsResponse,
} from '../../utils/backendRequests/admin/getBrands';
import { TitleProp } from '../PagesTypes';

export function AdminDesignerBrandPage({ title }: TitleProp) {
  const [designerBrands, setDesignerBrands] = useState<
    Brand[] | parseBrandsResponse[]
  >([]);
  const [supplierBrands, setSuppplierBrands] = useState<
    Brand[] | parseBrandsResponse[]
  >([]);
  const [modal, setModal] = useState({ visible: false });

  async function getUpdatedBrands() {
    const designerBrands = await getBrands('designer');
    setDesignerBrands(designerBrands);
    const supplierBrands = await getBrands('supplier');
    setSuppplierBrands(supplierBrands);
  }

  useEffect(() => {
    getUpdatedBrands();
  }, [modal]);

  const handleNew = () => {
    setModal({ visible: true });
  };

  return (
    <AdminLayout>
      <HeaderTitle title={i18n.t('page_titles:designer_brands_page')} />
      <TabTitle title={title} />
      {modal.visible && (
        <CreateBrandModal type="designer" modal={modal} setModal={setModal} />
      )}
      <Row justify="start">
        <Col>
          <ButtonBasic onClick={handleNew}>New</ButtonBasic>
        </Col>
      </Row>
      <AdminBrandList
        title={title}
        listType="designer"
        data={designerBrands}
        supplierBrands={supplierBrands}
        getBrands={getUpdatedBrands}
      />
    </AdminLayout>
  );
}
