import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getSimilarFabrics } from '../../../utils/backendRequests/fabric/getFabricSimilar';
import { CarouselMain } from '../../carousel/CarouselMain';
import { itemProp } from '../../carousel/CarouselTypes';
import { FabricTabProps } from '../FabricTypes';

/**
 * A tab component to show recommended results on a fabric details page.
 */
export function FabricRecommendationTab({ fabric, isMobile }: FabricTabProps) {
  const [similarFab, setSimilarFab] = useState<itemProp[]>([]);
  const navigate = useNavigate();

  const _getSimilarFabrics = async () => {
    const fabricData = await getSimilarFabrics(fabric.id);
    setSimilarFab(fabricData);
  };

  useEffect(() => {
    if (fabric.id !== undefined) {
      _getSimilarFabrics();
    }
  }, [fabric.id]);

  return (
    <CarouselMain items={similarFab} navigate={navigate} isMobile={isMobile} />
  );
}
