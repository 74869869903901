import { useEffect, useState } from 'react';

import { TableGeneric } from '../../../components/table/TableGeneric';
import { TabTitle } from '../../TabTitle';
import { AdminUserListProps, DesignerUserColumn } from '../TableTypes';
import { designerUserColumns } from './designerUserColumns';
import { supplierUserColumns } from './supplierUserColumns';

export function AdminUserList({
  listType,
  data,
  title,
  getUsers,
}: AdminUserListProps) {
  const [columns, setColumns] = useState<DesignerUserColumn[]>([]);

  useEffect(() => {
    listType === 'designer'
      ? setColumns(designerUserColumns(getUsers))
      : setColumns(supplierUserColumns(getUsers));
  }, [listType]);

  return (
    <>
      {data[0] && <TabTitle title={title} />}
      <TableGeneric columns={columns} data={data} isBackendPaginated={false} />
    </>
  );
}
