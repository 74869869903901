import { api } from '../api/api';
/**
 * Gets all inspirations for the currently logged in user.
 * Save results to state.
 * */

export type inspirationResponse = {
  created_at: string;
  description: string;
  image_url: string;
}[];

export async function getInspirations(): Promise<inspirationResponse> {
  const res = await api({
    method: 'GET',
    url: 'v1/user_upload',
    showErrMsg: true,
  });
  return res;
}
