import { api } from '../api/api';

/**
 * Gets possible fabric types.
 */
export const getFabricTypes = async (): Promise<Record<string, string>> => {
  return await api({
    method: 'GET',
    url: `v1/list/fabric_types`,
    showErrMsg: true,
  });
};
