import { ReactComponent as DelvifyLogo } from '../assets/images/logos/delvify.svg';
import { LayoutContainer } from '../elements/StyledUI';
import { ContentWrapper } from '../elements/wrapper/ContentWrapper';
import { LayoutWrapper } from '../elements/wrapper/LayoutWrapper';
import { NoAuthHeaderBarDobby } from '../pages/noAuth/NoAuthHeaderBarDobby';
import { LayoutProps } from './LayoutTypes';

export function NoAuthLayout({ children }: LayoutProps) {
  return (
    <LayoutContainer>
      <NoAuthHeaderBarDobby logo={<DelvifyLogo />} />
      <LayoutContainer>
        <LayoutWrapper>
          <ContentWrapper>{children}</ContentWrapper>
        </LayoutWrapper>
      </LayoutContainer>
    </LayoutContainer>
  );
}
