import { Space } from 'antd';

import { Brand } from '../../../utils/backendRequests/admin/AdminTypes';
import { parseBrandsResponse } from '../../../utils/backendRequests/admin/getBrands';
import { EditDesignerBrand } from './EditDesignerBrand';
import { ManageApprovedSupplierBrands } from './ManageApprovedSupplierBrands';

export const designerBrandColumns = (
  supplierBrands: Brand[] | parseBrandsResponse[],
  getBrands: () => void
) => [
  {
    dataIndex: 'key',
    key: 'key',
  },
  {
    dataIndex: 'name',
    key: 'name',
    title: 'Name',
    columnType: 'string',
  },
  {
    dataIndex: 'address',
    key: 'address',
    title: 'Address',
    columnType: 'string',
  },
  {
    dataIndex: 'city',
    key: 'city',
    title: 'City',
    columnType: 'string',
  },
  {
    dataIndex: 'country',
    key: 'country',
    title: 'Country',
    columnType: 'string',
  },
  {
    dataIndex: 'website',
    key: 'website',
    title: 'Website',
    render: (name: string) => <a href={name}>{name}</a>,
  },
  {
    key: 'action',
    title: 'Action',
    render: (record: Brand | parseBrandsResponse) => (
      <>
        <Space size="middle">
          <ManageApprovedSupplierBrands
            designerBrandId={record.id}
            supplierBrands={supplierBrands.map((brand) => {
              return {
                key: brand.id,
                value: brand.name,
              };
            })}
          />
          <EditDesignerBrand
            brand={record}
            designerBrandId={record.id}
            getBrands={getBrands}
          />
        </Space>
      </>
    ),
  },
];
