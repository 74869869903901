import { CollectionDetailsPage } from '../pages/collection/CollectionDetailsPage';
import { CollectionListPage } from '../pages/collection/CollectionListPage';
import { EditFabricPage } from '../pages/fabric/EditFabricPage';
import { FabricDetailsPage } from '../pages/fabric/FabricDetailsPage';
import { RouteProps } from './RouteTypes';

export const routesCollections: RouteProps[] = [
  {
    path: '/collections',
    title: 'Collections',
    key: 'collections_page',
    page: CollectionListPage,
    children: [
      {
        path: '/:id',
        title: 'Collection Id',
        page: CollectionDetailsPage,
      },
      {
        path: '/:id/:fabricId',
        title: 'Fabric Details',
        page: FabricDetailsPage,
      },
      {
        path: '/:id/:fabricId/edit',
        title: 'Fabric Id',
        page: EditFabricPage,
      },
    ],
  },
];
