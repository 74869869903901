import { Layout } from 'antd';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';
import i18n from 'i18next';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  BinPageIcon,
  CertificationsPageIcon,
  CollectionsPageIcon,
  ColoursPageIcon,
  FabricsPageIcon,
  GarmentsPageIcon,
  HangersPageIcon,
  HistoryPageIcon,
  HomePageIcon,
  HoplunPortalIcon,
  ImportPageIcon,
  ProjectsPageIcon,
  SuppliersPageIcon,
  TrimsPageIcon,
  UploadsPageIcon,
  UserManagementPageIcon,
} from '../elements/icons/StyledIcons';
import { MenuWrapper } from '../elements/wrapper/MenuWrapper';
import { toggleSidebar } from '../plugins/redux/common';
import { getSidebarRoutes } from '../routes';
import { getHoplunToken } from '../utils/backendRequests/user/getHoplunToken';
import { RootAuth, RootState } from '../utils/types/Types';
import { SideBarDobbyProps, SidebarItem } from './table/TableTypes';

const { Sider } = Layout;

export function SiderBarDobby({ siderBarWidth }: SideBarDobbyProps) {
  const dispatch = useDispatch();
  const { isSidebarCollapsed } = useSelector(
    (state: RootState) => state.common
  );
  const role = useSelector((state: RootAuth) => state.auth?.role);
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const { hoplunUsername } = useSelector((state: RootAuth) => state.auth);

  const sideBarCollapse = () => {
    dispatch(toggleSidebar(true));
  };

  const iconMap = (title: string) => {
    switch (title) {
      case 'fabrics_page':
        return <FabricsPageIcon $isMobileFlag={isMobileFlag} />;
      case 'import_page':
        return <ImportPageIcon $isMobileFlag={isMobileFlag} />;
      case 'user_management_page':
        return <UserManagementPageIcon $isMobileFlag={isMobileFlag} />;
      case 'hangers_page':
        return <HangersPageIcon $isMobileFlag={isMobileFlag} />;
      case 'home_page':
        return <HomePageIcon $isMobileFlag={isMobileFlag} />;
      case 'projects_page':
        return <ProjectsPageIcon $isMobileFlag={isMobileFlag} />;
      case 'trims_page':
        return <TrimsPageIcon $isMobileFlag={isMobileFlag} />;
      case 'garments_page':
        return <GarmentsPageIcon $isMobileFlag={isMobileFlag} />;
      case 'inspirations_page':
        return <UploadsPageIcon $isMobileFlag={isMobileFlag} />;
      case 'suppliers_page':
        return <SuppliersPageIcon $isMobileFlag={isMobileFlag} />;
      case 'certifications_page':
        return <CertificationsPageIcon $isMobileFlag={isMobileFlag} />;
      case 'colours_page':
        return <ColoursPageIcon $isMobileFlag={isMobileFlag} />;
      case 'bin_page':
        return <BinPageIcon $isMobileFlag={isMobileFlag} />;
      case 'history_page':
        return <HistoryPageIcon $isMobileFlag={isMobileFlag} />;
      case 'collections_page':
        return <CollectionsPageIcon $isMobileFlag={isMobileFlag} />;
      case 'hoplun_portal':
        return <HoplunPortalIcon isMobileFlag={isMobileFlag} />;
      default:
        return <></>;
    }
  };

  const handleHoplunRedirect = async () => {
    const hoplunKey = await getHoplunToken();
    const hoplunUrl = `https://aioonline.hoplun.com/api/data/view.ashx?userKey=${hoplunKey}&path=/Vendor/workspace/Index.aspx&source=delvify`;
    window.open(hoplunUrl, '_blank');
  };

  // Using useMemo to reduce times function is called
  const menuItems = useMemo(() => {
    const noGroupRouteKeys = ['home_page'];
    // Designer Sidebar routes
    let librariesGroupRouteKeys = [
      'fabrics_page',
      'import_page',
      'collections_page',
      'projects_page',
    ];
    let workspaceGroupRouteKeys = [
      'user_management_page',
      'hangers_page',
      'inspirations_page',
      'suppliers_page',
      'certifications_page',
      'colours_page',
      'history_page',
      'bin_page',
    ];
    // Supplier Sidebar routes
    if (role === 'supplier') {
      librariesGroupRouteKeys = [
        'fabrics_page',
        'import_page',
        'trims_page',
        'garments_page',
        'projects_page',
      ];
      workspaceGroupRouteKeys = [
        'collections_page',
        'user_management_page',
        'hangers_page',
        'inspirations_page',
        'certifications_page',
        'colours_page',
        'history_page',
        'bin_page',
      ];
    }
    const routes = getSidebarRoutes(role);

    let items: SidebarItem[] = [];

    items = [
      ...items,
      ...routes
        .filter((route) => noGroupRouteKeys.includes(route.key as string))
        .map((route) => ({
          key: route.key,
          icon: iconMap(route.key as string),
          label: (
            <Link
              {...(isMobileFlag && { onClick: () => sideBarCollapse() })}
              style={{
                fontSize: isMobileFlag ? '18px' : '14px',
              }}
              to={route.path}
            >
              {i18n.t(`page_titles:${route.key}`)}
            </Link>
          ),
        })),
    ];

    items = [
      ...items,
      {
        type: 'group',
        label:
          role === 'supplier'
            ? i18n.t('headers:libraries')
            : i18n.t('headers:data'),
      },
      ...routes
        .filter((route) =>
          librariesGroupRouteKeys.includes(route.key as string)
        )
        .map((route) => ({
          key: route.key,
          icon: iconMap(route.key as string),
          label: (
            <Link
              {...(isMobileFlag && { onClick: () => sideBarCollapse() })}
              style={{
                fontSize: isMobileFlag ? '18px' : '14px',
              }}
              to={route.path}
            >
              {i18n.t(`page_titles:${route.key}`)}
            </Link>
          ),
        })),
    ];

    items = [
      ...items,
      {
        type: 'group',
        label: i18n.t('headers:workspace'),
      },
      ...routes
        .filter((route) =>
          workspaceGroupRouteKeys.includes(route.key as string)
        )
        .map((route) => ({
          key: route.key,
          icon: iconMap(route.key as string),
          label: (
            <Link
              {...(isMobileFlag && { onClick: () => sideBarCollapse() })}
              style={{
                fontSize: isMobileFlag ? '18px' : '14px',
              }}
              to={route.path}
            >
              {i18n.t(`page_titles:${route.key}`)}
            </Link>
          ),
        })),
    ];
    if (hoplunUsername) {
      items = [
        ...items,
        {
          key: 'hoplun_portal',
          icon: iconMap('hoplun_portal'),
          label: (
            <a role="button" onClick={handleHoplunRedirect}>
              {i18n.t('headers:hoplun_portal_sidebar')}
            </a>
          ),
        },
      ];
    }

    return items;
  }, [role, hoplunUsername]);

  return (
    <Sider
      width={siderBarWidth}
      collapsible
      trigger={null}
      collapsed={isSidebarCollapsed}
      collapsedWidth="0"
      //Break point id the state transition for mobile
      breakpoint="md"
      // Handle initial mobile state, triggered by breakpoint above
      onCollapse={(currentState) => {
        dispatch(toggleSidebar(currentState));
      }}
    >
      <MenuWrapper
        style={{ paddingTop: '60px' }}
        $black={true}
        mode="inline"
        selectable={false}
        items={menuItems as ItemType<MenuItemType>[]}
      />
    </Sider>
  );
}
