import { Button, Form } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { FormWrapper } from '../../../elements/wrapper/FormWrapper';
import i18n from '../../../plugins/i18n';
import { CreateBrandData } from '../../../utils/backendRequests/admin/AdminTypes';
import { adminCreateBrand } from '../../../utils/backendRequests/admin/createBrand';
import { parseFirstCharToUpper } from '../../../utils/parsers/parseFirstCharToUpper';
import { nullParser } from '../../../utils/parsers/parseNullValues';
import { Modal } from '../../Modal';
import { FormItems } from '../../form/FormItems';
import { commonBrandFields, initialFormFields } from './brandFormFields';
import { brandFieldRules } from './brandFormRules';

interface CreateBrandModalProps {
  type: 'designer' | 'supplier';
  modal: { visible: boolean };
  setModal: (modal: { visible: boolean }) => void;
}

export function CreateBrandModal({
  type,
  modal,
  setModal,
}: CreateBrandModalProps) {
  const [form] = Form.useForm();
  const [propsWithDropDownObj, setPropsWithDropDownObj] = useState({});

  const fabricRulesConst = useMemo(
    () => brandFieldRules(commonBrandFields),
    []
  );
  // Close modal
  const handleCancel = () => {
    setModal({
      visible: false,
    });
  };

  useEffect(() => {
    form.setFieldsValue(initialFormFields(commonBrandFields));
  }, [form]);

  const handleSubmit = async (values: CreateBrandData) => {
    const parsedBrandData = nullParser(values) as CreateBrandData;
    if (await adminCreateBrand({ type: type, data: parsedBrandData })) {
      form.resetFields();
      setModal({
        visible: false,
      });
    }
  };

  return (
    <Modal
      title={`New ${parseFirstCharToUpper(type)} Brand`}
      open={modal.visible}
      onCancel={handleCancel}
      footer={null}
    >
      <FormWrapper>
        <Form form={form} onFinish={handleSubmit}>
          <FormItems
            formFields={commonBrandFields}
            propsWithDropDownObj={propsWithDropDownObj}
            setPropsWithDropDownObj={setPropsWithDropDownObj}
            formRules={fabricRulesConst}
          />
          <Form.Item wrapperCol={{ offset: 20, span: 12 }}>
            <Button type="primary" htmlType="submit">
              {i18n.t('buttons:submit')}
            </Button>
          </Form.Item>
        </Form>
      </FormWrapper>
    </Modal>
  );
}
