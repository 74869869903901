import i18n from 'i18next';

import { Space } from 'antd';
import { Link } from 'react-router-dom';
import placeholderImage from '../../../assets/images/placeholder-image.png';
import { FrontImageStyled } from '../../../elements/images/StyledImages';
import { routesNamedDict } from '../../../routes';
import { parseFabricTitles } from '../../../utils/fabrics/parseFabricFields';
import { HangersChatModal } from '../../modal/hangers/HangersChatModal';
import { hangerRequestResp } from '../TableTypes';
import { HangerStatusRender } from './HangerStatusRender';

export const hangersColumns = (
  account: string | null,
  getHangerData: () => void,
  role: string | null
) => {
  const commonColumns = [
    {
      dataIndex: ['fabric', 'name'],
      title: i18n.t('column_titles:fabric_name'),
      columnType: 'string',
    },
    {
      dataIndex: 'image_url_front',
      title: i18n.t('column_titles:fabric_front_image'),
      render: (_: unknown, record: hangerRequestResp) => {
        const text = record.fabric.image_url_front;
        const imageKey = record.fabric.id + '_image_url_front';
        return (
          <FrontImageStyled
            key={imageKey}
            width={'8vw'}
            height={'8vw'}
            src={text ? text : placeholderImage}
            alt={parseFabricTitles(
              'image_url_front',
              record.fabric.measurement_unit
            )}
          />
        );
      },
    },
    {
      dataIndex: 'status',
      title: i18n.t('common:status'),
      columnType: 'string',
      render: (status: string) => <HangerStatusRender status={status} />,
    },
    {
      dataIndex: 'created_at',
      title: i18n.t('common:created_at'),
      columnType: 'date',
    },
    {
      dataIndex: 'updated_at',
      title: i18n.t('common:updated_at'),
      columnType: 'date',
    },
  ];

  // Handle adding columns specific to supplier or designer accounts
  if (role === 'supplier') {
    commonColumns.push({
      dataIndex: 'requester_designer_user_brand',
      title: i18n.t('common:requester_designer_user_brand'),
      columnType: 'string',
    });
    commonColumns.push({
      dataIndex: 'requester_designer_user_name',
      title: i18n.t('common:requester_designer_user_name'),
      columnType: 'string',
    });
  } else if (role === 'designer') {
    commonColumns.push({
      dataIndex: ['fabric', 'supplier_brand_name'],
      title: i18n.t('common:fabric_supplier'),
      columnType: 'string',
    });
  }

  const columnsWithActions = [
    ...commonColumns,
    {
      title: i18n.t('column_titles:action'),
      fixed: 'right',
      dataIndex: 'action',
      render: (_: unknown, record: hangerRequestResp) => {
        return (
          <Space size="middle">
            <>
              <HangersChatModal record={record} getHangerData={getHangerData} />
              <Link
                to={routesNamedDict.HANGERS_HANGERS_ID.replace(
                  ':fabricId',
                  record.fabric.id
                )}
              >
                {i18n.t('column_titles:view_fabric')}
              </Link>
            </>
          </Space>
        );
      },
    },
  ];

  return account !== 'viewer' ? columnsWithActions : commonColumns;
};
