import { useEffect, useState } from 'react';

import { TableGeneric } from '../../../components/table/TableGeneric';
import { ImageSearchListResponse } from '../../../utils/backendRequests/imageSearch/ImageSearchTypes';
import { getImageSearchList } from '../../../utils/backendRequests/imageSearch/getImageSearchList';
import { imageColumns } from './columns';

export const ImageSearchList = () => {
  const [data, setData] = useState<ImageSearchListResponse[]>([]);

  const getData = async () => {
    setData(await getImageSearchList());
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <TableGeneric
        columns={imageColumns}
        data={data}
        isBackendPaginated={false}
      />
    </>
  );
};
