import { api } from '../api/api';
import { ApprovedBrands } from './AdminTypes';

/**
 * Get all approved supplier brands for a given designer brand.
 */
export async function getApprovedSupplierBrands(
  designerBrandId: string
): Promise<ApprovedBrands> {
  const response = await api({
    method: 'GET',
    url: `v1/superuser/brand/designer/${designerBrandId}/approved_supplier_brand`,
    showErrMsg: true,
  });

  const approvedSupplierBrands = Object.entries(response).map(
    ([key, value]) => ({
      key: key,
      value: value,
    })
  ) as ApprovedBrands;
  return approvedSupplierBrands;
}
