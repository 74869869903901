import { message } from 'antd';

import i18n from '../../../plugins/i18n';
import { api } from '../api/api';

/**
 * Create a new colour which will be owned by
 * the user's supplier brand.
 */
export async function createColour(
  name: string,
  hex_value: string | null
): Promise<boolean> {
  const data = { name, hex_value };
  try {
    return await api({
      method: 'POST',
      url: `v1/colour`,
      body: data,
      showErrMsg: true,
    }).then(() => {
      message.success(i18n.t('long_messages:message_colour_created_success'));
      return true;
    });
  } catch (error) {
    return false;
  }
}
