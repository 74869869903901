import { api } from '../api/api';
import { AdminCertReviewResponse } from './AdminTypes';

/**
 * Gets all certification details which should be verified.
 */
export async function getCertificationsToVerify(): Promise<
  AdminCertReviewResponse[]
> {
  return await api({
    method: 'GET',
    url: `v1/superuser/certification/verify`,
    showErrMsg: true,
  }).then((res: AdminCertReviewResponse[]) => {
    return res;
  });
}
