import { Checkbox, Col, ColorPicker, Input, Row, Space } from 'antd';
import { ColorFactory } from 'antd/es/color-picker/color';
import i18n from 'i18next';
import { useEffect, useState } from 'react';

import { Color } from 'antd/lib/color-picker';
import { editColour } from '../../../utils/backendRequests/colours/editColours';
import { stringValid } from '../../../utils/validations/stringValidator';
import { Modal } from '../../Modal';
import { EditColourModalProps } from './ColourModalTypes';

// Define a type for the ColorFactory instance
type ColorFactoryInstance = ColorFactory;

export function EditColourModal({
  modal,
  setModal,
  record,
  onSuccess,
}: EditColourModalProps) {
  const defaultColor = new ColorFactory('#000000'); // Initialize with default color
  const [name, setName] = useState(record.name);
  const [colorHex, setColorHex] = useState<ColorFactoryInstance>(defaultColor);
  const [formatHex, setFormatHex] = useState<'hex' | 'rgb' | 'hsb' | undefined>(
    'hex'
  );
  const [nullColor, setNullColor] = useState<boolean>(false);

  useEffect(() => {
    setName(record.name);
    if (record.hex_value === null) {
      setNullColor(true);
      setColorHex(defaultColor);
    } else {
      setNullColor(false);
      const originalColor = new ColorFactory(record.hex_value);
      setColorHex(originalColor);
    }
  }, [record]);

  // Close modal
  const handleCancel = () => {
    setModal({ visible: false });
  };

  const handleNullColor = () => {
    setNullColor(!nullColor);
  };

  const handleOk = async () => {
    if (stringValid(name, 50, i18n.t('common:name'), false)) {
      let editResponse;
      if (nullColor) {
        editResponse = await editColour(name, null, record.id);
      } else {
        editResponse = await editColour(
          name,
          colorHex.toHexString(),
          record.id
        );
      }
      if (editResponse) {
        onSuccess();
        setModal({ visible: false });
        setNullColor(false);
      }
    }
  };

  // Function to handle color change in ColorPicker
  const handleColorChange = (color: Color) => {
    setColorHex(color as ColorFactoryInstance); // Update colorHex state with the new color
  };

  return (
    <Modal
      title={i18n.t('headers:edit_colour')}
      open={modal.visible}
      onCancel={handleCancel}
      cancelButtonProps={{ danger: true }}
      onOk={handleOk}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {`${i18n.t('common:name')}:`}
          <Input
            name={i18n.t('common:name')}
            onChange={(e) => setName(e.target.value)}
            showCount={true}
            value={name}
          />
        </Col>
        <Col span={24}>
          <Row>{`${i18n.t('column_titles:colour')}:`}</Row>
          <Space>
            <ColorPicker
              value={colorHex}
              format={formatHex}
              onChange={handleColorChange} // Update to use handleColorChange function
              onFormatChange={setFormatHex}
              disabledAlpha={true}
              disabled={nullColor}
              showText
            />
            <Checkbox checked={nullColor} onChange={handleNullColor}>
              {i18n.t('column_titles:no_color')}
            </Checkbox>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
}
