import { message } from 'antd';
import i18n from 'i18next';
import { api } from '../api/api';
import { CollectionListItem } from './CollectionTypes';

/**
 * Create a new collection which will be owned by
 * the user's supplier brand.
 */
export async function createCollection(
  name: string,
  description: string | null,
  fabricIds: string[],
  fieldsToShow: string[]
): Promise<CollectionListItem> {
  const data = {
    name: name,
    description: description,
    fabric_ids: fabricIds,
    fields_to_show: fieldsToShow,
  };
  return await api({
    method: 'POST',
    url: `v1/collection`,
    body: data,
    showErrMsg: true,
  }).then((res: CollectionListItem) => {
    message.success(i18n.t('long_messages:message_collection_created_success'));
    return res;
  });
}
