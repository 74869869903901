import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as DelvifyLogo } from '../assets/images/logos/delvify.svg';
import { HeaderBarDobby } from '../components/HeaderBarDobby';
import { SiderBarDobby } from '../components/SiderBarDobby';
import { LayoutContainer, MainLayoutWrapper } from '../elements/StyledUI';
import { ContentWrapper } from '../elements/wrapper/ContentWrapper';
import { routesNamedDict } from '../routes';
import { RootAuth, RootState } from '../utils/types/Types';
import { LayoutProps } from './LayoutTypes';

export function MainLayout({ children }: LayoutProps) {
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);
  const username = useSelector((state: RootAuth) => state.auth?.name || '');
  const role = useSelector((state: RootAuth) => state.auth?.role);
  const navigate = useNavigate();
  const { isSidebarCollapsed } = useSelector(
    (state: RootState) => state.common
  );
  const siderBarWidth = isMobileFlag ? '100vw' : '204px';

  useEffect(() => {
    const handleVerification = async () => {
      if (role === 'admin') {
        navigate(routesNamedDict.ADMIN);
      }
    };
    handleVerification();
  }, [navigate]);

  return (
    <LayoutContainer>
      <HeaderBarDobby
        logo={
          <Link to={routesNamedDict.HOME}>
            <DelvifyLogo />
          </Link>
        }
        userName={username}
      />
      <LayoutContainer hasSider>
        <SiderBarDobby siderBarWidth={siderBarWidth} />
        <MainLayoutWrapper
          $isMobileFlag={isMobileFlag}
          $isSidebarCollapsed={isSidebarCollapsed}
          $siderBarWidth={siderBarWidth}
        >
          <ContentWrapper>{children}</ContentWrapper>
        </MainLayoutWrapper>
      </LayoutContainer>
    </LayoutContainer>
  );
}
