import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { hangerRequestResp } from '../../components/table/TableTypes';
import { HangersList } from '../../components/table/hangersList/HangersList';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { MainLayout } from '../../layouts/Main';
import i18n from '../../plugins/i18n';
import { getHangerRequests } from '../../utils/backendRequests/hangers/getHangerRequests';
import { RootAuth } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export function HangersPage({ title }: TitleProp) {
  const [data, setData] = useState<hangerRequestResp[]>();
  const role = useSelector((state: RootAuth) => state.auth?.role);

  const _getHangerData = async () => {
    const hangerData = await getHangerRequests();
    setData(hangerData);
  };

  useEffect(() => {
    _getHangerData();
  }, []);

  const subtitleKey =
    role === 'supplier' ? 'hangers_supplier_sub' : 'hangers_designer_sub';

  return (
    <MainLayout>
      <HeaderTitle
        title={i18n.t('page_titles:hanger_requests')}
        subtitle={i18n.t(`page_subtitles:${subtitleKey}`)}
      />
      {data && data.length > 0 && (
        <HangersList
          data={data as hangerRequestResp[]}
          title={title}
          getHangerData={_getHangerData}
        />
      )}
    </MainLayout>
  );
}
