import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminLabelList } from '../../components/table/adminLabelsList/AdminLabelList';
import { TabTitle } from '../../components/TabTitle';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { ButtonBasic } from '../../elements/buttons/StyledButtons';
import { TextB2 } from '../../elements/typography/TextB2';
import { AdminLayout } from '../../layouts/Admin';
import i18n from '../../plugins/i18n';
import { routesNamedDict } from '../../routes';
import { AdminLabelsListProps } from '../../utils/backendRequests/admin/AdminTypes';
import { getFabricLabels } from '../../utils/backendRequests/admin/getFabricLabels';
import { TitleProp } from '../PagesTypes';

/**
 * Admin page for viewing and updating the machine learning training label
 * of all lace fabrics.
 */
export function AdminTrainingLabelsListPage({ title }: TitleProp) {
  const [data, setData] = useState<AdminLabelsListProps>();
  const navigate = useNavigate(); // Initialize navigate function

  const getLabels = async () => {
    const labelData = await getFabricLabels();
    // Transform the data to the desired format
    const transformedData = labelData.map((label, index) => ({
      key: index, // unique key for each entry
      name: label ?? 'Label Not Specified', // replace null with 'Label Not Specified'
      isNull: label === null,
    }));
    setData(transformedData);
  };

  const getAllLabels = () => {
    navigate(routesNamedDict.ADMIN_ALL_FABRICS, {
      state: {
        isAllLabels: true,
      },
    });
  };

  useEffect(() => {
    getLabels();
  }, []);

  return (
    <AdminLayout>
      <HeaderTitle title={i18n.t('page_titles:training_labels')} />
      <TabTitle title={title} />
      <TextB2>
        To download all label folders with images use the script in the
        "ML_Image_To_Text" repo.
      </TextB2>
      <TextB2>
        To assign the "Label Not Specified" to a fabric, simply set the fabric's
        label to an empty string.
      </TextB2>
      <ButtonBasic onClick={() => getAllLabels()}>
        {i18n.t('buttons:all_labels')}
      </ButtonBasic>
      {data?.length && data.length > 0 && (
        <AdminLabelList title={title} data={data} />
      )}
    </AdminLayout>
  );
}
