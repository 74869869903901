import { message } from 'antd';
import i18n from 'i18next';

import { MsgProp } from '../../types/Types';
import { api } from '../api/api';

/**
 * Update the name and/or description of a collection.
 */
export async function updateCollectionDetails(
  collectionId: string,
  newName: string | null = null,
  newDescription: string | null = null,
  fieldsToShow: string[]
): Promise<MsgProp> {
  const data = {
    name: newName,
    description: newDescription,
    fields_to_show: fieldsToShow,
  };
  return await api({
    method: 'PUT',
    url: `v1/collection/${collectionId}`,
    body: data,
    showErrMsg: true,
  }).then((res: MsgProp) => {
    message.success(i18n.t('long_messages:message_collection_updated_success'));
    return res;
  });
}
