import { api } from '../api/api';

/**
 * Logs in the user to HopLun. If successful, returns the login token.
 */

export type hoplunTokenProps = {
  userKey: string;
};

export async function getHoplunToken(): Promise<string> {
  return await api({
    method: 'GET',
    url: 'v1/user/hoplun_token',
    showErrMsg: true,
  }).then((res: hoplunTokenProps) => {
    return res.userKey;
  });
}
