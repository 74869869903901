import { api } from '../api/api';

/**
 * Gets all chat data for a specified chatId.
 */
export const getChatById = async (chatId: string) => {
  const response = await api({
    method: 'GET',
    url: `v1/chat/${chatId}`,
    showErrMsg: true,
  });
  return response;
};
