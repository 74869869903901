import i18n from 'i18next';
import { Link, useLocation } from 'react-router-dom';
import { FabricDetailsLinkProps } from '../FabricTypes';

export function FabricDetailsLink({
  pageType,
  record,
}: FabricDetailsLinkProps) {
  const { pathname } = useLocation();
  let linkTo;
  switch (pageType) {
    case 'search':
      linkTo = `/search/${record.id}`;
      break;
    case 'all_fabrics':
      linkTo = `/all_fabrics/${record.id}`;
      break;
    default:
      linkTo = `${pathname}/${record.id}`;
  }

  return <Link to={linkTo}>{i18n.t('column_titles:view')}</Link>;
}
