import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import {
  NoAuthDesktopHeaderStyled,
  NoAuthStyledRow,
  StyledRow,
} from '../../elements/StyledUI';
import { HeaderWrapper } from '../../styles/wrappers/HeaderWrapper';
import { LogoWrapper } from '../../styles/wrappers/LogoWrapper';
import { RootState } from '../../utils/types/Types';

export function NoAuthHeaderBarDobby({ logo }: { logo: ReactNode }) {
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);

  return (
    <HeaderWrapper>
      {isMobileFlag ? (
        <StyledRow>
          <NoAuthDesktopHeaderStyled>
            <LogoWrapper $mainlayout={true}>{logo ? logo : 'Logo'}</LogoWrapper>
          </NoAuthDesktopHeaderStyled>
        </StyledRow>
      ) : (
        <NoAuthStyledRow>
          <NoAuthDesktopHeaderStyled>
            <LogoWrapper $mainlayout={true}>{logo ? logo : 'Logo'}</LogoWrapper>
          </NoAuthDesktopHeaderStyled>
        </NoAuthStyledRow>
      )}
    </HeaderWrapper>
  );
}
