import { api } from '../api/api';
import { Users, userType } from './AdminTypes';

/**
 * Get all users based on the type, as admin.
 */
export async function getUsers(type: userType): Promise<Users> {
  const response = await api({
    method: 'GET',
    url: `v1/superuser/user/${type}`,
    showErrMsg: true,
  });
  return response;
}
