import type { SelectProps } from 'antd';
import { Select } from 'antd';
import i18n from '../../plugins/i18n';

const { Option } = Select;

/*
 * Renders a searchable dropdown select component with provided options.
 */

interface SelectWithSearchProps extends SelectProps {
  options: { label: string; value: string | number }[];
  isTranslatable?: boolean;
}

export const SelectWithSearch = ({
  options,
  isTranslatable = false,
  ...restProps
}: SelectWithSearchProps) => {
  return (
    <Select
      showSearch
      allowClear
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.children as unknown as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      {...restProps}
    >
      {options.map(({ value, label }, i) => (
        <Option value={value} key={i}>
          {isTranslatable || label == 'none' ? i18n.t(label) : label}
        </Option>
      ))}
    </Select>
  );
};
