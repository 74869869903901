import { useEffect, useState } from 'react';

import { TableGeneric } from '../../../components/table/TableGeneric';
import { Brand } from '../../../utils/backendRequests/admin/AdminTypes';
import { TabTitle } from '../../TabTitle';
import { AdminBrandListProps, columnFields } from '../../table/TableTypes';
import { designerBrandColumns } from './designerBrandColumns';
import { supplierBrandColumns } from './supplierBrandColumns';

export function AdminBrandList({
  listType,
  data,
  supplierBrands,
  title,
  getBrands,
}: AdminBrandListProps) {
  const [columns, setColumns] = useState<columnFields[]>([]);

  // Supplier Brands is used as data for designers when approving of brands
  useEffect(() => {
    listType === 'designer'
      ? setColumns(designerBrandColumns(supplierBrands as Brand[], getBrands))
      : setColumns(supplierBrandColumns(getBrands));
  }, [listType, supplierBrands]);

  return (
    <>
      {data[0] && <TabTitle title={title} />}
      <TableGeneric columns={columns} data={data} isBackendPaginated={false} />
    </>
  );
}
