import { message } from 'antd';
import i18n from 'i18next';

import { TransformedFilterProps } from '../../../components/filter/FilterTypes';
import { defaultPageSize } from '../../../components/table/TableGeneric';
import { routesNamedDict } from '../../../routes';
import {
  FabricTableReqProps,
  NavigateProp,
  Paths,
  TableStateObj,
} from '../../types/Types';
import { api } from '../api/api';
import { TextSearchShowResponse } from './TextSearchTypes';

/**
 * Creates a text search for fabrics.
 */

export async function searchTextFabric(
  query: {
    text_query: string;
    exclude_ml_fields: boolean;
    new_search: boolean;
  },
  navigate: NavigateProp,
  paths: Paths,
  tableState: TableStateObj | null = null,
  filterableColumns: TransformedFilterProps | null = null
): Promise<TextSearchShowResponse> {
  try {
    let params: FabricTableReqProps = {
      fabrics_page: 1,
      fabrics_page_size: defaultPageSize,
    };

    if (tableState !== null) {
      params = {
        fabrics_page: tableState.current,
        fabrics_page_size: tableState.pageSize,
      };
      // Add sort parameters only if both sortField and sortOrder have values
      if (tableState.sortField && tableState.sortOrder) {
        params.fabrics_sort_by = tableState.sortField;
        params.fabrics_sort_order = tableState.sortOrder;
      }
    }
    if (filterableColumns !== null)
      params.fabrics_filter = JSON.stringify(filterableColumns);

    const response = await api({
      method: 'POST',
      url: 'v1/text_search/fabric',
      body: query,
      params: params,
    });

    // Returns 200 with [] if there's no match in database, so return error message if [] is returned
    if (!response.result_items[0]) {
      const errorBody =
        i18n.t('long_messages:no_fabrics_found_part1_text_search') + ' ';
      // Added margin top so error message does not block the text search bar
      message.error({
        content: (
          <span>
            {errorBody}
            <a href={routesNamedDict.FABRICS}>
              {i18n.t('long_messages:message_no_fabrics_found_part2')}
            </a>
          </span>
        ),
        duration: 6,
        style: {
          marginTop: '41px',
        },
      });
    } else {
      if (tableState === null && filterableColumns === null) {
        navigate(
          paths.SEARCH_TEXT_RESULTS +
            `?q=${encodeURIComponent(response.text_query)}&exclude_ml_fields=${response.are_ml_fields_excluded}`,
          {
            state: {
              result_fabrics: response.result_items,
              text_query: response.text_query,
              fabrics_count: response.result_items_count,
              selectable_columns: response.selectable_columns,
              filterable_columns: response.filterable_columns,
              sortable_columns: response.sortable_columns,
              exclude_ml_fields: response.are_ml_fields_excluded,
            },
          }
        );
      }
    }
    return response;
  } catch (error) {
    message.error(i18n.t('error_messages:error_creating_text_search'));
    throw error;
  }
}
