import { DatePicker, Form, Input, InputNumber, Switch, message } from 'antd';
import { useEffect, useMemo } from 'react';

import { getEditOptions } from '../../utils/backendRequests/list/lists';
import { parseFormLabel } from '../../utils/parsers/parseFormLabel';
import { sortDropdownOptionsByTranslation } from '../../utils/parsers/sortByTranslation';
import { SelectWithSearch } from '../search/SelectWithSearch';
import { FormItemsProps, formFieldsProp } from './FormTypes';

const { TextArea } = Input;

/**
 * FormItems Component
 *
 * Renders form items based on the provided configuration and fetches select options from the backend.
 */
export function FormItems({
  formFields,
  propsWithDropDownObj,
  setPropsWithDropDownObj,
  formRules = {},
  parseFieldTitle = parseFormLabel,
}: FormItemsProps) {
  // Sorts the dropdown options according to translation output
  const sortedPropsWithDropDownObj = useMemo(() => {
    return sortDropdownOptionsByTranslation(propsWithDropDownObj);
  }, [propsWithDropDownObj]);

  useEffect(() => {
    async function _fetchDropDownOptions() {
      const fetchOptionPromise = formFields
        .filter(
          (field) =>
            typeof field.fieldType === 'string' &&
            field.fieldType.includes('Dropdown')
        )
        .map((field) => getEditOptions(field, setPropsWithDropDownObj));

      await Promise.all(fetchOptionPromise).catch((err) => {
        message.error(err.message);
      });
    }

    _fetchDropDownOptions();
  }, [formFields, setPropsWithDropDownObj]);

  const renderFormComponent = (
    field: formFieldsProp,
    additionalProps: Record<string, unknown>
  ) => {
    if (field.fieldType === 'stringArea') {
      return (
        <TextArea {...additionalProps} autoSize={{ minRows: 2, maxRows: 6 }} />
      );
    } else if (field.fieldType === 'singleDropdown') {
      return (
        <SelectWithSearch
          {...additionalProps}
          options={sortedPropsWithDropDownObj[field.name] || []}
          isTranslatable={field.isTranslatable}
        />
      );
    } else if (field.fieldType === 'multiDropdown') {
      return (
        <SelectWithSearch
          mode="multiple"
          {...additionalProps}
          options={sortedPropsWithDropDownObj[field.name] || []}
          isTranslatable={field.isTranslatable}
        />
      );
    } else if (field.fieldType === 'number') {
      return <InputNumber {...additionalProps} style={{ width: '100%' }} />;
    } else if (field.fieldType === 'numberInteger') {
      return (
        <InputNumber
          {...additionalProps}
          style={{ width: '100%' }}
          precision={0}
        />
      );
    } else if (field.fieldType === 'boolean') {
      return <Switch {...additionalProps} />;
    } else if (field.fieldType === 'date') {
      return <DatePicker {...additionalProps} style={{ width: '100%' }} />;
    }

    return <Input {...additionalProps} />;
  };

  return formFields.map((field) => {
    const additionalProps: Record<string, unknown> = {
      placeholder: `${parseFieldTitle(field.name)}`,
    };

    return (
      <Form.Item
        label={parseFieldTitle(field.name)}
        name={field.name}
        key={field.name}
        rules={formRules[field.name]}
        valuePropName={field.fieldType === 'boolean' ? 'checked' : 'value'}
      >
        {renderFormComponent(field, additionalProps)}
      </Form.Item>
    );
  });
}
