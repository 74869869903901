import { api } from '../api/api';
import { BulkUpload } from './BulkUploadTypes';

/**
 * Gets all Bulk Upload entries from a supplier brand.
 */
export async function getBulkUploads() {
  return await api({
    method: 'GET',
    url: `v1/bulk_upload`,
    showErrMsg: true,
  }).then((res: BulkUpload[]) => {
    return res;
  });
}
