import { FilterValues } from '../FilterTypes';

export const createInitialFilterValues = (role: string) => {
  const initialFilterValues: FilterValues = {
    status: {
      filter_type: 'checkbox',
      values: [],
      has_null: false,
      required: true,
      grouping: 'other',
    },
    created_at: {
      filter_type: 'date',
      values: [],
      has_null: false,
      required: true,
      grouping: 'other',
    },
    updated_at: {
      filter_type: 'date',
      values: [],
      has_null: true,
      required: false,
      grouping: 'other',
    },
    ...(role === 'supplier'
      ? {
          requester_designer_user_brand: {
            filter_type: 'checkbox',
            values: [],
            has_null: false,
            required: true,
            grouping: 'other',
          },
          requester_designer_user_name: {
            filter_type: 'checkbox',
            values: [],
            has_null: false,
            required: true,
            grouping: 'other',
          },
        }
      : {}),
    ...(role === 'designer'
      ? {
          'fabric.supplier_brand_name': {
            filter_type: 'checkbox',
            values: [],
            has_null: false,
            required: true,
            grouping: 'other',
          },
        }
      : {}),
  };
  return initialFilterValues;
};
