import { message } from 'antd';
import i18n from 'i18next';
import { MsgProp } from '../../types/Types';
import { api } from '../api/api';

/**
 * Copies all the fabrics from a collection to an existing collection.
 */
export async function copyCollectionToCollection(
  srcCollection: string,
  destCollection: string
): Promise<MsgProp> {
  const data = {
    src_collection_id: srcCollection,
    dst_collection_id: destCollection,
  };
  return await api({
    method: 'POST',
    url: 'v1/collection/copy_from_collection',
    body: data,
    showErrMsg: true,
  }).then((res: MsgProp) => {
    message.success(i18n.t('long_messages:message_collection_copy_success'));
    return res;
  });
}
