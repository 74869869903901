import { SelectProps } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getApprovedSupplierBrands } from '../../../utils/backendRequests/admin/getApprovedSupplierBrands';
import { updateApprovedBrands } from '../../../utils/backendRequests/admin/updateApprovedBrands';
import { Modal } from '../../Modal';
import { SelectWithSearch } from '../../search/SelectWithSearch';
import { BrandValue, ManageApprovedSupplierBrandsProps } from '../TableTypes';
import { ApprovedSupplierBrands } from './ApprovedSupplierBrands';

export function ManageApprovedSupplierBrands({
  designerBrandId,
  supplierBrands,
}: ManageApprovedSupplierBrandsProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [approvedSupplierBrands, setApprovedSupplierBrands] = useState<
    BrandValue[]
  >([]);
  const [selectedBrand, setSelectedBrand] = useState<BrandValue | null>(null);
  const [unapprovedSupplierBrands, setUnapprovedSupplierBrands] = useState<
    BrandValue[]
  >([]);

  async function _getApprovedBrands() {
    const supplierBrandsData = await getApprovedSupplierBrands(designerBrandId);
    setApprovedSupplierBrands(supplierBrandsData);
  }

  useEffect(() => {
    _getApprovedBrands();
  }, [designerBrandId]);

  useEffect(() => {
    const approvedBrandIds = approvedSupplierBrands.map((brand) => brand.key);
    setUnapprovedSupplierBrands(
      supplierBrands.filter((brand) => !approvedBrandIds.includes(brand.key))
    );
  }, [approvedSupplierBrands, supplierBrands]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    if (selectedBrand) {
      const selectedBrandId = selectedBrand.value;
      await updateApprovedBrands({
        designerBrandId: designerBrandId,
        supplierId: selectedBrandId,
      });
      // Updates list of approved supplier brands after API call is completed
      await _getApprovedBrands();
      // Clears the list of selected brands to prevent resubmission of the same brands
      setSelectedBrand(null);
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange: SelectProps['onChange'] = (value, option) => {
    if (!Array.isArray(option)) {
      setSelectedBrand(option as BrandValue);
    }
  };

  return (
    <>
      <div style={{ marginBottom: '10px' }}>
        <Link onClick={showModal} to={''}>
          Manage
        </Link>
      </div>
      <Modal
        title="Approve Brands"
        open={isModalOpen}
        okText="Send"
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <SelectWithSearch
          style={{ width: '100%' }}
          placeholder="Add more brands"
          onChange={handleChange}
          options={unapprovedSupplierBrands.map((brand) => ({
            value: brand.key,
            label: brand.value, // Assuming value is the display name of the brand
          }))}
          value={selectedBrand?.value || null}
        />
        <ApprovedSupplierBrands
          approvedSupplierBrands={approvedSupplierBrands}
        />
      </Modal>
    </>
  );
}
