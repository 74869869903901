import { Checkbox, Col, Row, Space } from 'antd';
import { Dayjs } from 'dayjs';
import i18n from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FileUpload } from '../../../components/upload/FileUpload';
import { ImageUpload } from '../../../components/upload/ImageUpload';
import {
  AttachmentsFormText,
  FabricFormAttachmentContainer,
  FabricFormUploadContainer,
  FormTitleFieldsText,
  MobileUploadContainer,
  StyledHorizontalSpace,
  StyledVerticalSpace,
  UploadFormText,
} from '../../../elements/fabrics/fabricStyledElement';
import { HelpIcon } from '../../../elements/icons/StyledIcons';
import { TextB3 } from '../../../elements/typography/TextB3';
import {
  CenteredUploadWrapper,
  FormMobileCheckboxWrapper,
  FormWrapper,
  MobileCheckboxContentWrapper,
  MobileCheckboxRow,
  MobileFormWrapper,
} from '../../../elements/wrapper/FormWrapper';
import { createFabric } from '../../../utils/backendRequests/fabric/createFabric';
import { parseFabricTitles } from '../../../utils/fabrics/parseFabricFields';
import { nullParser } from '../../../utils/parsers/parseNullValues';
import { RootAuth } from '../../../utils/types/Types';
import { FabricForm } from '../../form/FabricForm';
import { formFieldsProp } from '../../form/FormTypes';
import {
  CompositeFieldCheckProps,
  CreateFabricFormProps,
  FabricFormSubmitProps,
  FabricSellingOption,
} from '../FabricTypes';
import {
  compositeFabricFieldCheck,
  fabricFieldRules,
} from '../fabricFormRules';
import {
  formFields,
  getSelectedFormFields,
  sellingMethodOptions,
} from '../formFields';

/**
 * FabricForm component
 * */
export function CreateFabricForm({
  libraryId,
  isNarrowLayout,
}: CreateFabricFormProps) {
  const { measurementUnit } = useSelector((state: RootAuth) => state.auth);
  const [backImg, setBackImg] = useState<File | null>(null);
  const [backImgUrl, setBackImgUrl] = useState<string | null>(null);
  const [frontImg, setFrontImg] = useState<File | null>(null);
  const [frontImgUrl, setFrontImgUrl] = useState<string | null>(null);
  const [headerImg, setHeaderImg] = useState<File | null>(null);
  const [headerImgUrl, setHeaderImgUrl] = useState<string | null>(null);
  const [macroImg, setMacroImg] = useState<File | null>(null);
  const [macroImgUrl, setMacroImgUrl] = useState<string | null>(null);
  const [propsWithDropDownObj, setPropsWithDropDownObj] = useState({});
  const [attachments, setAttachments] = useState<File[] | null>([]);
  const [filteredFields, setFilteredFields] = useState<formFieldsProp[]>();

  // Initialize the selling options
  const [sellingOptions, setSellingOptions] =
    useState<FabricSellingOption[]>(sellingMethodOptions);

  const fabricRulesConst = useMemo(
    () => fabricFieldRules(measurementUnit),
    [measurementUnit]
  );
  const navigate = useNavigate();

  useEffect(() => {
    const showableFields = getSelectedFormFields(sellingOptions);
    setFilteredFields(showableFields);
  }, [sellingOptions]);

  const uploadBack = (
    <ImageUpload
      fieldName={i18n.t('fabric_fields:image_url_back')}
      setImage={setBackImg}
      imageUrl={backImgUrl}
      setImageUrl={setBackImgUrl}
    />
  );
  const uploadFront = (
    <ImageUpload
      fieldName={i18n.t('fabric_fields:image_url_front')}
      setImage={setFrontImg}
      imageUrl={frontImgUrl}
      setImageUrl={setFrontImgUrl}
    />
  );
  const uploadHeader = (
    <ImageUpload
      fieldName={i18n.t('fabric_fields:image_url_header')}
      setImage={setHeaderImg}
      imageUrl={headerImgUrl}
      setImageUrl={setHeaderImgUrl}
    />
  );
  const uploadMacro = (
    <ImageUpload
      fieldName={i18n.t('fabric_fields:image_url_macro')}
      setImage={setMacroImg}
      imageUrl={macroImgUrl}
      setImageUrl={setMacroImgUrl}
    />
  );

  const fileUpload = <FileUpload setAttachments={setAttachments} />;

  const handleSubmit = async (fabricData: FabricFormSubmitProps) => {
    if (!fabricData) return;
    let values = fabricData;
    // Parses field values to null if they are empty strings
    values = nullParser(values) as FabricFormSubmitProps;
    // Format creation_date following the format of date from python datetime
    if (values.creation_date) {
      values.creation_date = (values.creation_date as Dayjs).format(
        'YYYY-MM-DD'
      );
    }

    // Replace form field with correct colours field for backend and delete
    // colour_ids intermediary form field
    if ('colour_ids' in values) {
      values.colours = values.colour_ids as string[];
      delete (values as { colour_ids?: string[] }).colour_ids;
    }

    // Check if composite fields are fulfilled
    if (
      !compositeFabricFieldCheck(values as unknown as CompositeFieldCheckProps)
    )
      return;

    values = { ...values, library_id: libraryId };

    // Create formData, only append non-null images
    const formData: FormData = new FormData();
    if (frontImg) formData.append('image_front', frontImg);
    if (backImg) formData.append('image_back', backImg);
    if (headerImg) formData.append('image_header', headerImg);
    if (macroImg) formData.append('image_macro', macroImg);
    if (attachments && attachments.length > 0) {
      for (const file of attachments) {
        formData.append('attachments', file);
      }
    }
    formData.append('fabric', JSON.stringify(values));
    await createFabric(formData);
    navigate(-1);
  };

  const parseFabricTitleWithUnit = (name: string) => {
    return parseFabricTitles(name, measurementUnit);
  };

  // Toggle checkbox selection
  const handleCheckboxChange = (label: string) => {
    setSellingOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.label === label
          ? { ...option, isChecked: !option.isChecked }
          : option
      )
    );
  };

  const tooltipText = i18n.t('long_messages:sell_fabric_by_field_message');

  return (
    <>
      {isNarrowLayout ? (
        <MobileFormWrapper>
          <CenteredUploadWrapper>
            <UploadFormText>
              {i18n.t('fabric_fields:attachments')}
            </UploadFormText>
            {fileUpload}
            <TextB3>{i18n.t('long_messages:upload_file_rule')}</TextB3>
            <UploadFormText>
              {i18n.t('fabric_fields:image_url_macro')}
            </UploadFormText>
            <MobileUploadContainer>{uploadMacro}</MobileUploadContainer>
            <UploadFormText>
              {i18n.t('fabric_fields:image_url_header')}
            </UploadFormText>
            <MobileUploadContainer>{uploadHeader}</MobileUploadContainer>
            <UploadFormText>
              {i18n.t('fabric_fields:image_url_front')}
            </UploadFormText>
            <MobileUploadContainer>{uploadFront}</MobileUploadContainer>
            <UploadFormText>
              {i18n.t('fabric_fields:image_url_back')}
            </UploadFormText>
            <MobileUploadContainer>{uploadBack}</MobileUploadContainer>
          </CenteredUploadWrapper>
          <FormMobileCheckboxWrapper>
            <FormTitleFieldsText>
              {i18n.t('headers:sell_fabric')}
              <HelpIcon title={tooltipText} margin="0 3px 0 3px" />
            </FormTitleFieldsText>
            <MobileCheckboxContentWrapper>
              {sellingOptions.map((option) => (
                <MobileCheckboxRow key={option.label}>
                  <Checkbox
                    key={option.label}
                    checked={option.isChecked}
                    onChange={() => handleCheckboxChange(option.label)}
                  >
                    {i18n.t(`headers:${option.label}`)}
                  </Checkbox>
                </MobileCheckboxRow>
              ))}
            </MobileCheckboxContentWrapper>
          </FormMobileCheckboxWrapper>
          <FabricForm
            formFields={formFields}
            propsWithDropDownObj={propsWithDropDownObj}
            setPropsWithDropDownObj={setPropsWithDropDownObj}
            formRules={fabricRulesConst}
            parseFieldTitle={parseFabricTitleWithUnit}
            filterFields={filteredFields as formFieldsProp[]}
            handleSubmit={handleSubmit}
            submitTextKey="create"
            isNarrowLayout={false}
          />
        </MobileFormWrapper>
      ) : (
        <StyledHorizontalSpace>
          <FabricFormUploadContainer>
            <StyledVerticalSpace>
              <Row gutter={[16, 16]} justify="center">
                <Col span={12}>
                  <UploadFormText>
                    {i18n.t('fabric_fields:attachments')}
                  </UploadFormText>
                  {fileUpload}
                </Col>
                <FabricFormAttachmentContainer span={12}>
                  <AttachmentsFormText>
                    {i18n.t('long_messages:upload_file_rule')}
                  </AttachmentsFormText>
                </FabricFormAttachmentContainer>
              </Row>
              <Row gutter={[16, 16]} justify="center">
                <Col span={12}>
                  <UploadFormText>
                    {i18n.t('fabric_fields:image_url_macro')}
                  </UploadFormText>
                  {uploadMacro}
                </Col>
                <Col span={12}>
                  <UploadFormText>
                    {i18n.t('fabric_fields:image_url_header')}
                  </UploadFormText>
                  {uploadHeader}
                </Col>
              </Row>
              <Row gutter={[16, 16]} justify="center">
                <Col span={12}>
                  <UploadFormText>
                    {i18n.t('fabric_fields:image_url_front')}
                  </UploadFormText>
                  {uploadFront}
                </Col>
                <Col span={12}>
                  <UploadFormText>
                    {i18n.t('fabric_fields:image_url_back')}
                  </UploadFormText>
                  {uploadBack}
                </Col>
              </Row>
            </StyledVerticalSpace>
          </FabricFormUploadContainer>
          <Col>
            <FormWrapper>
              <>
                <FormTitleFieldsText>
                  {i18n.t('headers:sell_fabric')}
                  <HelpIcon title={tooltipText} margin="0 3px 0 3px" />
                </FormTitleFieldsText>
                <Space direction="horizontal">
                  {sellingOptions.map((option) => (
                    <Checkbox
                      key={option.label}
                      checked={option.isChecked}
                      onChange={() => handleCheckboxChange(option.label)}
                    >
                      {i18n.t(`headers:${option.label}`)}
                    </Checkbox>
                  ))}
                </Space>
              </>
              <FabricForm
                formFields={formFields}
                propsWithDropDownObj={propsWithDropDownObj}
                setPropsWithDropDownObj={setPropsWithDropDownObj}
                formRules={fabricRulesConst}
                parseFieldTitle={parseFabricTitleWithUnit}
                filterFields={filteredFields as formFieldsProp[]}
                handleSubmit={handleSubmit}
                submitTextKey="create"
                isNarrowLayout={true}
              />
            </FormWrapper>
          </Col>
        </StyledHorizontalSpace>
      )}
    </>
  );
}
