import { api } from '../api/api';
import { AdminSharedLinksResp } from './AdminTypes';

/**
 * Gets all users information about shared collection links.
 */
export async function getsharedUserLinks(): Promise<AdminSharedLinksResp> {
  return await api({
    method: 'GET',
    url: `v1/superuser/shareable_link`,
    showErrMsg: true,
  }).then((res: AdminSharedLinksResp) => {
    return res;
  });
}
