import { Button, Collapse, Drawer } from 'antd';
import { Dayjs } from 'dayjs';
import i18n from 'i18next';
import { useEffect, useMemo, useState } from 'react';

import { dayjs } from '../../../plugins/dayjs';
import { Setter } from '../../../utils/types/Types';
import {
  BackendFilterProps,
  FilterValues,
  GenericFilterDrawerProps,
} from '../FilterTypes';
import { BooleanCheckboxFilter } from '../filterTemplate/BooleanCheckboxFilter';
import { CheckboxFilter } from '../filterTemplate/CheckboxFilter';
import { DateRangeFilter } from '../filterTemplate/DateRangeFilter';
import { NumberRangeFilter } from '../filterTemplate/NumberRangeFilter';
import { createInitialChoicesOrRange } from './createInitialChoicesOrRange';

/**
 * Generic filter for non fabric tables.
 *
 * Note: This logic cannot handle backend pagination.
 */
export function GenericFilterDrawer({
  initialData,
  setFilteredData,
  filterOpen,
  closeFilter,
  createInitialFilterValues,
  role,
}: GenericFilterDrawerProps) {
  const initialFilterValues = useMemo(
    () => createInitialFilterValues(role),
    []
  );
  const initialChoicesOrRange = useMemo(
    () => createInitialChoicesOrRange(initialFilterValues),
    [initialFilterValues]
  );
  const [currentFilterValues, setCurrentFilterValues] =
    useState<FilterValues>(initialFilterValues);

  useEffect(() => {
    setCurrentFilterValues(initialFilterValues);
  }, [initialFilterValues]);

  const [ChoicesOrRange, setChoicesOrRange] = useState(initialChoicesOrRange);

  useEffect(() => {
    const _getDateRange = (
      field: string,
      ChoicesOrRangeToUpdate: Record<string, unknown>,
      row: Record<string, unknown>
    ) => {
      const dayjsDate = dayjs(row[field] as string, 'YYYY-MM-DD');
      if (!row[field] || !dayjsDate.isValid()) return;
      if ((ChoicesOrRangeToUpdate[field] as Dayjs[]).length === 0)
        ChoicesOrRangeToUpdate[field] = [dayjsDate, dayjsDate];
      if (dayjsDate.isBefore((ChoicesOrRangeToUpdate[field] as Dayjs[])[0]))
        (ChoicesOrRangeToUpdate[field] as Dayjs[])[0] = dayjsDate;
      if (dayjsDate.isAfter((ChoicesOrRangeToUpdate[field] as Dayjs[])[1]))
        (ChoicesOrRangeToUpdate[field] as Dayjs[])[1] = dayjsDate;
    };

    const _getNumberRange = (
      field: string,
      ChoicesOrRangeToUpdate: Record<string, unknown>,
      row: Record<string, unknown>
    ) => {
      const value = Number(row[field]);
      if (isNaN(value)) return;
      if ((ChoicesOrRangeToUpdate[field] as number[]).length === 0)
        ChoicesOrRangeToUpdate[field] = [value, value];
      if (value < (ChoicesOrRangeToUpdate[field] as number[])[0])
        (ChoicesOrRangeToUpdate[field] as number[])[0] = value;
      if (value > (ChoicesOrRangeToUpdate[field] as number[])[1])
        (ChoicesOrRangeToUpdate[field] as number[])[1] = value;
    };

    const tempChoicesOrRange = structuredClone(initialChoicesOrRange);
    //TODO: update this logic to not require 2 variables
    for (const row of initialData) {
      for (const [key, value] of Object.entries(initialFilterValues)) {
        let resolvedValue;
        if (key.includes('.')) {
          // Handle nested keys
          resolvedValue = key
            .split('.')
            .reduce((acc, part) => acc?.[part], row);
        } else {
          resolvedValue = row[key];
        }

        if (value.filter_type === 'date') {
          // Process date filters
          _getDateRange(key, tempChoicesOrRange, { [key]: resolvedValue });
        } else if (value.filter_type === 'number') {
          // Process number filters
          _getNumberRange(key, tempChoicesOrRange, { [key]: resolvedValue });
        } else if (value.filter_type === 'checkbox') {
          // Collect unique checkbox values
          const checkboxValues = new Set(tempChoicesOrRange[key] ?? []);
          checkboxValues.add(resolvedValue);
          tempChoicesOrRange[key] = Array.from(checkboxValues);
        }
      }
    }

    // Sort the date range values
    Object.keys(tempChoicesOrRange).forEach((key) => {
      if (initialFilterValues[key].filter_type === 'date') {
        tempChoicesOrRange[key].sort((a, b) => (a.isAfter(b) ? 1 : -1));
      }
    });

    setChoicesOrRange(tempChoicesOrRange);
    setCurrentFilterValues((previousState) => {
      const newState = { ...previousState };
      Object.keys(tempChoicesOrRange).forEach((key) => {
        newState[key] = {
          ...previousState[key],
          values: tempChoicesOrRange[key],
        };
      });
      return newState;
    });
  }, [initialData, initialChoicesOrRange, initialFilterValues]);

  const isDayjs = (value: unknown): value is Dayjs => {
    return dayjs.isDayjs(value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const updateFilteredData = (filterValues: FilterValues) => {
    let filteredData = initialData;

    for (const [key, value] of Object.entries(filterValues)) {
      filteredData = filteredData.filter((element) => {
        const elementValue = key.includes('.')
          ? getNestedValue(element, key)
          : element[key];
        return value.has_null || elementValue !== null;
      });

      filteredData = filteredData.filter((element) => {
        const elementValue = key.includes('.')
          ? getNestedValue(element, key)
          : element[key];
        if (value.filter_type === 'date') {
          const elementDate = dayjs(elementValue, 'YYYY-MM-DD');
          return (
            elementValue === null ||
            value.values.length === 0 ||
            (isDayjs(elementDate) &&
              elementDate.isSameOrAfter(value.values[0]) &&
              elementDate.isSameOrBefore(value.values[1]))
          );
        } else if (value.filter_type === 'number') {
          if (value.values.length === 2) {
            const [min, max] = value.values;
            const numValue = Number(elementValue);
            return numValue >= min && numValue <= max;
          }
        } else if (value.filter_type === 'checkbox') {
          if (value.values.length > 0) {
            return value.values.includes(elementValue);
          }
          return false; // No checkbox selected, filter out all data
        } else if (value.filter_type === 'checkboxBoolean') {
          if (value.values.length > 0) {
            return (
              (value.values.includes(true) && elementValue === true) ||
              (value.values.includes(false) && elementValue === false)
            );
          }
          return false; // No checkbox selected, filter out all data
        }
        return true;
      });
    }
    setFilteredData(filteredData);
  };

  useEffect(() => {
    updateFilteredData(currentFilterValues);
  }, [currentFilterValues]);

  const resetFilters = () => {
    setFilteredData(initialData);
    setCurrentFilterValues((previousState) => {
      const newState = { ...previousState };
      Object.keys(ChoicesOrRange).forEach((key) => {
        newState[key] = {
          ...previousState[key],
          values: ChoicesOrRange[key],
          has_null: true,
        };
      });

      return newState;
    });
  };

  // Utility function to handle label logic
  const getFilterLabel = (key: string): string => {
    return i18n.t(
      `column_titles:${key.includes('.') ? key.split('.').pop() : key}`
    );
  };

  const filterItems = () => {
    const items: { label: string; key: number; children: React.ReactNode }[] =
      [];

    Object.keys(currentFilterValues).forEach((key, index) => {
      const label = getFilterLabel(key); // Use the label utility function
      if (currentFilterValues[key].filter_type === 'date') {
        items.push({
          label: label,
          key: index,
          children: (
            <DateRangeFilter
              range={ChoicesOrRange[key]}
              field={key}
              currentFilterValues={currentFilterValues}
              setCurrentFilterValues={
                setCurrentFilterValues as Setter<
                  FilterValues | BackendFilterProps
                >
              }
              updateFilteredData={updateFilteredData}
            />
          ),
        });
      } else if (currentFilterValues[key].filter_type === 'number') {
        items.push({
          label: label,
          key: index,
          children: (
            <NumberRangeFilter
              range={ChoicesOrRange[key] as [number, number]} // Pass the numeric range
              field={key}
              currentFilterValues={currentFilterValues}
              setCurrentFilterValues={
                setCurrentFilterValues as Setter<
                  FilterValues | BackendFilterProps
                >
              }
              updateFilteredData={updateFilteredData}
            />
          ),
        });
      } else if (currentFilterValues[key].filter_type === 'checkbox') {
        items.push({
          label: label,
          key: index,
          children: (
            <CheckboxFilter
              choices={ChoicesOrRange[key]} // Pass choices for the checkbox filter
              field={key}
              isTranslatable={true}
              currentFilterValues={currentFilterValues}
              setCurrentFilterValues={
                setCurrentFilterValues as Setter<
                  FilterValues | BackendFilterProps
                >
              }
              updateFilteredData={updateFilteredData}
            />
          ),
        });
      } else if (currentFilterValues[key].filter_type === 'checkboxBoolean') {
        items.push({
          label: label,
          key: index,
          children: (
            <BooleanCheckboxFilter
              field={key}
              isTranslatable={true}
              currentFilterValues={currentFilterValues}
              setCurrentFilterValues={
                setCurrentFilterValues as Setter<
                  FilterValues | BackendFilterProps
                >
              }
              updateFilteredData={updateFilteredData}
              choices={ChoicesOrRange[key]}
            />
          ),
        });
      }
    });

    return items;
  };

  return (
    <Drawer
      open={filterOpen}
      placement="left"
      onClose={closeFilter}
      title={i18n.t('buttons:filter')}
      styles={{
        body: {
          padding: 0,
        },
      }}
    >
      <Button
        danger
        onClick={resetFilters}
        style={{
          width: '100%',
        }}
      >
        {i18n.t('buttons:reset_filters')}
      </Button>
      <Collapse items={filterItems()} bordered={false} />
    </Drawer>
  );
}
