import { message } from 'antd';
import i18n from 'i18next';

import { defaultPageSize } from '../../../components/table/TableGeneric';
import { routesNamedDict } from '../../../routes';
import { NavigateProp } from '../../types/Types';
import { api } from '../api/api';
import { unviewedImageResponse } from './ImageSearchTypes';

/**
 * Search lace fabrics with the image search
 */
export async function searchLaceImage(
  data: FormData,
  navigate: NavigateProp,
  isMobile: boolean,
  isNewModel: boolean
): Promise<void> {
  try {
    const response: unviewedImageResponse = await api({
      method: 'POST',
      url: 'v1/image_search/lace',
      body: data,
      params: {
        is_mobile: isMobile,
        is_new_model: isNewModel,
        fabrics_page: 1,
        fabrics_page_size: defaultPageSize,
      },
    });

    // Case when we have no results for image search
    if (response.result_fabrics.length === 0) {
      const errorBody = i18n.t(
        'long_messages:no_fabrics_found_part1_image_search'
      );
      message.error({
        content: (
          <span>
            {errorBody}
            <a href={routesNamedDict.FABRICS}>
              {i18n.t('long_messages:message_no_fabrics_found_part2')}
            </a>
          </span>
        ),
        duration: 6,
      });
      // Dont navigate to results
      return;
    }

    if (isMobile) {
      navigate(routesNamedDict.SEARCH_RESULTS_MOBILE);
    } else {
      navigate(
        routesNamedDict.SEARCH_IMAGE_RESULTS.replace(
          ':imageId',
          response.image_search_id
        ),
        {
          state: {
            closeModal: true,
            result_fabrics: response.result_fabrics,
            fabric_type: response.fabric_type,
            fabrics_count: response.fabrics_count,
            selectable_columns: response.selectable_columns,
            sortable_columns: response.sortable_columns,
            filterable_columns: response.filterable_columns,
          },
        }
      );
    }
  } catch (error) {
    message.error(i18n.t('error_messages:error_creating_image_search'), 6);
  }
}
