import { api } from '../api/api';

/**
 * Return email notification status for current user as either true or false.
 */

export type UserProps = {
  email: string;
  id: string;
  is_active: boolean;
  is_email_notified: boolean;
  is_2fa_enabled: boolean;
  account_type: string;
  measurement_unit: string;
  name: string;
};

export async function getCurrentUser(): Promise<UserProps> {
  return await api({
    method: 'GET',
    url: `v1/user/me`,
    showErrMsg: true,
  }).then((res: UserProps) => {
    return res;
  });
}
