import { Space } from 'antd';
import {
  CertificationDisplayedStyled,
  CertificationDownloadIcon,
} from '../../elements/certifications/StyledCertifications';
import { TextB2 } from '../../elements/typography/TextB2';
import { FabricCert } from '../../utils/backendRequests/certifications/CertificationTypes';
import { CertStatusRender } from './CertStatusRender';

type DisplayCertificationsProps = {
  certArray: FabricCert[];
  showDownload?: boolean | null;
  isTechnical?: boolean;
};

export const DisplayCertifications = ({
  certArray,
  showDownload = false,
  isTechnical = false,
}: DisplayCertificationsProps) => {
  const certDisplay = (cert: FabricCert) => {
    return (
      <CertificationDisplayedStyled key={cert.id} $isTechnical={isTechnical}>
        <TextB2>{cert.name}</TextB2>
        {showDownload && cert.status === 'verified' && cert.url && (
          <a href={cert.url} target="_blank" rel="noreferrer">
            <CertificationDownloadIcon />
          </a>
        )}
        <CertStatusRender status={cert.status} showNull={true} />
      </CertificationDisplayedStyled>
    );
  };

  return (
    <Space size={0} direction="vertical">
      {certArray.map(certDisplay)}
    </Space>
  );
};
