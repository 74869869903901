import { hangerRequestResp } from '../../../components/table/TableTypes';
import { api } from '../api/api';

/**
 * Gets all available hanger requests for current user
 */
export async function getHangerRequests(): Promise<hangerRequestResp[]> {
  const response = await api({
    method: 'GET',
    url: 'v1/hanger_request',
    showErrMsg: true,
  });
  return response;
}
