import { CheckOutlined } from '@ant-design/icons';
import { List, Space } from 'antd';
import i18n from 'i18next';
import { ButtonBasic } from '../../../elements/buttons/StyledButtons';
import { ScrollableListContainer } from '../../../elements/modals/StyledModal';
import { CollectionListItem } from '../../../utils/backendRequests/collection/CollectionTypes';

type CollectionListProps = {
  handleSave: (item: CollectionListItem) => void;
  collections: CollectionListItem[];
  containedFabricCollections: string[];
  srcCollectionId: string | null;
};

export function CollectionList({
  handleSave,
  collections,
  containedFabricCollections = [],
  srcCollectionId = null,
}: CollectionListProps) {
  return (
    <ScrollableListContainer>
      <List
        dataSource={collections}
        renderItem={(item) => {
          return (
            item.id !== srcCollectionId && (
              <List.Item
                actions={[
                  containedFabricCollections.includes(item.id) ? (
                    <Space>
                      <span>{i18n.t('headers:copy_existing_fabrics')}</span>
                      <CheckOutlined key="check" />
                    </Space>
                  ) : (
                    <ButtonBasic onClick={() => handleSave(item)}>
                      {i18n.t('buttons:add')}
                    </ButtonBasic>
                  ),
                ]}
              >
                <List.Item.Meta title={item.name} />
              </List.Item>
            )
          );
        }}
      />
    </ScrollableListContainer>
  );
}
