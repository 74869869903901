import { TransformedFilterProps } from '../../../components/filter/FilterTypes';
import { encryptStorage } from '../../../plugins/encryptStorage';
import {
  FabricTableReqProps,
  TableStateObj,
  unitType,
} from '../../types/Types';
import { api } from '../api/apiWithoutAuth';
import { CollectionShow } from '../collection/CollectionTypes';

/**
 * Gets all sharable links information generated from
 * the Shareable Links ID. Returns data corresponding to to
 * a collection the link belongs to. Also encrypt header token
 * on request and is stored in local storage to be used for
 * request since request is limited, if rejected a new token is returned
 * and updated existing return token.
 */
export async function getSharedCollection(
  id: string,
  tableState: TableStateObj,
  filterableColumns: TransformedFilterProps,
  returnViewerToken: string,
  measurementUnit: unitType
): Promise<CollectionShow> {
  const params: FabricTableReqProps = {
    fabrics_page: tableState.current,
    fabrics_page_size: tableState.pageSize,
    measurement_unit: measurementUnit,
  };
  // Add sort parameters only if both sortField and sortOrder have values
  if (tableState.sortField && tableState.sortOrder) {
    params.fabrics_sort_by = tableState.sortField;
    params.fabrics_sort_order = tableState.sortOrder;
  }
  if (filterableColumns) {
    params.fabrics_filter = JSON.stringify(filterableColumns);
  }
  const headers = {
    returning_viewer_token: returnViewerToken,
  };
  const res = await api({
    method: 'GET',
    url: `no_auth/v1/shared/collection/${id}`,
    params: params,
    headers: headers,
    showErrMsg: true,
  });
  const newToken = res.headers.returning_viewer_token;
  encryptStorage.setItem('returnViewerToken', newToken);
  return res.data;
}
