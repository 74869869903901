import { Button, Form, Row } from 'antd';
import { Dayjs } from 'dayjs';
import i18n from 'i18next';
import { useState } from 'react';

import {
  AdminCertReviewBodyProps,
  AdminCertReviewResponse,
} from '../../../utils/backendRequests/admin/AdminTypes';
import { verifyCertificationDetails } from '../../../utils/backendRequests/admin/verifyCertificationDetails';
import { Modal } from '../../Modal';
import { FormItems } from '../../form/FormItems';
import { SetPropsWithDropDownObj } from '../../form/FormTypes';
import { initCreateVerifyFormValues, verifyFields } from './verifyFields';

type AdminVerifyCertDetailsProp = {
  record: AdminCertReviewResponse;
  onSuccess: () => void;
};

type dropDownDataType = {
  certification_status: {
    value: number;
    label: string;
  }[];
};

type AdminCertFormValues = {
  is_name_verified: boolean;
  is_company_verified: boolean;
  expiry_date: Dayjs | null;
  certification_status: number;
};

export function AdminVerifyCertDetails({
  record,
  onSuccess,
}: AdminVerifyCertDetailsProp) {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [propsWithDropDownObj, setPropsWithDropDownObj] = useState<
    dropDownDataType | Record<string, never>
  >({});

  const handleCancel = () => {
    setVisible(false);
    form.setFieldsValue(initCreateVerifyFormValues);
  };

  const handleSubmit = async (values: AdminCertFormValues) => {
    const payload: AdminCertReviewBodyProps = {
      is_name_verified: values.is_name_verified,
      is_company_verified: values.is_company_verified,
      status: values.certification_status,
      expiry_date: values.expiry_date?.format('YYYY-MM-DD') || null,
    };

    const res = await verifyCertificationDetails(
      record.certification_details_id,
      payload
    );
    if (res) {
      onSuccess();
      handleCancel();
    }
  };

  return (
    <>
      <a role="button" onClick={() => setVisible(true)}>
        Update
      </a>
      <Modal
        title={`Verify "${record.certification_name}" for "${record.supplier_brand_name}"`}
        open={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <Row>
          Download file:
          <a href={record.url} target="_blank" rel="noopener noreferrer">
            Download
          </a>
        </Row>
        <Row>Details to submit:</Row>
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={initCreateVerifyFormValues}
        >
          <FormItems
            formFields={verifyFields}
            propsWithDropDownObj={propsWithDropDownObj}
            setPropsWithDropDownObj={
              setPropsWithDropDownObj as SetPropsWithDropDownObj
            }
          />
          <Form.Item wrapperCol={{ offset: 20 }}>
            <Button type="primary" htmlType="submit">
              {i18n.t('buttons:submit')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
