import { CreateHangerMessage } from '../../../components/table/hangersList/HangerTypes';
import { api } from '../api/api';

/**
 * Creates or updates a hanger requests optional chat
 */

export type CreateMessageResp = {
  user: {
    name: string;
    brand_name: string;
  };
  text: string;
  created_at: string;
  chat_id: string;
  message_id: string;
}[];

export const createChat = async (
  newChatData: CreateHangerMessage
): Promise<CreateMessageResp> => {
  const res = await api({
    method: 'POST',
    url: 'v1/chat/message',
    showErrMsg: true,
    body: newChatData,
  });
  return res as CreateMessageResp;
};
