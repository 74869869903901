import i18n from 'i18next';
import { useState } from 'react';

import { CopyToCollectionModal } from '../../modal/collection/CopyToCollectionModal';
import { CopyCollectionLinkProps } from '../TableTypes';

export function CopyCollectionToCollectionLink({
  collectionId,
  getCollectionsList,
}: CopyCollectionLinkProps) {
  const [collectionModal, setCollectionModal] = useState({ visible: false });

  return (
    <>
      <a
        onClick={() =>
          setCollectionModal({
            visible: true,
          })
        }
        role="button"
      >
        {i18n.t('buttons:copy_to_collection')}
      </a>
      <CopyToCollectionModal
        modal={collectionModal}
        setModal={setCollectionModal}
        collectionId={collectionId}
        getCollectionsList={getCollectionsList}
      />
    </>
  );
}
