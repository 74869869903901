import { api } from '../api/api';
import { UserProps } from './getCurrentUser';

/**
 * Return all users for user management page
 */

export async function getAllUsers(): Promise<UserProps[]> {
  return await api({
    method: 'GET',
    url: `v1/user`,
    showErrMsg: true,
  }).then((res: UserProps[]) => {
    return res;
  });
}
