import { UserOutlined } from '@ant-design/icons';
import { Space } from 'antd';

import { FontWeight } from '../../../elements/base/Font';
import { ApprovedSupplierBrandsProps } from '../TableTypes';

export function ApprovedSupplierBrands({
  approvedSupplierBrands,
}: ApprovedSupplierBrandsProps) {
  return (
    <Space direction="vertical" size="small">
      <p style={{ margin: '10px' }}>Approved Brands</p>
      {approvedSupplierBrands.map((brand, index) => (
        <Space direction="vertical" size="small" key={index}>
          <Space style={{ height: '2rem' }}>
            <UserOutlined style={{ fontSize: '22px' }} />
            <p style={{ fontWeight: FontWeight.Regular }}>{brand.value}</p>
          </Space>
        </Space>
      ))}
    </Space>
  );
}
