import { EditFabricPage } from '../pages/fabric/EditFabricPage';
import { FabricDetailsPage } from '../pages/fabric/FabricDetailsPage';
import { ProjectDetailsPage } from '../pages/project/ProjectDetailsPage';
import { ProjectListPage } from '../pages/project/ProjectListPage';
import { RouteProps } from './RouteTypes';

// TODO: Children of children does not work due to how the routes are being read at the moment
export const routesProjects: RouteProps[] = [
  {
    path: '/projects',
    title: 'Projects',
    key: 'projects_page',
    page: ProjectListPage,
    children: [
      {
        path: '/:projectId',
        title: 'Project Id',
        page: ProjectDetailsPage,
      },
      {
        path: '/:projectName/:fabricId',
        title: 'Fabric Details',
        page: FabricDetailsPage,
      },
      {
        path: '/:projectName/:fabricId/edit',
        title: 'Fabric Id',
        page: EditFabricPage,
      },
    ],
  },
];
