import { DownOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, Row, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { Color } from '../../../elements/base/Color';
import { AddToCollectionModal } from '../../modal/collection/AddToCollectionModal';
import { AddToProjectModal } from '../../modal/project/AddToProjectModal';

import { HeaderTitle } from '../../../components/title/HeaderTitle';
import { ButtonBasic } from '../../../elements/buttons/StyledButtons';
import {
  BasicFieldsBoldText,
  BasicFieldsTextContainer,
} from '../../../elements/fabrics/fabricStyledElement';
import { HelpIcon } from '../../../elements/icons/StyledIcons';
import { routesNamedDict } from '../../../routes';
import { deleteToBinFabric } from '../../../utils/backendRequests/fabric/deleteToBinFabric';
import { priceParser } from '../../../utils/fabrics/parseFabric';
import {
  fabricPropertyArray,
  parseFabricTitles,
  returnGroupedProperties,
} from '../../../utils/fabrics/parseFabricFields';
import { getTranslatedValuesAndUpdateData } from '../../../utils/fabrics/parseFabricValues';
import { parseLocalDateTime } from '../../../utils/parsers/parseLocalDateTime';
import {
  compositionsProp,
  parseTranslatedCompositions,
} from '../../../utils/parsers/parseTranslatedCompositions';
import { record, RootAuth } from '../../../utils/types/Types';
import { DisplayImages } from '../../DisplayImages';
import { Modal } from '../../Modal';
import { CreateFabricsHangerRequest } from '../../modal/hangers/CreateFabricsHangerRequest';
import { intialDataProp } from '../../search/SearchTypes';
import { BasicInfoComponentProps, fabricRecordProps } from '../FabricTypes';
import { Fabric3DModel } from './Fabric3DModel';
import { FabricAttatchmentsTab } from './FabricAttachmentsTab';
import { FabricRecommendationTab } from './FabricRecommendationTab';
import { FabricTechnicalTab, technicalFields } from './FabricTechnicalTab';

export function FabricDetailsMobile({ fabric }: fabricRecordProps) {
  const [images, setImages] = useState<string[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [projectModal, setProjectModal] = useState({ visible: false });
  const [collectionModal, setNewCollectionModal] = useState({ visible: false });
  const [isHangerRequestModalVisible, setIsHangerRequestModalVisible] =
    useState(false);
  const [attachmentsExist, setAttachmentsExist] = useState<boolean>(false);
  const [hideImages, setHideImages] = useState<boolean>(false);
  const [translatedData, setTranslatedData] = useState<record>(fabric);
  const navigate = useNavigate();
  const { measurementUnit, role } = useSelector(
    (state: RootAuth) => state.auth
  );
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  const [activeTab, setActiveTab] = useState<string>('recommendation');
  const [hasAdditional, setHasAdditional] = useState<boolean>(false);

  // Set translated data
  useEffect(() => {
    const translationData = getTranslatedValuesAndUpdateData(
      [fabric],
      fabricPropertyArray
    ) as intialDataProp;
    setTranslatedData(translationData[0]);
  }, [fabric]);

  // Call the function and then filter out any 'image' fields
  const basicFabricFields = returnGroupedProperties('basic').filter(
    (property) =>
      ![
        'image_url_front',
        'image_url_back',
        'image_url_header',
        'image_url_macro',
      ].includes(property.name) // Filter based on 'name' property
  );

  useEffect(() => {
    if (Object.keys(fabric).length !== 0) {
      const newImages = [
        fabric['image_url_macro'] as string,
        fabric['image_url_front'] as string,
        fabric['image_url_back'] as string,
        fabric['image_url_header'] as string,
      ].filter(Boolean); // Remove null or undefined values
      setImages(newImages);

      setAttachmentsExist(
        Array.isArray(fabric.attachments) && fabric.attachments.length > 0
      );

      const allImagesNull = newImages.length === 0;
      setHideImages(allImagesNull);

      // Determine if any relevant field has a non-empty value
      const anyValuePresent =
        !!fabric.ml_description ||
        !!fabric.description ||
        !!fabric.notes ||
        technicalFields.some((field) => {
          const fieldValue = fabric[field.name as keyof typeof fabric];
          // Check if the field value is an array and ensure it's not empty
          if (Array.isArray(fieldValue)) {
            return fieldValue.length > 0; // Return true if the array is not empty
          }
          return fieldValue != null && fieldValue !== ''; // Ensure the field is not null or empty
        });
      setHasAdditional(anyValuePresent);
    }
  }, [fabric]);

  const BasicInfoComponent = ({
    fieldName,
    title,
    value,
    fieldType,
  }: BasicInfoComponentProps) => {
    const hasValue = value !== null && value !== '' && value !== undefined;
    if (fieldType === 'date' && hasValue && value !== null) {
      value = parseLocalDateTime(value);
    } else if (typeof value === 'number' && fieldName.startsWith('price')) {
      value = priceParser(fabric.currency as string, value);
    } else if (fieldName === 'compositions') {
      value = parseTranslatedCompositions(
        value as unknown as compositionsProp[],
        true
      ) as string;
    }

    const widthMetric = i18n.t(
      'long_messages:message_fabric_width_tolerance_metric'
    );

    const widthImperial = i18n.t(
      'long_messages:message_fabric_width_tolerance_imperial'
    );

    const widthMessage =
      measurementUnit === 'metric' ? widthMetric : widthImperial;
    const weightMessage = i18n.t(
      'long_messages:message_fabric_weight_tolerance'
    );

    const tooltipText =
      (fieldName === 'width_cm' && widthMessage) ||
      (fieldName === 'weight_grams_per_sqm' && weightMessage);

    return (
      <div>
        {tooltipText !== false ? (
          <BasicFieldsTextContainer>
            <BasicFieldsBoldText>
              {title}
              <HelpIcon
                title={tooltipText}
                fontSize="12px"
                margin="0 5px 0 3px"
              />
              :
            </BasicFieldsBoldText>
            <span style={{ wordWrap: 'break-word' }}>
              {hasValue ? value : '-'}
            </span>
          </BasicFieldsTextContainer>
        ) : (
          <BasicFieldsTextContainer>
            <BasicFieldsBoldText>{title}:</BasicFieldsBoldText>
            <span> {hasValue ? value : '-'}</span>
          </BasicFieldsTextContainer>
        )}
      </div>
    );
  };
  const { libraryId } = useParams();

  const editFabricNavigate = () => {
    navigate(
      routesNamedDict.FABRICS_FABRIC_EDIT.replace(
        ':libraryId',
        libraryId as string
      ).replace(':fabricId', fabric.id)
    );
  };

  // Filter out null fields and map them to BasicInfoComponent
  const basicFieldsWithValues = basicFabricFields
    .filter(
      (field) =>
        fabric[field.name as keyof typeof fabric] !== null &&
        fabric[field.name as keyof typeof fabric] !== '' &&
        fabric[field.name as keyof typeof fabric] !== undefined
    )
    .filter((field) => {
      const value = fabric[field.name as keyof typeof fabric];
      return Array.isArray(value) ? value.length !== 0 : true;
    }); // Filter out empty arrays

  // Calculate the length of each column
  const halfLength = Math.ceil(basicFieldsWithValues.length / 2);

  // Divide fields into two columns
  const firstColumnFields = basicFieldsWithValues.slice(0, halfLength);
  const secondColumnFields = basicFieldsWithValues.slice(halfLength);

  // Map the fields to BasicInfoComponent for each column
  const basicFieldsPropFirstColumn = firstColumnFields.map((field, index) => (
    <BasicInfoComponent
      key={index}
      fieldName={field.name}
      title={parseFabricTitles(field.name, measurementUnit)}
      value={fabric[field.name as keyof typeof fabric] as string}
      fieldType={field.fieldType}
    />
  ));

  const basicFieldsPropSecondColumn = secondColumnFields.map((field, index) => (
    <BasicInfoComponent
      key={index}
      fieldName={field.name}
      title={parseFabricTitles(field.name, measurementUnit)}
      value={fabric[field.name as keyof typeof fabric] as string}
      fieldType={field.fieldType}
    />
  ));

  // Function to handle opening and closing of the hanger request modal
  const toggleHangerRequestModal = () => {
    setIsHangerRequestModalVisible(!isHangerRequestModalVisible);
  };

  const handleConfirm = async () => {
    const data = { ids: [fabric.id] };
    deleteToBinFabric(data, navigate);
    setModalVisible(false); // Close the modal after deletion
  };

  const handleMenuClick = (e: { key: string }) => {
    setActiveTab(e.key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="recommendation">
        {i18n.t('headers:recommendation')}
      </Menu.Item>
      <Menu.Item key="3d_model" disabled={hideImages}>
        {i18n.t('headers:3d_model')}
      </Menu.Item>
      <Menu.Item key="additional_info" disabled={!hasAdditional}>
        {i18n.t('headers:additional_info')}
      </Menu.Item>
      <Menu.Item key="attachments" disabled={!attachmentsExist}>
        {i18n.t('headers:attachments')}
      </Menu.Item>
    </Menu>
  );

  const getContent = () => {
    switch (activeTab) {
      case 'recommendation':
        return <FabricRecommendationTab isMobile={true} fabric={fabric} />;
      case '3d_model':
        return <Fabric3DModel isMobile={true} />;
      case 'additional_info':
        return <FabricTechnicalTab isMobile={true} fabric={translatedData} />;
      case 'attachments':
        return <FabricAttatchmentsTab fabric={fabric} />;
      default:
        return null;
    }
  };

  return (
    <>
      {fabric.name && (
        <HeaderTitle
          title={`${i18n.t('page_titles:fabric_details_page')} "${
            fabric.name
          }"`}
          isCenter={true}
        />
      )}
      {role === 'designer' && (
        <AddToProjectModal
          modal={projectModal}
          setModal={setProjectModal}
          fabricIds={[fabric.id]}
        />
      )}
      <AddToCollectionModal
        modal={collectionModal}
        setModal={setNewCollectionModal}
        fabricIds={[fabric.id]}
      />
      <CreateFabricsHangerRequest
        visible={isHangerRequestModalVisible}
        onCancel={toggleHangerRequestModal}
        fabricIds={[fabric.id]}
      />
      <Modal
        title={i18n.t('headers:confirm_deletion')}
        open={modalVisible}
        onOk={handleConfirm}
        onCancel={() => setModalVisible(false)}
        okText={i18n.t('buttons:yes')}
        cancelText={i18n.t('buttons:no')}
        okButtonProps={{
          style: { backgroundColor: Color.Red },
        }}
      >
        <p>{i18n.t('long_messages:message_confirm_fabrics_deletion')}</p>
      </Modal>
      {!hideImages && (
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <DisplayImages ImageArray={images} isMobile={true} />
        </Row>
      )}
      <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
        <Col span={12}>{basicFieldsPropFirstColumn}</Col>
        <Col span={12}>{basicFieldsPropSecondColumn}</Col>
      </Row>
      <Row style={{ marginTop: '30px' }}>
        <Space>
          {role === 'designer' && (
            <ButtonBasic onClick={() => setProjectModal({ visible: true })}>
              {i18n.t('headers:add_to_project')}
            </ButtonBasic>
          )}
          {role === 'supplier' && account !== 'viewer' && (
            <ButtonBasic
              onClick={() => setNewCollectionModal({ visible: true })}
            >
              {i18n.t('headers:add_to_collection')}
            </ButtonBasic>
          )}
          {role === 'supplier' && account !== 'viewer' && (
            <ButtonBasic onClick={editFabricNavigate}>
              {i18n.t('buttons:edit')}
            </ButtonBasic>
          )}
          {role === 'designer' && account !== 'viewer' && (
            <ButtonBasic onClick={toggleHangerRequestModal}>
              {i18n.t('buttons:request_hanger')}
            </ButtonBasic>
          )}
          {role === 'supplier' && account !== 'viewer' && (
            <ButtonBasic danger onClick={() => setModalVisible(true)}>
              {i18n.t('buttons:delete')}
            </ButtonBasic>
          )}
        </Space>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
          <Button>
            {i18n.t(`headers:${activeTab}`)} <DownOutlined />
          </Button>
        </Dropdown>
        <Col style={{ marginTop: '20px' }} flex="auto">
          {getContent()}
        </Col>
      </Row>
    </>
  );
}
