import { message } from 'antd';
import i18n from 'i18next';

import { api } from '../api/api';
/**
 * Move fabrics in a library, specified by name. Adds new Fabrics to
 * an existing Fabric Library
 */
export async function moveFabrics(
  libraryId: string,
  fabricIds: string[] | null = null
): Promise<boolean> {
  const data = {
    new_library_id: libraryId,
    fabric_ids: fabricIds,
  };
  try {
    await api({
      method: 'PUT',
      url: `v1/fabric/update_library`,
      showErrMsg: true,
      body: data,
    });
    message.success(
      i18n.t('long_messages:message_fabric_moved_success', {
        count: fabricIds?.length,
      })
    );
    return true;
  } catch (error) {
    return false;
  }
}
