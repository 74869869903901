import { Checkbox, DatePicker, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs, { Dayjs } from 'dayjs';
import i18n from 'i18next';

import {
  BackendFilterProps,
  DateRangeFilterProps,
  FilterValues,
} from '../FilterTypes';

const { RangePicker } = DatePicker;

export function DateRangeFilter({
  range,
  field,
  currentFilterValues,
  setCurrentFilterValues,
  updateFilteredData,
}: DateRangeFilterProps) {
  // Convert the currentFilterValues[field].values to Dayjs objects if they are strings
  const currentFilterValuesAsDayjs: [Dayjs | null, Dayjs | null] | null =
    currentFilterValues[field].values
      ? [
          typeof currentFilterValues[field].values[0] === 'string'
            ? dayjs(currentFilterValues[field].values[0], 'YYYY-MM-DD')
            : currentFilterValues[field].values[0],
          typeof currentFilterValues[field].values[1] === 'string'
            ? dayjs(currentFilterValues[field].values[1], 'YYYY-MM-DD')
            : currentFilterValues[field].values[1],
        ]
      : null;

  /**
   * Any time the date range is changed, this function is called.
   * The new filter values are set to the currentFilterValues state
   * and the updateFilteredData function is called to update the data
   * which gets filtered.
   */
  const _onApplyFilter = (dates: [Dayjs | null, Dayjs | null] | null) => {
    const newFilterValues = {
      ...currentFilterValues,
      [field]: {
        ...currentFilterValues[field],
        values: dates,
      },
    };
    setCurrentFilterValues(
      newFilterValues as FilterValues | BackendFilterProps
    );
    if (updateFilteredData) updateFilteredData(newFilterValues as FilterValues);
  };

  const onCheckboxUpdate = (event: CheckboxChangeEvent) => {
    const newFilterValues = {
      ...currentFilterValues,
      [field]: {
        ...currentFilterValues[field],
        has_null: event.target.checked,
      },
    };
    setCurrentFilterValues(newFilterValues);
    if (updateFilteredData) updateFilteredData(newFilterValues as FilterValues);
  };

  // Only show the filter if the range array has dates to work with
  return range.length === 0 ? (
    <div>{i18n.t('long_messages:null_data_filter')}</div>
  ) : (
    <>
      <Row style={{ padding: '10px 0px 10px 0px' }}>
        <RangePicker
          value={currentFilterValuesAsDayjs}
          onChange={_onApplyFilter}
          allowClear={false}
        />
      </Row>
      {!currentFilterValues[field].required && (
        <Row>
          <Checkbox
            checked={currentFilterValues[field].has_null}
            onChange={onCheckboxUpdate}
          >
            {i18n.t('long_messages:show_null_rows')}
          </Checkbox>
        </Row>
      )}
    </>
  );
}
