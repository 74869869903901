import { Checkbox, Col, InputNumber, Row, Slider } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import i18n from 'i18next';

import {
  BackendFilterProps,
  FilterValues,
  NumberRangeFilterProps,
} from '../FilterTypes';

export function NumberRangeFilter({
  range,
  field,
  currentFilterValues,
  setCurrentFilterValues,
  updateFilteredData,
}: NumberRangeFilterProps) {
  // Helper functions to round values to 2 decimal places
  const roundTo2DP = (value: number) => {
    return Math.round(value * 100) / 100;
  };

  // Specifically for min and max values when rouding down for min
  // and rounding up for max values
  const roundMinMax2DP = (value: number, roundDown: boolean) => {
    if (roundDown) {
      return Math.floor(value * 100) / 100; // Round down
    } else {
      return Math.ceil(value * 100) / 100; // Round up
    }
  };

  /**
   * Any time a number value is changed or after the slider has been let go
   * after applying changes, this function is called.
   */
  const _onApplyFilter = (maxMinValues: number[]) => {
    const roundedValues = maxMinValues.map((val) => roundTo2DP(val));
    const newFilterValues: FilterValues | BackendFilterProps = {
      ...currentFilterValues,
      [field]: {
        ...currentFilterValues[field],
        values: roundedValues,
      },
    };
    setCurrentFilterValues(newFilterValues);
    if (updateFilteredData) updateFilteredData(newFilterValues as FilterValues);
  };

  /**
   * Called any time a change to the slider occurs to update the
   * filter values.
   */
  const _onNumberUpdate = (maxMinValues: number[]) => {
    const roundedValues = maxMinValues.map((val) => roundTo2DP(val));
    const newFilterValues = {
      ...currentFilterValues,
      [field]: {
        ...currentFilterValues[field],
        values: roundedValues,
      },
    };
    setCurrentFilterValues(newFilterValues);
  };

  const onCheckboxUpdate = (event: CheckboxChangeEvent) => {
    const newFilterValues = {
      ...currentFilterValues,
      [field]: {
        ...currentFilterValues[field],
        has_null: event.target.checked,
      },
    };
    setCurrentFilterValues(newFilterValues);
    if (updateFilteredData) updateFilteredData(newFilterValues as FilterValues);
  };

  return range.length !== 2 ? (
    <div>{i18n.t('long_messages:null_data_filter')}</div>
  ) : (
    <div style={{ padding: '10px' }}>
      <Slider
        max={roundMinMax2DP(range[1], false)}
        min={roundMinMax2DP(range[0], true)}
        onChange={_onNumberUpdate}
        onChangeComplete={_onApplyFilter}
        range={{ draggableTrack: true }}
        value={[
          roundMinMax2DP(Number(currentFilterValues[field].values[0]), true),
          roundMinMax2DP(Number(currentFilterValues[field].values[1]), false),
        ]}
      />
      <Row justify="space-between">
        <Col style={{ width: '50%', paddingRight: '5px' }}>
          <p>{i18n.t('buttons:min')}</p>
          <InputNumber
            min={roundMinMax2DP(range[0], true)}
            max={roundMinMax2DP(
              Number(currentFilterValues[field].values[1]),
              false
            )}
            onChange={(minNumber: number | null) => {
              if (minNumber !== null) {
                _onApplyFilter([
                  Number(minNumber),
                  Number(currentFilterValues[field].values[1]),
                ]);
              }
            }}
            value={roundTo2DP(Number(currentFilterValues[field].values[0]))}
          />
        </Col>
        <Col style={{ width: '50%', paddingLeft: '5px' }}>
          <Row justify="end">
            <p>{i18n.t('buttons:max')}</p>
          </Row>
          <Row justify="end">
            <InputNumber
              min={roundMinMax2DP(
                Number(currentFilterValues[field].values[0]),
                true
              )}
              max={roundMinMax2DP(range[1], false)}
              onChange={(maxNumber) => {
                if (maxNumber !== null) {
                  _onApplyFilter([
                    Number(currentFilterValues[field].values[0]),
                    Number(maxNumber),
                  ]);
                }
              }}
              value={roundTo2DP(Number(currentFilterValues[field].values[1]))}
            />
          </Row>
        </Col>
      </Row>
      {!currentFilterValues[field].required && (
        <Row style={{ paddingTop: '10px' }}>
          <Checkbox
            checked={currentFilterValues[field].has_null}
            onChange={onCheckboxUpdate}
          >
            {i18n.t('long_messages:show_null_rows')}
          </Checkbox>
        </Row>
      )}
    </div>
  );
}
