import { TextB1 } from '../../elements/typography/TextB1';
import { TitleH2 } from '../../elements/typography/TitleH2';

interface HeaderTitleProps {
  title: string;
  subtitle?: string | React.ReactNode;
  isCenter?: boolean;
}

export const HeaderTitle = ({
  title,
  subtitle,
  isCenter = false,
}: HeaderTitleProps) => {
  const titleStyle: React.CSSProperties = {};
  if (isCenter) {
    titleStyle.display = 'flex';
    titleStyle.justifyContent = 'center';
    titleStyle.textAlign = 'center';
  }

  const containerStyle: React.CSSProperties = {};
  if (subtitle) {
    containerStyle.paddingBottom = '20px';
  } else {
    containerStyle.paddingBottom = '0px';
  }

  return (
    <div style={containerStyle}>
      <TitleH2 style={titleStyle}>{title}</TitleH2>
      {subtitle && (
        <TextB1 style={titleStyle}>
          {subtitle} {/* Render string or JSX */}
        </TextB1>
      )}
    </div>
  );
};
