import { useNavigate } from 'react-router-dom';

import { TabTitle } from '../../components/TabTitle';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { ButtonBasic } from '../../elements/buttons/StyledButtons';
import { SubTitleH6 } from '../../elements/typography/SubTitleH6';
import { TextB2 } from '../../elements/typography/TextB2';
import { AdminLayout } from '../../layouts/Admin';
import i18n from '../../plugins/i18n';
import { routesNamedDict } from '../../routes';
import { SectionWrapper } from '../../styles/wrappers/SectionWrapper';
import { TitleProp } from '../PagesTypes';

export function AdminHomePage({ title }: TitleProp) {
  const navigate = useNavigate();

  const handleViewAllFabrics = () => {
    navigate(routesNamedDict.ADMIN_TRAINING_LABELS);
  };

  const handleViewAllDesigners = () => {
    navigate(routesNamedDict.ADMIN_DESIGNERS);
  };

  const handleViewAllDesignerBrands = () => {
    navigate(routesNamedDict.ADMIN_DESIGNER_BRANDS);
  };

  const handleViewAllSuppliers = () => {
    navigate(routesNamedDict.ADMIN_SUPPLIERS);
  };

  const handleViewAllSupplierBrands = () => {
    navigate(routesNamedDict.ADMIN_SUPPLIER_BRANDS);
  };

  const handleViewSharedLinksList = () => {
    navigate(routesNamedDict.ADMIN_SHARED_LINKS);
  };

  const handleViewCertReviewList = () => {
    navigate(routesNamedDict.ADMIN_CERTIFICATIONS_REVIEW);
  };

  const handleBulkImageUpload = () => {
    navigate(routesNamedDict.ADMIN_IMAGE_BULK_UPLOAD);
  };

  const handleBulkImageCropping = () => {
    navigate(routesNamedDict.ADMIN_IMAGE_BULK_CROPPER);
  };

  return (
    <AdminLayout>
      <HeaderTitle title={i18n.t('page_titles:admin_home_page')} />
      <TabTitle title={title} />

      <SectionWrapper>
        <SubTitleH6>View Fabrics' Training Labels</SubTitleH6>
        <TextB2>
          Here you can view all fabrics' training labels and update them.
        </TextB2>
        <ButtonBasic onClick={handleViewAllFabrics}>View Labels</ButtonBasic>
      </SectionWrapper>

      <SectionWrapper>
        <SubTitleH6>View All Designer Brands </SubTitleH6>
        <TextB2>Here you can view all the designer brands.</TextB2>
        <ButtonBasic onClick={handleViewAllDesignerBrands}>
          View Designer Brands
        </ButtonBasic>
      </SectionWrapper>

      <SectionWrapper>
        <SubTitleH6>View All Supplier Brands </SubTitleH6>
        <TextB2>Here you can view all the supplier brands.</TextB2>
        <ButtonBasic onClick={handleViewAllSupplierBrands}>
          View Supplier Brands
        </ButtonBasic>
      </SectionWrapper>

      <SectionWrapper>
        <SubTitleH6>View All Designers </SubTitleH6>
        <TextB2>Here you can view all the designers.</TextB2>
        <ButtonBasic onClick={handleViewAllDesigners}>
          View Designers
        </ButtonBasic>
      </SectionWrapper>

      <SectionWrapper>
        <SubTitleH6>View All Suppliers </SubTitleH6>
        <TextB2>Here you can view all the suppliers.</TextB2>
        <ButtonBasic onClick={handleViewAllSuppliers}>
          View Suppliers
        </ButtonBasic>
      </SectionWrapper>

      <SectionWrapper>
        <SubTitleH6>View Shared Links</SubTitleH6>
        <TextB2>
          Here you can view all fabric collections shared outside the platform
          via email.
        </TextB2>
        <ButtonBasic onClick={handleViewSharedLinksList}>
          View Shared Links
        </ButtonBasic>
      </SectionWrapper>

      <SectionWrapper>
        <SubTitleH6>View All Certifications to Review </SubTitleH6>
        <TextB2>
          Here you can view all the Certifications that need to be reviewed.
        </TextB2>
        <ButtonBasic onClick={handleViewCertReviewList}>
          View Certifications
        </ButtonBasic>
      </SectionWrapper>

      <SectionWrapper>
        <SubTitleH6>Image Bulk Upload </SubTitleH6>
        <TextB2>Here you can bulk upload images for fabrics.</TextB2>
        <ButtonBasic onClick={handleBulkImageUpload}>
          Bulk Upload Images
        </ButtonBasic>
      </SectionWrapper>

      <SectionWrapper>
        <SubTitleH6>Image Bulk Cropping </SubTitleH6>
        <TextB2>Here you can bulk crop images.</TextB2>
        <ButtonBasic onClick={handleBulkImageCropping}>
          Bulk Crop Images
        </ButtonBasic>
      </SectionWrapper>
    </AdminLayout>
  );
}
