import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloudDownloadOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import { Space } from 'antd';
import styled from 'styled-components';

export const CertificationDownloadIcon = styled(CloudDownloadOutlined)`
  font-size: 22px;
`;

export const CertificationVerifiedStatusIcon = styled(CheckCircleOutlined)`
  font-size: 22px;
  color: green;
`;

export const CertificationInvalidStatusIcon = styled(CloseCircleOutlined)`
  font-size: 22px;
  color: red;
`;

export const CertificationInProgressStatusIcon = styled(ClockCircleOutlined)`
  font-size: 22px;
  color: orange;
`;

export const CertificationDisplayedStyled = styled(Space)<{
  $isTechnical: boolean;
}>`
  margin-bottom: ${(props) => (props.$isTechnical ? '-12px' : '0px')};
  margin-top: ${(props) => (props.$isTechnical ? '-6px' : '0px')};
  max-width: ${(props) => (props.$isTechnical ? '230px' : '100%')};
`;

export const HangersVerifiedStatusIcon = styled(CheckCircleOutlined)`
  font-size: 22px;
  color: green;
`;

export const HangersInvalidStatusIcon = styled(CloseCircleOutlined)`
  font-size: 22px;
  color: red;
`;

export const HangersInProgressStatusIcon = styled(ClockCircleOutlined)`
  font-size: 22px;
  color: orange;
`;

export const HangersSentStatusIcon = styled(RightCircleOutlined)`
  font-size: 22px;
  color: grey;
`;
