import { Row } from 'antd';
import styled from 'styled-components';

export const FormWrapper = styled.div`
  margin-top: 30px;
  @media (min-width: 450px) {
    width: 420px;
  }
`;

export const MobileFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  width: 100%;
  max-width: 420px;
  padding: 0 15px;

  // Ensure form items are stacked properly
  .ant-form-item {
    width: 100%;
    margin-bottom: 20px; /* Space between form items */
  }

  .ant-form-item-label {
    text-align: left; /* Align labels to the left */
  }

  .ant-form-item-control-input {
    text-align: left; /* Align inputs to the left */
  }

  .ant-form-item:last-of-type {
    margin-bottom: 40px;
  }
`;

export const CenteredUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  > * {
    margin-bottom: 20px;
  }
  > *:last-child {
    margin-bottom: 30px;
  }
`;

export const FormMobileCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileCheckboxContentWrapper = styled.div`
  margin-top: -10px;
`;

export const MobileCheckboxRow = styled(Row)`
  margin-top: 10px;
`;
