import { message } from 'antd';
import i18n from 'i18next';
import { MsgProp } from '../../types/Types';
import { api } from '../api/api';

/**
 * Unshares a project, specified by name.
 */
export async function unshareProject(projectId: string): Promise<MsgProp> {
  return await api({
    method: 'PUT',
    url: `v1/project/${projectId}/unshare`,
    showErrMsg: true,
  }).then((res: MsgProp) => {
    message.success(i18n.t('long_messages:message_project_unshared_success'));
    return res;
  });
}
