import { FabricResponse } from './ProjectTypes';

import { message } from 'antd';
import i18n from 'i18next';
import { api } from '../api/api';

export const copyCollectionFabricsToProject = async (
  projectId: string,
  collectionId: string
): Promise<FabricResponse> => {
  const data = { project_id: projectId, collection_id: collectionId };
  return await api({
    method: 'POST',
    url: `v1/project/copy_from_collection`,
    showErrMsg: true,
    body: data,
  }).then((res: FabricResponse) => {
    message.success(i18n.t('long_messages:message_project_add_fabric_success'));
    return res;
  });
};
