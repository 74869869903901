import { message } from 'antd';
import { FabricBinBody } from '../../../components/bin/BinTypes';
import { MsgProp } from '../../types/Types';
import { api } from '../api/api';

/**
 * Backend request to restore selected fabrics from the bin.
 * If successful return true and display a success message.
 * If an error occurs, return false and display the error message.
 */
export const restoreFabric = async (body: FabricBinBody): Promise<boolean> => {
  let successFlag = false;
  try {
    const response: MsgProp = await api({
      method: 'PUT',
      url: 'v1/fabric/restore_from_bin',
      body: body,
      showErrMsg: true,
    });
    successFlag = true;
    message.success(response.msg);
  } catch (error) {
    successFlag = false;
  }
  return successFlag;
};
