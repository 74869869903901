import { api } from '../api/api';
import { SupplierType } from './supplierTypes';
/**
 * Makes an API call for suppliers connected to the current
 * designer user.
 */
export async function getSuppliers(): Promise<SupplierType[]> {
  let results = [];
  const response = await api({
    method: 'GET',
    url: 'v1/brand/supplier',
    showErrMsg: true,
  });
  results = response;
  return results;
}
