import { List, Space } from 'antd';

import { CertificationDownloadIcon } from '../../../elements/certifications/StyledCertifications';
import { FabricTabAttachmentProps } from '../FabricTypes';

export type Attachment = {
  id: string;
  url: string;
  name: string;
};

/**
 * A tab component to display downloadable attachments of one fabric.
 */
export function FabricAttatchmentsTab({ fabric }: FabricTabAttachmentProps) {
  return (
    <>
      <List<Attachment>
        dataSource={fabric.attachments as unknown as Attachment[]}
        renderItem={(attachment) => (
          <List.Item style={{ border: 'none' }}>
            <Space>
              {attachment.name}

              <a
                href={attachment.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CertificationDownloadIcon />
              </a>
            </Space>
          </List.Item>
        )}
      />
    </>
  );
}
