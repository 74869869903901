import { api } from '../api/api';
import {
  containedFabricProjectsProps,
  ProjectsContainingFabrics,
} from './ProjectTypes';

/**
 * Retrieves project names containing the specified fabrics
 * by their id.
 */
export async function getContainingFabricsProjects({
  fabricIds,
  fabricSourceProjectId,
}: ProjectsContainingFabrics): Promise<containedFabricProjectsProps> {
  try {
    let queryString = '';
    // Construct the query string manually
    if (fabricIds !== undefined) {
      queryString = fabricIds.map((id) => `fabric_ids=${id}`).join('&');
    } else if (fabricSourceProjectId !== undefined) {
      queryString = `fabric_source_project_id=${fabricSourceProjectId}`;
    }
    const url = `v1/project/projects_containing_fabrics?${queryString}`;
    return await api({
      method: 'GET',
      url: url,
    });
  } catch (error) {
    const emptyReturn = {
      project_ids: [],
    };
    return emptyReturn;
  }
}
