import { Image } from 'antd';
import styled from 'styled-components';

// Display images of fabrics Details
interface StyledDetailsImageProps {
  isMobile: boolean;
  src: string;
  $itemKey: number;
  $isSelected: number;
  onClick?: () => void;
}

// Styled component for ImageSide
const StyledImageSideBase = styled(Image)<{
  $isSelected: number;
  $itemKey: number;
}>`
  cursor: pointer;
  filter: ${({ $isSelected, $itemKey }) =>
    $isSelected === $itemKey ? 'brightness(65%)' : ''};
  object-fit: contain;
  object-position: center;
`;

export const StyledImageSide = ({
  $itemKey,
  src,
  isMobile,
  $isSelected,
  onClick,
}: StyledDetailsImageProps) => {
  return (
    <StyledImageSideBase
      $itemKey={$itemKey}
      alt="Preview image"
      src={src}
      width={isMobile ? '18vw' : '7vw'}
      height={isMobile ? '18vw' : '7vw'}
      preview={false}
      $isSelected={$isSelected}
      onClick={onClick}
    />
  );
};

type StyledDetailsImageMainProps = {
  isMobile: boolean;
  src: string;
};

// Styled component for ImageMain
const StyledImageMainBase = styled(Image)`
  object-fit: contain;
  object-position: center;
`;

export const StyledImageMain = (props: StyledDetailsImageMainProps) => {
  return (
    <StyledImageMainBase
      width={props.isMobile ? '75vw' : '25vw'}
      height={props.isMobile ? '75vw' : '25vw'}
      preview={false}
      src={props.src}
      alt="Main image"
      key="mainImage"
    />
  );
};

// Fabric cards used for reccomendations
export const ImageFabricCardStyle = styled(Image)`
  padding: 10px 10px 0 0;
  object-fit: contain;
  object-position: center;
`;

interface ImageFabricCardProps {
  isMobile: boolean;
  src: string;
  alt: string;
}

export const ImageFabricCard = ({
  isMobile,
  src,
  alt,
}: ImageFabricCardProps) => (
  <ImageFabricCardStyle
    width={isMobile ? '70vw' : '12vw'}
    height={isMobile ? '70vw' : '12vw'}
    src={src}
    alt={alt}
    preview={false}
  />
);

// Fabric Image
export const FrontImageStyled = styled(Image)`
  object-fit: contain;
  object-position: center;
`;
