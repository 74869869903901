import { Empty, Pagination } from 'antd';
import {
  EmptyGalleryContainer,
  GalleryCardCol,
  GalleryViewContainer,
  PaginationContainer,
} from '../../elements/gallery/StyledGallery';
import i18n from '../../plugins/i18n';
import { record, Setter, TableStateObj } from '../../utils/types/Types';
import { GalleryCard } from './GalleryCard';

interface GalleryViewProps {
  fabrics: record[];
  tableState: TableStateObj;
  setTableState: Setter<TableStateObj>;
  isMobile: boolean;
  selectedKeys: number[];
  setSelectedKeys: Setter<number[]>;
  pageType: string;
}

export const GalleryView = ({
  fabrics,
  tableState,
  setTableState,
  isMobile,
  selectedKeys,
  setSelectedKeys,
  pageType,
}: GalleryViewProps) => {
  const handlePageChange = (page: number, pageSize?: number) => {
    setTableState((prevState) => ({
      ...prevState,
      current: page,
      pageSize: pageSize || prevState.pageSize,
    }));
  };

  return (
    <>
      {fabrics.length > 0 ? (
        <>
          <GalleryViewContainer gutter={[16, 16]} justify="start">
            {fabrics.length > 0 &&
              fabrics.map((fabric, index) => (
                <GalleryCardCol
                  key={fabric.id}
                  xs={24}
                  sm={12}
                  md={12}
                  lg={8}
                  xl={6}
                  xxl={6}
                  $isMobile={isMobile}
                >
                  <GalleryCard
                    fabric={fabric}
                    index={index + 1} // Start index at 1 same as fabric table rows
                    selectedKeys={selectedKeys}
                    setSelectedKeys={setSelectedKeys}
                    pageType={pageType}
                    isMobile={isMobile}
                  />
                </GalleryCardCol>
              ))}
          </GalleryViewContainer>
          <PaginationContainer>
            <Pagination
              current={tableState.current}
              pageSize={tableState.pageSize}
              total={tableState.total}
              onChange={handlePageChange}
            />
          </PaginationContainer>
        </>
      ) : (
        <EmptyGalleryContainer>
          <Empty description={i18n.t('headers:no_data')} />
        </EmptyGalleryContainer>
      )}
    </>
  );
};
