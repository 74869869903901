import { message } from 'antd';
import { api } from '../api/api';
import { ImageBulkCropperResponse } from './AdminTypes';

/**
 * Crops images in bulk for a specified folder based on the given dimensions.
 */
export async function imageBulkCropper(
  folderName: string,
  cropDimensions: {
    leftPosition: number;
    topPosition: number;
    rightPosition: number;
    bottomPosition: number;
    fromRight: number;
    fromBottom: number;
  }
): Promise<ImageBulkCropperResponse | null> {
  const data = {
    folder_name: folderName,
    left_position: cropDimensions.leftPosition,
    top_position: cropDimensions.topPosition,
    right_position: cropDimensions.rightPosition,
    bottom_position: cropDimensions.bottomPosition,
    from_right: cropDimensions.fromRight,
    from_bottom: cropDimensions.fromBottom,
  };
  return api({
    method: 'PUT',
    url: 'v1/superuser/crop',
    body: data,
    showErrMsg: true,
  })
    .then((response) => {
      // Check if the cropping was unsuccessful
      if (response.Errors && response.Errors.length > 0) {
        const errorsString = JSON.stringify(response.Errors);
        message.error(errorsString);
        return {
          text: `${JSON.stringify(response.msg)}\n${errorsString}`,
          isSuccess: false,
        };
      } else {
        message.success(
          `Bulk Image Folder '${folderName}' successfully cropped.`
        );
        return {
          text: `Bulk Image Folder '${folderName}' successfully cropped.`,
          isSuccess: true,
        };
      }
    })
    .catch((error) => {
      if (error) {
        return { text: error.message, isSuccess: false };
      }
      return null;
    });
}
