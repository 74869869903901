import { Space } from 'antd';
import i18n from 'i18next';
import { Link } from 'react-router-dom';

import { routesNamedDict } from '../../../routes';
import { ExportAllFabricsModal } from '../../modal/fabric/ExportAllFabricsModal';
import { EditFabricLibraryModal } from '../../modal/library/EditFabricLibraryModal';
import { LibraryRecord } from '../TableTypes';
import { BinFabricLibraryButton } from './BinFabricLibraryButton';
import { CopyLibraryToCollectionLink } from './CopyLibraryToCollectionLink';

export const columnsDesktop = (
  account: string | null,
  getLibraryList: () => Promise<void>
) => [
  {
    dataIndex: 'name',
    key: 'name',
    title: i18n.t('common:name'),
    columnType: 'string',
    render: (name: string) => (
      <span>
        <span style={{ marginLeft: '15px' }}>{name}</span>
      </span>
    ),
  },
  {
    dataIndex: 'description',
    key: 'description',
    title: i18n.t('column_titles:description'),
  },
  {
    dataIndex: 'owner',
    key: 'owner',
    title: i18n.t('column_titles:owner'),
    columnType: 'string',
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
    title: i18n.t('column_titles:created_at'),
    columnType: 'date',
  },
  {
    dataIndex: 'id',
    key: 'id',
    title: i18n.t('column_titles:action'),
    render: (_: unknown, record: LibraryRecord) => {
      return (
        <Space size="middle">
          <Link
            to={
              routesNamedDict.FABRICS_LIBRARY_ID.replace(/:libraryId/g, '') +
              record.id
            }
          >
            {i18n.t('column_titles:view')}
          </Link>
          <EditFabricLibraryModal
            record={record}
            getLibraryList={getLibraryList}
          />
          <ExportAllFabricsModal libraryId={record.id} />
          {account !== 'viewer' && (
            <CopyLibraryToCollectionLink libraryId={record.id} />
          )}
          {/* <ExportLibraryButton data={record} /> Disabled as approach needs to be changed with regards to pagination*/}
          {record.is_owner && (
            <BinFabricLibraryButton
              fabricId={record.id}
              getLibraryList={getLibraryList}
            />
          )}
        </Space>
      );
    },
  },
];
