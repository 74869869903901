import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../utils/types/Types';
import { TabTitle } from '../../TabTitle';
import { TableGeneric } from '../TableGeneric';
import { columnFields, InspirationsListProps } from '../TableTypes';
import { columnsDesktop } from './ColumnsDesktop';
import { columnsMobile } from './ColumnsMobile';

export function InspirationsList({
  data,
  title,
  getInspirations,
}: InspirationsListProps) {
  const [columns, setColumns] = useState<columnFields[]>([]);
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);

  useEffect(() => {
    if (isMobileFlag) {
      setColumns(columnsMobile);
    } else {
      setColumns(columnsDesktop(getInspirations));
    }
  }, [isMobileFlag]);

  return (
    <>
      {data[0] && <TabTitle title={title} />}
      <TableGeneric columns={columns} data={data} isBackendPaginated={false} />
    </>
  );
}
