import { Button, Col, List, Row, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SendCollectionButton } from '../../../elements/buttons/StyledButtons';
import {
  RedUnCheckedIcon,
  SendLinkIcon,
} from '../../../elements/icons/StyledIcons';
import {
  EmailInput,
  EmailInputCol,
  SendCollectionContainer,
  ShareableLinksContainer,
} from '../../../elements/modals/StyledModal';
import { ModalTitleText } from '../../../elements/typography/ModalTitle';
import { WarningText } from '../../../elements/typography/TextWarning';
import {
  SharedLink,
  SharedLinksResponse,
} from '../../../utils/backendRequests/collection/CollectionTypes';
import { createCollectionLink } from '../../../utils/backendRequests/collection/createCollectionLink';
import { deleteShareableLink } from '../../../utils/backendRequests/collection/deleteShareableLink';
import { getShareableLinks } from '../../../utils/backendRequests/collection/getShareableLinks';
import { parseLocalDateTime } from '../../../utils/parsers/parseLocalDateTime';
import { RootState } from '../../../utils/types/Types';
import { Modal } from '../../Modal';

interface ShareableLinkModalProps {
  collectionId: string;
}

export function ShareableLinkModal({ collectionId }: ShareableLinkModalProps) {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [linkIdToDelete, setLinkIdToDelete] = useState<string>('');
  const [linkCount, setLinkCount] = useState<number>(0);
  const [maxLinkLimit, setMaxLinkLimit] = useState<number>(0);
  const [sharedEmail, setSharedEmail] = useState<string>('');
  const [sharedLinks, setSharedLinks] = useState<SharedLinksResponse | null>(
    null
  );
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);

  const getLinks = async () => {
    const res = await getShareableLinks();
    setSharedLinks(res);
    setLinkCount(res.links.length);
    setMaxLinkLimit(res.total_link_limit);
  };

  // Fetch links for current collection
  useEffect(() => {
    getLinks();
  }, [collectionId]);

  const handleSubmit = async () => {
    let successFlag;
    if (sharedEmail) {
      successFlag = await createCollectionLink({
        collectionId,
        sharedEmail,
      });
    }
    if (successFlag) {
      setSharedEmail('');
      getLinks();
    }
  };

  const openConfirmationModal = (linkId: string) => {
    setLinkIdToDelete(linkId);
    setIsConfirmationModalOpen(true);
  };

  const handleDeleteLink = async () => {
    const successFlag = await deleteShareableLink(collectionId, linkIdToDelete);
    if (successFlag) {
      setIsConfirmationModalOpen(false);
      getLinks();
    }
  };

  return (
    <SendCollectionContainer $isMobileFlag={isMobileFlag}>
      <ModalTitleText>{i18n.t('headers:share_outside_delvify')}</ModalTitleText>
      <Row gutter={[16, 8]}>
        {/* Remaining Links Section */}
        <Col span={24}>
          <p>
            {i18n.t('long_messages:message_collection_link_share_explaination')}
          </p>
          <p>
            {i18n.t('headers:remaining_links')}: {maxLinkLimit - linkCount}
          </p>
        </Col>
        {/* Email Input Section */}
        <EmailInputCol span={24}>
          <p>{i18n.t('column_titles:email')}:</p>
          <EmailInput
            value={sharedEmail as string}
            onChange={(e) => setSharedEmail(e.target.value)}
            disabled={linkCount === maxLinkLimit}
          />
          {linkCount === maxLinkLimit && (
            <WarningText>
              *{i18n.t('long_messages:maximum_links_created_message')}
            </WarningText>
          )}
        </EmailInputCol>
        {/* Shareable Links Section */}
        <Col span={24}>
          {sharedLinks !== null && sharedLinks?.links.length > 0 ? (
            <ShareableLinksContainer>
              <List
                dataSource={sharedLinks.links.filter(
                  (link) => link.collection_id === collectionId
                )}
                locale={{
                  emptyText: i18n.t(
                    'long_messages:long_messages_no_links_collection_message'
                  ),
                }}
                renderItem={(item: SharedLink, index: number) => (
                  <List.Item
                    actions={[
                      <Button
                        type="text"
                        icon={<RedUnCheckedIcon />}
                        onClick={() => openConfirmationModal(item.id)}
                        danger
                      />,
                    ]}
                  >
                    <div>
                      {`${index + 1}. ${i18n.t('headers:collection_name')}: ${item.collection_name}`}
                      <br />
                      {`${i18n.t('headers:email')}: ${item.shared_email}`}
                      <br />
                      <span>
                        {`${i18n.t('headers:expires')}: ${parseLocalDateTime(item.expiry_datetime)}`}
                      </span>
                    </div>
                  </List.Item>
                )}
              />
            </ShareableLinksContainer>
          ) : null}
        </Col>
      </Row>
      <SendCollectionButton
        onClick={handleSubmit}
        disabled={linkCount === maxLinkLimit || sharedEmail === ''}
      >
        <Space>
          {i18n.t('buttons:send_link')}
          <SendLinkIcon />
        </Space>
      </SendCollectionButton>
      <Modal
        style={{ marginTop: '10%' }}
        width={400}
        title={i18n.t('headers:confirm_remove_link')}
        open={isConfirmationModalOpen}
        onOk={() => handleDeleteLink()}
        okText={i18n.t('buttons:delete')}
        okButtonProps={{ danger: true }}
        onCancel={() => setIsConfirmationModalOpen(false)}
      ></Modal>
    </SendCollectionContainer>
  );
}
