import i18n from 'i18next';

import { BinTab } from './BinTab';
import { FabricBinTabProps } from './BinTypes';

export function FabricBinTab({ fabrics, getBinContents }: FabricBinTabProps) {
  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      title: i18n.t('fabric_fields:name'),
      columnType: 'string' as const,
    },
    {
      dataIndex: 'library_name',
      key: 'library_name',
      title: i18n.t('fabric_fields:belongs_to_library'),
      columnType: 'string' as const,
    },
    {
      dataIndex: 'deleted_timestamp',
      key: 'deleted_timestamp',
      title: i18n.t('column_titles:deleted_at'),
      columnType: 'date' as const,
    },
  ];

  return (
    <BinTab
      binData={fabrics}
      binColumns={columns}
      getBinContents={getBinContents}
      binType="fabrics"
    />
  );
}
