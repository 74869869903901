import { Col, Divider, Row, Space } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ShareCollectionButton } from '../../../elements/buttons/StyledButtons';
import { DesignerBrandsIcon } from '../../../elements/icons/StyledIcons';
import {
  BrandSelector,
  DesignerBrandContainer,
  SharedDesignersContainer,
  VerticalModalDivider,
} from '../../../elements/modals/StyledModal';
import { ModalTitleText } from '../../../elements/typography/ModalTitle';
import { shareCollection } from '../../../utils/backendRequests/collection/shareCollection';
import { RootState } from '../../../utils/types/Types';
import { ShareableLinkModal } from '../../modal/collection/ShareableLinkModal';
import {
  KeyValueDesignerBrandObject,
  SharedDesignerBrandsProps,
} from './CollectionListTypes';

export function SharedDesignerBrands({
  sharedDesignerBrands,
  collectionId,
  setSharedDesignerBrands,
  handleOk,
  approvedDesignerBrands,
  setSelectedSharedDesigners,
  selectedSharedDesigners,
}: SharedDesignerBrandsProps) {
  const [unsharedSharedDesigners, setUnsharedSharedDesigners] = useState<
    KeyValueDesignerBrandObject[]
  >([]);
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);

  useEffect(() => {
    // Converts list of designer brands (Object) to designer brand ids (number) for easy filtering
    const sharedDesignerBrandsIds = sharedDesignerBrands.map(
      (designerBrand: KeyValueDesignerBrandObject) => designerBrand.key
    );
    // This updates the list of designer brands who have not been shared with previously
    // Users will be unable to select a designer brands they have already shared the collection with
    setUnsharedSharedDesigners(
      approvedDesignerBrands.filter(
        (designerBrand) => !sharedDesignerBrandsIds.includes(designerBrand.key)
      )
    );
  }, [approvedDesignerBrands, sharedDesignerBrands]);

  const handleChange = (
    value: KeyValueDesignerBrandObject[],
    option: KeyValueDesignerBrandObject[]
  ) => {
    // Updates the list of designer brands that the user has selected
    setSelectedSharedDesigners(option);
  };

  const removeSharedDesignerBrandFromCollection = (
    designerBrand: KeyValueDesignerBrandObject
  ) => {
    const newSharedDesignerBrands = sharedDesignerBrands.filter(
      (brand) => brand.key !== designerBrand.key
    );
    const sharedDesignerBrandsIds = newSharedDesignerBrands.map(
      (designerBrand: KeyValueDesignerBrandObject) => designerBrand.key
    );
    shareCollection(collectionId, sharedDesignerBrandsIds).then(() => {
      setSharedDesignerBrands(newSharedDesignerBrands);
    });
  };

  return (
    <>
      {isMobileFlag ? (
        <>
          <Col span={24}>
            <ModalTitleText>
              {i18n.t('headers:share_with_designer_brands')}
            </ModalTitleText>
            <Row>
              <BrandSelector
                mode="multiple"
                placeholder={i18n.t('headers:add_more_designer_brands')}
                // @ts-expect-error because Antd restrictions
                onChange={handleChange}
                options={unsharedSharedDesigners.map((brand) => ({
                  value: brand.key,
                  label: brand.value,
                }))}
                value={selectedSharedDesigners}
              />
            </Row>
            <Row>
              <ShareCollectionButton
                type="primary"
                onClick={handleOk}
                disabled={selectedSharedDesigners.length === 0}
              >
                {i18n.t('buttons:share')}
              </ShareCollectionButton>
            </Row>
            <SharedDesignersContainer direction="vertical" size="small">
              <p>{i18n.t('headers:designer_brands_with_access')}</p>
              {sharedDesignerBrands.map((designerBrand, index: number) => (
                <Space direction="vertical" size="small" key={index}>
                  <DesignerBrandContainer>
                    <DesignerBrandsIcon />
                    <p>{designerBrand.value}</p>
                    <a
                      role="button"
                      onClick={() =>
                        removeSharedDesignerBrandFromCollection(designerBrand)
                      }
                    >
                      {i18n.t('buttons:remove')}
                    </a>
                  </DesignerBrandContainer>
                </Space>
              ))}
            </SharedDesignersContainer>
          </Col>
          <Divider />
          <Col span={24}>
            <ShareableLinkModal collectionId={collectionId} />
          </Col>
        </>
      ) : (
        <Row gutter={16}>
          {/* Left Column: Designer Brands */}
          <Col span={8}>
            <ModalTitleText>
              {i18n.t('headers:share_with_designer_brands')}
            </ModalTitleText>
            <Row>
              <BrandSelector
                mode="multiple"
                placeholder={i18n.t('headers:add_more_designer_brands')}
                // @ts-expect-error because Antd restrictions
                onChange={handleChange}
                options={unsharedSharedDesigners.map((brand) => ({
                  value: brand.key,
                  label: brand.value,
                }))}
                value={selectedSharedDesigners}
              />
            </Row>
            <Row>
              <ShareCollectionButton
                type="primary"
                onClick={handleOk}
                disabled={selectedSharedDesigners.length === 0} // Disable if no designer is selected
              >
                {i18n.t('buttons:share')}
              </ShareCollectionButton>
            </Row>
            <SharedDesignersContainer direction="vertical" size="small">
              <p>{i18n.t('headers:designer_brands_with_access')}</p>
              {sharedDesignerBrands.map((designerBrand, index: number) => (
                <Space direction="vertical" size="small" key={index}>
                  <DesignerBrandContainer>
                    <DesignerBrandsIcon />
                    <p>{designerBrand.value}</p>
                    <a
                      role="button"
                      onClick={() =>
                        removeSharedDesignerBrandFromCollection(designerBrand)
                      }
                    >
                      {i18n.t('buttons:remove')}
                    </a>
                  </DesignerBrandContainer>
                </Space>
              ))}
            </SharedDesignersContainer>
          </Col>
          {/* Vertical Divider */}
          <Col span={1}>
            <VerticalModalDivider type="vertical" />
          </Col>
          {/* Right Column: Shareable Link Modal */}
          <Col span={12}>
            <ShareableLinkModal collectionId={collectionId} />
          </Col>
        </Row>
      )}
    </>
  );
}
