import { api } from '../api/api';

/**
 * Gets possible measurement units.
 */
export const getMeasurementUnits = async (): Promise<
  Record<string, string>
> => {
  return await api({
    method: 'GET',
    url: `v1/list/measurement_unit_types`,
    showErrMsg: true,
  });
};
