import i18n from 'i18next';

import { BinTab } from './BinTab';
import { ProjectBinTabProps } from './BinTypes';

export function ProjectBinTab({
  projects,
  getBinContents,
}: ProjectBinTabProps) {
  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      title: i18n.t('common:name'),
      columnType: 'string' as const,
    },
    {
      dataIndex: 'deleted_timestamp',
      key: 'deleted_timestamp',
      title: i18n.t('column_titles:deleted_at'),
      columnType: 'date' as const,
    },
  ];

  return (
    <BinTab
      binData={projects}
      binColumns={columns}
      getBinContents={getBinContents}
      binType="projects"
    />
  );
}
