import { Row } from 'antd';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TabTitle } from '../../components/TabTitle';
import { CreateColourModal } from '../../components/modal/colour/CreateColourModal';
import { EditColourModal } from '../../components/modal/colour/EditColourModal';
import { ColourList } from '../../components/table/coloursTable/ColourList';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { ButtonBasic } from '../../elements/buttons/StyledButtons';
import { MainLayout } from '../../layouts/Main';
import { routesNamedDict } from '../../routes';
import { getColoursResponse } from '../../utils/backendRequests/colours/ColourTypes';
import { getColours } from '../../utils/backendRequests/colours/getColours';
import { RootAuth } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export type ColourRecord = {
  id: string;
  name: string;
  hex_value: string;
  is_owner: boolean;
};

export function ColoursPage({ title }: TitleProp) {
  const [data, setData] = useState<getColoursResponse>([]);
  const [createColourModal, setColourModal] = useState({ visible: false });
  const [editColourModal, setEditColourModal] = useState({ visible: false });
  const [edtiColoursRecord, setEditColoursRecord] =
    useState<ColourRecord | null>(null);
  const role = useSelector((state: RootAuth) => state.auth?.role);
  const account = useSelector((state: RootAuth) => state.auth.accountType);
  const navigate = useNavigate();

  const editColourOnClick = (record: ColourRecord) => {
    setEditColoursRecord(record);
    setEditColourModal({ visible: true });
  };

  const refreshColours = async () => {
    setData(await getColours());
  };

  useEffect(() => {
    if (role === 'supplier') {
      refreshColours();
    } else {
      navigate('/' + routesNamedDict.NOT_FOUND);
    }
  }, [role, navigate]);

  return (
    <MainLayout>
      <HeaderTitle
        title={i18n.t('page_titles:colours_page')}
        subtitle={i18n.t('page_subtitles:colours_sub')}
      />
      <TabTitle title={title} />
      <Row justify="start">
        {account !== 'viewer' && (
          <ButtonBasic onClick={() => setColourModal({ visible: true })}>
            {i18n.t('buttons:create')}
          </ButtonBasic>
        )}
      </Row>
      <ColourList
        title={title}
        data={data}
        editColourOnClick={editColourOnClick}
        getColoursList={refreshColours}
      />
      <CreateColourModal
        modal={createColourModal}
        setModal={setColourModal}
        onSuccess={refreshColours}
      />
      {edtiColoursRecord && (
        <EditColourModal
          modal={editColourModal}
          setModal={setEditColourModal}
          record={edtiColoursRecord}
          onSuccess={refreshColours}
        />
      )}
    </MainLayout>
  );
}
