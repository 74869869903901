import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import { TabTitle } from '../../components/TabTitle';
import {
  BackendFilterProps,
  TransformedFilterProps,
} from '../../components/filter/FilterTypes';
import { FabricSearchResults } from '../../components/search/fabric/FabricSearchResults';
import { defaultPageSize } from '../../components/table/TableGeneric';
import { HeaderTitle } from '../../components/title/HeaderTitle';
import { MainLayout } from '../../layouts/Main';
import i18n from '../../plugins/i18n';
import { routesNamedDict } from '../../routes';
import { searchTextFabric } from '../../utils/backendRequests/textSearch/fabric';
import { record } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export function TextSearchResultsPage({ title }: TitleProp) {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [searchParams] = useSearchParams();
  const textQuery = decodeURIComponent(searchParams.get('q') || '');
  const excludeMlFieldsQuery = searchParams.get('exclude_ml_fields') === 'true';
  const [searchResults, setSearchResults] = useState<record[]>([]);
  const [textSearchQuery, setTextSearchQuery] = useState<string>('');
  const [selectableColumnNames, setSelectableColumnNames] = useState<string[]>(
    []
  );
  const [sortableColumnNames, setSortableColumnNames] = useState<string[]>([]);
  // Represents the range of filterable fields from the backend
  const [filterableFields, setFilterableFields] = useState<BackendFilterProps>(
    {}
  );
  // Represents the selected filter values to be sent to the backend
  const [filterableColumns, setFilterableColumns] =
    useState<TransformedFilterProps>({});
  const [excludeMlFields, setExcludeMlFields] = useState<boolean>(false);

  // Pagination and Sorter state
  const [tableState, setTableState] = useState({
    current: 1,
    pageSize: defaultPageSize,
    total: 0,
    sortField: '',
    sortOrder: '',
  });

  const getTextSearchResults = async () => {
    const query = {
      text_query: state?.text_query || textQuery,
      exclude_ml_fields: state?.exclude_ml_fields || excludeMlFieldsQuery,
      new_search: false,
    };
    const results = await searchTextFabric(
      query,
      navigate,
      routesNamedDict,
      tableState,
      filterableColumns
    );
    setSearchResults(results.result_items);
    setTableState((prevState) => ({
      ...prevState,
      total: results.result_items_count,
    }));
    setSelectableColumnNames(results.selectable_columns);
    setSortableColumnNames(results.sortable_columns);
    if (Object.keys(filterableFields).length === 0) {
      setFilterableFields(results.filterable_columns);
    }
    setExcludeMlFields(results.are_ml_fields_excluded);
    setTextSearchQuery(results.text_query);
  };

  const fetchFilteredData = async () => {
    const query = {
      text_query: textSearchQuery,
      exclude_ml_fields: excludeMlFields,
      new_search: false,
    };
    const results = await searchTextFabric(
      query,
      navigate,
      routesNamedDict,
      {
        ...tableState,
        current: 1,
      },
      filterableColumns
    );
    setSearchResults(results.result_items);
    setTableState((prevState) => ({
      ...prevState,
      current: 1,
      total: results.result_items_count,
    }));
    setSelectableColumnNames(results.selectable_columns);
    setSortableColumnNames(results.sortable_columns);
  };

  const fetchData = async () => {
    if (state === null) {
      if (!textQuery) {
        navigate(routesNamedDict.HOME);
      }
      // Rerun text search api call if state is null, is likely a page refresh
      getTextSearchResults();
    } else {
      setSearchResults(state.result_fabrics);
      setTextSearchQuery(state.text_query || null);
      setTableState((prevState) => ({
        ...prevState,
        total: state.fabrics_count || 0,
      }));
      setSelectableColumnNames(state.selectable_columns);
      setSortableColumnNames(state.sortable_columns);
      if (Object.keys(filterableFields).length === 0) {
        setFilterableFields(state.filterable_columns);
      }
    }
    window.history.replaceState({}, '');
  };

  useEffect(() => {
    fetchData();
  }, [textQuery, state?.result_fabrics, filterableColumns]);

  // Fetch text search results when pagination or sorter changes
  useEffect(() => {
    if (searchResults.length > 0) {
      getTextSearchResults();
    }
  }, [
    tableState.current,
    tableState.pageSize,
    tableState.sortField,
    tableState.sortOrder,
  ]);

  return (
    <MainLayout>
      <HeaderTitle
        title={i18n.t('page_titles:text_search_results_page')}
        subtitle={i18n.t('page_subtitles:text_search_sub', {
          query: textSearchQuery,
        })}
      />
      <TabTitle title={title} />
      <FabricSearchResults
        initialData={searchResults}
        searchType={'text'}
        tableState={tableState}
        setTableState={setTableState}
        selectableColumnNames={selectableColumnNames}
        sortableColumns={sortableColumnNames}
        filterableFields={filterableFields}
        filterableColumns={filterableColumns}
        setFilterableColumns={setFilterableColumns}
        getFilteredFabrics={fetchFilteredData}
      />
    </MainLayout>
  );
}
