import { Space } from 'antd';

import i18n from 'i18next';
import { Link } from 'react-router-dom';
import { GenericTableColumn } from '../../../components/table/TableTypes';
import { CollectionListItem } from '../../../utils/backendRequests/collection/CollectionTypes';
import { EditCollectionModalWithLink } from '../../modal/collection/EditCollectionModalWithLink';
import { ShareCollectionModalWithLink } from '../../modal/collection/ShareCollectionModalWithLink';
import { ExportAllFabricsModal } from '../../modal/fabric/ExportAllFabricsModal';
import { BinCollectionButton } from './BinCollectionButton';
import { KeyValueDesignerBrandObject } from './CollectionListTypes';
import { CopyCollectionToCollectionLink } from './CopyCollectionToCollectionLink';
import { CopyCollectionToProjectLink } from './CopyCollectionToProjectLink';
import { UnshareCollectionButton } from './UnshareCollectionButton';

export const getColumns = (
  approvedDesignerBrands: KeyValueDesignerBrandObject[],
  role: string | null,
  getCollectionsList: () => void,
  account: string | null
): GenericTableColumn<CollectionListItem>[] => [
  {
    dataIndex: 'name',
    title: i18n.t('common:name'),
    columnType: 'string',
  },
  {
    dataIndex: 'description',
    title: i18n.t('column_titles:description'),
  },
  {
    dataIndex: 'created_at',
    title: i18n.t('column_titles:created_at'),
    columnType: 'date',
  },
  {
    dataIndex: 'owner',
    title: i18n.t('column_titles:owner'),
    columnType: 'string',
  },
  {
    dataIndex: 'id',
    title: i18n.t('column_titles:action'),
    render: (value: string, record: CollectionListItem) => (
      <Space size="middle">
        <Link to={`/collections/${value}`}>{i18n.t('column_titles:view')}</Link>
        {record.is_owner && account !== 'viewer' && (
          <EditCollectionModalWithLink
            record={record}
            getCollectionsList={getCollectionsList}
          />
        )}
        <ExportAllFabricsModal collectionId={record.id} />
        {record.is_owner && account !== 'viewer' && (
          <ShareCollectionModalWithLink
            collectionId={record.id}
            approvedDesignerBrands={approvedDesignerBrands}
            getCollectionsList={getCollectionsList}
          />
        )}
        {record.is_owner && account !== 'viewer' && (
          <CopyCollectionToCollectionLink
            collectionId={record.id}
            getCollectionsList={getCollectionsList}
          />
        )}
        {/* <ExportCollectionButton data={record} /> Disabled as approach needs to be changed with regards to pagination*/}
        {record.is_owner && account !== 'viewer' && (
          <BinCollectionButton
            collectionId={record.id}
            getCollectionsList={getCollectionsList}
          />
        )}
        {role === 'designer' && (
          <CopyCollectionToProjectLink collectionId={record.id} />
        )}
        {!record.is_owner && (
          <UnshareCollectionButton
            collectionId={record.id}
            getCollectionsList={getCollectionsList}
          />
        )}
      </Space>
    ),
  },
];
