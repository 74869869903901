import { message } from 'antd';
import i18n from 'i18next';
import { MsgProp } from '../../types/Types';
import { api } from '../api/api';

/**
 * Updates a certification details entry for a certification by overwriting the file.
 * This certifications details must be existing and will be pending for review again.
 */
export async function updateCertDetails(
  certification_id: string,
  certification_file: FormData
): Promise<MsgProp> {
  return await api({
    method: 'PUT',
    url: `v1/certification/${certification_id}/details`,
    showErrMsg: true,
    body: certification_file,
  }).then((res: MsgProp) => {
    message.success(
      i18n.t('long_messages:message_cert_file_overwrite_under_review')
    );
    return res;
  });
}
