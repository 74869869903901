import { Space } from 'antd';
import i18n from 'i18next';
import {
  HangersInProgressStatusIcon,
  HangersInvalidStatusIcon,
  HangersSentStatusIcon,
  HangersVerifiedStatusIcon,
} from '../../../elements/certifications/StyledCertifications';

type HangerStatusRenderProps = {
  status: string;
};

export const HangerStatusRender = ({ status }: HangerStatusRenderProps) => {
  if (status === 'received')
    return (
      <Space>
        {i18n.t(`hanger_status_values:${status}`)}
        <HangersVerifiedStatusIcon />
      </Space>
    );
  else if (status === 'sent')
    return (
      <Space>
        {i18n.t(`hanger_status_values:${status}`)}
        <HangersSentStatusIcon />
      </Space>
    );
  else if (status === 'cancelled')
    return (
      <Space>
        {i18n.t(`hanger_status_values:${status}`)}
        <HangersInvalidStatusIcon />
      </Space>
    );
  else if (status === 'in_progress')
    return (
      <Space>
        {i18n.t(`hanger_status_values:${status}`)}
        <HangersInProgressStatusIcon />
      </Space>
    );
};
