import { logoutUser } from '../../../plugins/redux/auth';
import { NavigateProp } from '../../types/Types';
import { PathsObject, ReduxStoreProp } from './AuthTypes';
/**
 * Logout the user. Navigates to the login page after the user credentials are removed in redux and encypted local storage.
 */
export async function logout(
  store: ReduxStoreProp,
  navigate: NavigateProp,
  paths: PathsObject
): Promise<void> {
  store.dispatch(logoutUser());
  navigate(paths.LOGIN);
}
