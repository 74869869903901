import { Space } from 'antd';
import i18n from 'i18next';

import { DisplayColour } from '../../DisplayColour';
import { ColourColumnRecord, editColourClickProp } from '../TableTypes';
import { DeleteColourButton } from './DeleteColourButton';

export const coloursColumns = (
  editColourOnClick: editColourClickProp,
  brandName: string | null,
  account: string | null,
  getColoursList: () => Promise<void>
) => {
  const commonColumns = [
    {
      dataIndex: 'name',
      title: i18n.t('common:name'),
      columnType: 'string',
    },
    {
      dataIndex: 'hex_value',
      title: i18n.t('column_titles:hex_value'),
      render: (hex_value: string | null) => {
        const Colour = {
          hexValue: hex_value,
          title: hex_value,
          tooltipShow: false,
        };
        return <DisplayColour colours={[Colour]} />;
      },
    },
    {
      dataIndex: 'is_owner',
      title: i18n.t('column_titles:is_owner'),
      columnType: 'boolean',
      render: (is_owner: boolean) => {
        return is_owner
          ? brandName
            ? brandName
            : i18n.t('common:company')
          : i18n.t('common:common');
      },
    },
  ];

  const columnsWithActions = [
    ...commonColumns,
    {
      title: i18n.t('column_titles:action'),
      fixed: 'right',
      render: (_: unknown, record: ColourColumnRecord) => {
        return (
          <Space size="middle">
            {record.is_owner && account !== 'viewer' && (
              <>
                <a onClick={() => editColourOnClick(record)} role="button">
                  {i18n.t('buttons:edit')}
                </a>
              </>
            )}
            {record.is_owner && (
              <DeleteColourButton
                colourId={record.id}
                getColoursList={getColoursList}
              />
            )}
          </Space>
        );
      },
    },
  ];

  return account !== 'viewer' ? columnsWithActions : commonColumns;
};
