import { useEffect, useState } from 'react';

import { TableGeneric } from '../../../components/table/TableGeneric';
import { AdminCertReviewResponse } from '../../../utils/backendRequests/admin/AdminTypes';
import { TabTitle } from '../../TabTitle';
import { CertColumns, CertReviewColumn } from './CertColumns';

type AdminCertListProps = {
  data: AdminCertReviewResponse[];
  title: string;
  onSuccess: () => void;
};

export function AdminCertReviewList({
  data,
  title,
  onSuccess,
}: AdminCertListProps) {
  const [columns, setColumns] = useState<CertReviewColumn[]>([]);

  useEffect(() => {
    setColumns(CertColumns(onSuccess));
  }, []);

  return (
    <>
      {/* if no data's fed in, show parent component's title for browser tab, i.e. use 'Fabric' instead of 'Library :id', otherwise child will overide parent tab's title */}
      {data[0] && <TabTitle title={title} />}
      <TableGeneric columns={columns} data={data} isBackendPaginated={false} />
    </>
  );
}
