import { Button, Row, Upload, message } from 'antd';
import { UploadFile } from 'antd/lib/upload';
import i18n from 'i18next';
import { useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { TextB3 } from '../../../elements/typography/TextB3';
import { getCertObj } from '../../../utils/backendRequests/certifications/CertificationTypes';
import { createCertDetails } from '../../../utils/backendRequests/certifications/createCertDetails';
import { updateCertDetails } from '../../../utils/backendRequests/certifications/updateCertDetails';
import { Modal } from '../../Modal';

type createOrUpdateFileProps = {
  record: getCertObj;
  onSuccess: () => Promise<void>;
};

export const CreateOrUpdateFileModal = ({
  record,
  onSuccess,
}: createOrUpdateFileProps) => {
  const [uploadModal, setUploadModal] = useState(false);
  const [overwriteModal, setOverwriteModal] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleFileChange = ({
    fileList: newFileList,
  }: {
    fileList: UploadFile[];
  }) => {
    setFileList(newFileList);
  };

  const handleFileRemove = (file: UploadFile) => {
    setFileList(fileList.filter((item) => item.uid !== file.uid));
  };

  const handleClick = () => {
    if (record.url) {
      setOverwriteModal(true);
    } else {
      setUploadModal(true);
    }
  };

  const handleUpload = async () => {
    if (fileList.length > 0) {
      const formData = new FormData();
      const file = fileList[0] as UploadFile;
      formData.append('certification_file', file.originFileObj as Blob);
      const response = await createCertDetails(record.id, formData);
      if (response) {
        onSuccess();
        handleCancelModal();
      }
    } else {
      message.error(i18n.t('error_messages:file_not_selected'));
    }
  };

  const handleOverwrite = async () => {
    if (fileList.length > 0) {
      const formData = new FormData();
      const file = fileList[0] as UploadFile;
      formData.append('certification_file', file.originFileObj as Blob);
      const response = await updateCertDetails(record.id, formData);
      if (response) {
        onSuccess();
        handleCancelModal();
      }
    } else {
      message.error(i18n.t('error_messages:file_not_selected'));
    }
  };

  const handleCancelModal = () => {
    setUploadModal(false);
    setOverwriteModal(false);
    setFileList([]);
  };

  const props = {
    maxCount: 1,
    accept: '.pdf',
    beforeUpload: () => false,
    onChange: handleFileChange,
    onRemove: handleFileRemove,
    fileList,
  };

  return (
    <>
      <a role="Button" onClick={handleClick}>
        {i18n.t(`buttons:${record.url ? 'overwrite_file' : 'upload_file'}`)}
      </a>
      <Modal
        title={i18n.t('upload_file_for_certification', {
          cert_name: record.name,
        })}
        open={uploadModal}
        onCancel={handleCancelModal}
        cancelButtonProps={{ danger: true }}
        onOk={handleUpload}
        okText={i18n.t('buttons:upload_file')}
      >
        <Row style={{ marginTop: '20px' }}>
          {i18n.t('long_message:message_explain_cert_upload_file')}
        </Row>
        <Row style={{ marginTop: '10px' }}>{i18n.t('headers:input_file')}:</Row>
        <Row wrap={false}>
          <Upload {...props}>
            <Button disabled={fileList.length !== 0} icon={<UploadOutlined />}>
              {i18n.t('buttons:select_files')}
            </Button>
          </Upload>
        </Row>
        <Row style={{ marginTop: '5px' }}>
          <TextB3>
            {i18n.t('long_messages:certification_upload_file_rule')}
          </TextB3>
        </Row>
      </Modal>
      <Modal
        title={i18n.t('overwrite_file_for_certification', {
          cert_name: record.name,
        })}
        open={overwriteModal}
        onCancel={handleCancelModal}
        cancelButtonProps={{ danger: true }}
        onOk={handleOverwrite}
        okText={i18n.t('buttons:overwrite_file')}
      >
        <Row style={{ marginTop: '20px' }}>
          {i18n.t('long_message:message_explain_cert_overwrite_file')}
        </Row>
        <Row style={{ marginTop: '10px' }}>{i18n.t('headers:input_file')}:</Row>
        <Row wrap={false}>
          <Upload {...props}>
            <Button disabled={fileList.length !== 0} icon={<UploadOutlined />}>
              {i18n.t('buttons:select_files')}
            </Button>
          </Upload>
        </Row>
        <Row style={{ marginTop: '5px' }}>
          <TextB3>
            {i18n.t('long_messages:certification_upload_file_rule')}
          </TextB3>
        </Row>
      </Modal>
    </>
  );
};
