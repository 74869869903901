import { Checkbox, Col, Row } from 'antd';
import i18n from 'i18next';

import {
  BackendFilterProps,
  FilterValues,
  ImageFilterProps,
} from '../FilterTypes';

export function ImageFilter({
  choices,
  field,
  currentFilterValues,
  setCurrentFilterValues,
  updateFilteredData,
}: ImageFilterProps) {
  /**
   * Any time a checkbox value is changed, this function is called.
   * The new filter values are set to the currentFilterValues state
   * and the updateFilteredData function is called to update the data
   * which gets filtered.
   */
  const _onApplyFilter = (checkedValues: string[]) => {
    const newFilterValues: FilterValues | BackendFilterProps = {
      ...currentFilterValues,
      [field]: {
        ...currentFilterValues[field],
        values: checkedValues.map((value) => value),
      },
    };
    setCurrentFilterValues(newFilterValues);
    if (updateFilteredData) updateFilteredData(newFilterValues as FilterValues);
  };
  const checkboxOptions = [true, false].map((value, index) => {
    if (!choices.includes(value)) return null; // Render only if the value exists in choices
    return (
      <Row key={index} style={{ paddingTop: '5px' }}>
        <Checkbox value={value}>
          <div style={{ wordBreak: 'break-word' }}>
            {value
              ? i18n.t('common:includes_image')
              : i18n.t('common:missing_image')}
          </div>
        </Checkbox>
      </Row>
    );
  });

  if (choices.length === 0) {
    return <>{i18n.t('long_messages:null_data_filter')}</>;
  } else {
    return (
      <>
        <Checkbox.Group
          onChange={_onApplyFilter}
          value={currentFilterValues[field].values}
        >
          <Col>{checkboxOptions}</Col>
        </Checkbox.Group>
      </>
    );
  }
}
