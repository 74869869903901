import { message } from 'antd';
import i18n from 'i18next';
import { api } from '../api/api';

import { MsgProp } from '../../types/Types';

/**
 * Updates which designer brands a collection is shared with
 */
export async function shareCollection(
  collectionId: string,
  DesignerBrandIds: string[]
) {
  const data = { shared_with_designer_brand_ids: DesignerBrandIds };
  return await api({
    method: 'PUT',
    url: `v1/collection/${collectionId}/share`,
    body: data,
    showErrMsg: true,
  }).then((res: MsgProp) => {
    message.success(i18n.t('long_messages:message_collection_shared_success'));
    return res;
  });
}
