import { MsgProp } from '../../types/Types';
import { api } from '../api/api';
import { AdminCertReviewBodyProps } from './AdminTypes';

/**
 * Verify a certification details by id.
 */
export async function verifyCertificationDetails(
  cert_details_id: string,
  body: AdminCertReviewBodyProps
): Promise<MsgProp> {
  return await api({
    method: 'PUT',
    url: `v1/superuser/certification/verify/${cert_details_id}`,
    body: body,
    showErrMsg: true,
  }).then((res: MsgProp) => {
    return res;
  });
}
