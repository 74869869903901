import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';

import { message } from 'antd';
import i18n from '../../plugins/i18n';
import { font } from '../../styles/fonts/exportPDFFont';
import { FabricCert } from '../backendRequests/certifications/CertificationTypes';
import { CreateColourProp } from '../backendRequests/colours/ColourTypes';
import {
  compositionsProp,
  parseTranslatedCompositions,
} from '../parsers/parseTranslatedCompositions';
import { record } from '../types/Types';
import { priceParser } from './parseFabric';
import {
  fabricPropertyArray,
  parseFabricTitles,
  returnOrderedFields,
} from './parseFabricFields';
import { getTranslatedValuesAndUpdateData } from './parseFabricValues';
/**
 * Parse the value based on the key:
 *  - If the key is certifications, then return a string of all certifications.
 */
const _parseValue = (
  key: string,
  value: string | FabricCert[] | compositionsProp[] | undefined,
  currency?: string
) => {
  if (key === 'certifications') {
    // Assuming `value` is an array of FabricCert objects
    const certString = (value as FabricCert[])
      .map((cert: FabricCert) => cert.name)
      .join(', ');
    return String(certString);
  } else if (key === 'colours' && value !== null) {
    // Assuming `value` is an object with a 'name' property
    const colourString = (value as unknown as CreateColourProp[])
      .map((colour: CreateColourProp) => colour.name)
      .join(', ');
    return String(colourString);
  } else if (key === 'compositions') {
    const translatedComposition = parseTranslatedCompositions(
      value as compositionsProp[],
      true
    );
    return translatedComposition;
  } else if (typeof value === 'number' && key.startsWith('price')) {
    const parsedValue = priceParser(currency as string, value);
    return String(parsedValue);
  } else if (
    [
      'image_url_front',
      'image_url_back',
      'image_url_header',
      'image_url_macro',
    ].includes(key)
  ) {
    return '';
  } else if (value === null) {
    return '';
  } else {
    return String(value);
  }
};

const fabricFieldObjs = returnOrderedFields([
  'fabric_type',
  'name',
  'image_url_front',
  'image_url_back',
  'image_url_header',
  'image_url_macro',
  'original_supplier',
  'name_from_original_supplier',
  'weight_grams_per_sqm',
  'width_cm',
  'compositions',
  'construction',
  'description',
  'notes',
  'colours',
  'colour_fastness',
  'country_of_origin',
  'wash_care',
  'piling',
  'gauge_inch',
  'yarn_count',
  'stretch',
  'functional_finish',
  'creation_date',
  'season',
  'usage_category',
  'look',
  'special_yarn',
  'sustainability',
  'hand_feel',
  'repeat',
  'lace_structure',
  'pattern_design',
  'aesthetic_finish',
  'edge_finish',
  'price_per_sqm',
  'price_per_m',
  'price_per_kg',
  'price_per_piece',
  'currency',
  'lead_time_days',
  'moq_m',
  'mcq_m',
  'moq_sqm',
  'mcq_sqm',
  'moq_kg',
  'mcq_kg',
  'moq_piece',
  'mcq_piece',
  'stock_m',
  'stock_sqm',
  'stock_kg',
  'stock_piece',
  'weight_grams_per_m',
  'weight_grams_per_piece',
  'length_cm_per_piece',
  'availability',
  'certifications',
]);

// Loads an image from a URL and returns it as an HTMLImageElement.
const loadImage = async (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = url;
  });
};

// Converts an HTMLImageElement to a Base64-encoded JPEG string.
const getBase64 = (img: HTMLImageElement): Promise<string> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      reject(new Error('Failed to get canvas 2D context'));
      return;
    }
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    resolve(canvas.toDataURL('image/jpeg'));
  });
};

// Calculate scaled image dimensions based on aspect ratio and maximum dimensions.
const calculateScaledDimensions = (
  img: HTMLImageElement,
  maxWidth: number,
  maxHeight: number
) => {
  const imgWidth = img.width;
  const imgHeight = img.height;
  const imgAspectRatio = imgWidth / imgHeight;
  let scaledWidth, scaledHeight;
  if (imgWidth > imgHeight) {
    // Landscape image
    scaledWidth = maxWidth;
    scaledHeight = scaledWidth / imgAspectRatio;
  } else {
    // Portrait or square image
    scaledHeight = maxHeight;
    scaledWidth = scaledHeight * imgAspectRatio;
  }
  return {
    width: scaledWidth,
    height: scaledHeight,
  };
};

export const exportExcelFabrics = async (
  fabrics: record[],
  measurementUnit: string,
  type: string,
  name: string,
  includeImages: 'sm' | 'md' | 'lg' | 'excel' | null
) => {
  if (includeImages === 'excel') {
    message.success(
      i18n.t('long_messages:generate_image_excel_success_message')
    );
  } else {
    message.success(i18n.t('long_messages:generate_excel_success_message'));
  }
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('fabrics');
  const imageFields = [
    'image_url_front',
    'image_url_back',
    'image_url_header',
    'image_url_macro',
  ];

  // Filter columns based on the includeImages flag
  const filteredColumnOrder = fabricFieldObjs
    .filter((a) => includeImages === 'excel' || !imageFields.includes(a.name))
    .map((a) => a.name);

  const filteredTitleOrder = fabricFieldObjs
    .filter((a) => includeImages === 'excel' || !imageFields.includes(a.name))
    .map((a) => {
      const title = parseFabricTitles(a.name, measurementUnit);
      if (imageFields.includes(a.name)) {
        return title.split(' ')[0]; // Take only the first word
      }
      return title;
    });

  // Add titles to the first row
  worksheet.addRow(filteredTitleOrder).eachCell((cell) => {
    cell.font = { bold: true };
  });

  // Set a standard width for all columns
  const standardColumnWidth = 25;
  const standardRowHeight = 35;
  worksheet.columns.forEach((column) => {
    column.width = standardColumnWidth;
  });

  const translatedFabricData = getTranslatedValuesAndUpdateData(
    fabrics,
    fabricPropertyArray
  );

  // Define thumbnail size
  const thumbnailMaxWidth = 30;
  const thumbnailMaxHeight = 30;

  // Convert fabrics data to worksheet data
  for (const fabric of translatedFabricData) {
    const rowValues = filteredColumnOrder.map((key) =>
      _parseValue(
        key,
        fabric[key as keyof typeof fabric] as string,
        fabric.currency as string
      )
    );
    const row = worksheet.addRow(rowValues);

    if (includeImages === 'excel') {
      for (const key of imageFields) {
        if (filteredColumnOrder.includes(key)) {
          const columnIndex = filteredColumnOrder.indexOf(key) + 1; // ExcelJS columns are 1-based
          worksheet.getColumn(columnIndex).width = thumbnailMaxWidth / 4; // Adjust width for image columns

          if (fabric[key as keyof record]) {
            try {
              const img = await loadImage(
                fabric[key as keyof typeof fabric] as string
              );
              const base64 = await getBase64(img);
              const scaledDimensions = calculateScaledDimensions(
                img,
                thumbnailMaxWidth,
                thumbnailMaxHeight
              );
              const imageId = workbook.addImage({ base64, extension: 'jpeg' });

              worksheet.addImage(imageId, {
                tl: { col: columnIndex - 0.1, row: row.number - 0.75 },
                ext: {
                  width: scaledDimensions.width,
                  height: scaledDimensions.height,
                },
              });
            } catch (error) {
              row.getCell(columnIndex).value = null;
            }
          }
        }
      }
    }

    if (row.number > 1) {
      row.height = standardRowHeight; // Adjust the row height for images
    }
  }

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(blob, `${type} ${name}.xlsx`);
};

/**
 * Export fabrics from either a Library, Project or Collection to a PDF file.
 */
const addImageToPDF = async (
  doc: jsPDF,
  url: string,
  x: number,
  y: number,
  maxWidth: number,
  maxHeight: number
): Promise<boolean> => {
  try {
    const img = await loadImage(url);
    const dataUrl = await getBase64(img);
    const scaledDimensions = calculateScaledDimensions(
      img,
      maxWidth,
      maxHeight
    );

    // Center the image within the square
    const centeredX = x + (maxWidth - scaledDimensions.width) / 2;
    const centeredY = y + (maxHeight - scaledDimensions.height) / 2;

    doc.addImage(
      dataUrl,
      'JPEG',
      centeredX,
      centeredY,
      scaledDimensions.width,
      scaledDimensions.height
    );
    return true;
  } catch (error) {
    return false;
  }
};

// Updated PDF export function
export const exportPDFFabrics = async (
  fabrics: record[],
  measurementUnit: string,
  type: string,
  name: string,
  includeImages: 'sm' | 'md' | 'lg' | 'excel' | null,
  selectedFields: string[]
) => {
  if (includeImages) {
    message.success(i18n.t('long_messages:generate_image_pdf_success_message'));
  } else {
    message.success(i18n.t('long_messages:generate_pdf_success_message'));
  }

  const translatedFabricData = getTranslatedValuesAndUpdateData(
    fabrics,
    fabricPropertyArray
  );
  const doc = new jsPDF();
  doc.addFileToVFS('MiSans-Regular-normal.ttf', font);
  doc.addFont('MiSans-Regular-normal.ttf', 'MiSans-Regular', 'normal');
  doc.setFont('MiSans-Regular');
  doc.setFontSize(10);

  const imageFields = [
    'image_url_front',
    'image_url_back',
    'image_url_header',
    'image_url_macro',
  ];
  const columnOrder = fabricFieldObjs.map((a) => a.name);
  const filteredColumnOrder = columnOrder.filter((col) =>
    selectedFields.includes(col)
  );

  const hasImageFields = imageFields.some((imageField) =>
    selectedFields.includes(imageField)
  );

  let pageNumber = 1;

  const drawBorderAndPageNumber = () => {
    doc.rect(
      5,
      5,
      doc.internal.pageSize.width - 10,
      doc.internal.pageSize.height - 10
    );
    doc.text(
      `Page ${pageNumber}`,
      doc.internal.pageSize.width / 2,
      doc.internal.pageSize.height - 10,
      { align: 'center' }
    );
  };

  for (const fabric of translatedFabricData) {
    let yPos = 20;

    // Filter valid images
    const validImages = imageFields
      .map((field) => fabric[field as keyof record])
      .filter((url) => url !== null && url !== undefined) as string[];

    if (includeImages === 'sm') {
      // Calculate the image width and height as 40% of the page dimensions (no more than 40% of width and height)
      const imageAreaWidth = (doc.internal.pageSize.width - 20) * 0.4; // 40% of the page width (considering padding)
      const imageAreaHeight = imageAreaWidth * 0.75; // Set a 4:3 aspect ratio for the images (optional, adjust as needed)

      // Handle different arrangements based on number of images
      if (
        validImages.length === 1 &&
        hasImageFields &&
        validImages.length > 0
      ) {
        // 1 image, centered at the top
        const xPos = (doc.internal.pageSize.width - imageAreaWidth) / 2; // Center the image
        const yPosImg = 20; // Start positioning near the top
        await addImageToPDF(
          doc,
          validImages[0],
          xPos,
          yPosImg,
          imageAreaWidth,
          imageAreaHeight
        );
        yPos = yPosImg + imageAreaHeight + 20; // Move below the image for text
      } else if (
        validImages.length === 2 &&
        hasImageFields &&
        validImages.length > 0
      ) {
        // 2 images in a single row
        const xPos1 = 15; // First image at the left side with padding
        const xPos2 = xPos1 + imageAreaWidth + 10; // 10px gap between images
        await addImageToPDF(
          doc,
          validImages[0],
          xPos1,
          yPos,
          imageAreaWidth,
          imageAreaHeight
        );
        await addImageToPDF(
          doc,
          validImages[1],
          xPos2,
          yPos,
          imageAreaWidth,
          imageAreaHeight
        );
        yPos += imageAreaHeight + 20; // Move down after images
      } else if (
        (validImages.length === 3 || validImages.length === 4) &&
        hasImageFields &&
        validImages.length > 0
      ) {
        // 2 rows of images (2 per row for 3 or 4 images)
        for (let i = 0; i < Math.min(validImages.length, 4); i++) {
          const xPos = 20 + (i % 2) * (imageAreaWidth + 15); // Positioning first and second image in the row
          const yPosImg = yPos + Math.floor(i / 2) * (imageAreaHeight + 15); // Second row positioning
          await addImageToPDF(
            doc,
            validImages[i],
            xPos,
            yPosImg,
            imageAreaWidth,
            imageAreaHeight
          );
        }
        yPos += imageAreaHeight * 2 + 30; // Move below the images for text
      }

      // Adjust text placement next to the image (align left)
      const textXPos = 10; // Align text to the left, same as if there were no image
      for (const key of filteredColumnOrder) {
        const value = fabric[key as keyof record];
        if (value !== null && value !== undefined) {
          if (!imageFields.includes(key)) {
            const text = `${parseFabricTitles(key, measurementUnit)}: ${_parseValue(
              key,
              value as string | FabricCert[] | compositionsProp[],
              fabric.currency as string
            )}`;
            const lines = doc.splitTextToSize(
              text,
              doc.internal.pageSize.width - imageAreaWidth - 20
            );
            const heightRequired = lines.length * 6;

            if (yPos + heightRequired > doc.internal.pageSize.height - 40) {
              drawBorderAndPageNumber();
              pageNumber++;
              doc.addPage();
              yPos = 20;
            }

            lines.forEach((line: string) => {
              doc.text(line, textXPos, yPos); // Aligning the text from the left
              yPos += 6;
            });
          }
        }
      }
    } else if (includeImages === 'md' && validImages.length > 0) {
      const imageAreaWidth = (doc.internal.pageSize.width - 20) * 0.4; // 40% of page width
      const imageAreaHeight = (doc.internal.pageSize.height - 40) * 0.4; // 40% of page height
      const rowSpacing = 20; // Added space between the rows

      if (validImages.length === 1) {
        // Center the single image in the middle of the page, smaller size
        const xPos = (doc.internal.pageSize.width - imageAreaWidth) / 2; // Center horizontally
        const yPosImg = (doc.internal.pageSize.height - imageAreaHeight) / 2; // Center vertically
        await addImageToPDF(
          doc,
          validImages[0],
          xPos,
          yPosImg,
          imageAreaWidth,
          imageAreaHeight
        );
      } else if (validImages.length === 2 && validImages.length > 0) {
        // Two images, 40% width each, top and bottom
        const totalWidth = imageAreaWidth * 2 + 20; // 2 images with some space in between
        const yPosImgTop = 20; // Position for top image
        const yPosImgBottom = imageAreaHeight + 30; // Position for bottom image

        // First image (top)
        await addImageToPDF(
          doc,
          validImages[0],
          (doc.internal.pageSize.width - totalWidth) / 2, // Center horizontally
          yPosImgTop,
          imageAreaWidth,
          imageAreaHeight
        );

        // Second image (bottom)
        await addImageToPDF(
          doc,
          validImages[1],
          (doc.internal.pageSize.width - totalWidth) / 2, // Center horizontally
          yPosImgBottom,
          imageAreaWidth,
          imageAreaHeight
        );
      } else if (validImages.length === 3 && validImages.length > 0) {
        // Three images: Two on the top row, one in the middle on the second row
        const totalWidth = imageAreaWidth * 2 + 20; // 2 images with some space in between

        // First image (top-left)
        await addImageToPDF(
          doc,
          validImages[0],
          (doc.internal.pageSize.width - totalWidth) / 2, // Center horizontally
          20,
          imageAreaWidth,
          imageAreaHeight
        );

        // Second image (top-right)
        await addImageToPDF(
          doc,
          validImages[1],
          (doc.internal.pageSize.width - totalWidth) / 2 + imageAreaWidth + 10, // Center horizontally for the second image
          20,
          imageAreaWidth,
          imageAreaHeight
        );

        // Third image (centered on second row)
        await addImageToPDF(
          doc,
          validImages[2],
          (doc.internal.pageSize.width - imageAreaWidth) / 2, // Center horizontally
          imageAreaHeight + rowSpacing + 20, // Position below the top row
          imageAreaWidth,
          imageAreaHeight
        );
      } else if (validImages.length === 4 && validImages.length > 0) {
        // Four images: Two on top row and two on bottom row
        const rowHeight = imageAreaHeight + rowSpacing; // Added space between rows

        // Loop through images and place them in 2 rows
        for (let i = 0; i < validImages.length; i++) {
          const xPos = 10 + (i % 2) * (imageAreaWidth + 10) + 10; // Shifted to the right for both rows
          const yPosImg = 20 + Math.floor(i / 2) * rowHeight; // Positioning for top and bottom rows

          await addImageToPDF(
            doc,
            validImages[i],
            xPos,
            yPosImg,
            imageAreaWidth,
            imageAreaHeight
          );
        }
      }
      drawBorderAndPageNumber();
      pageNumber++;
      doc.addPage(); // Move to the next page for fabric fields
      yPos = 20;
    } else if (includeImages === 'lg' && validImages.length > 0) {
      // Large size: each image gets a full page
      for (let i = 0; i < validImages.length; i++) {
        const image = validImages[i];
        const imgWidth = doc.internal.pageSize.width * 0.7; // Make the image width 80% of the page width
        const imgHeight =
          (doc.internal.pageSize.height - 40) *
          (imgWidth / (doc.internal.pageSize.width - 20)); // Adjust the height proportionally to maintain aspect ratio
        const xPos = (doc.internal.pageSize.width - imgWidth) / 2; // Center the image horizontally
        const yPosImg = (doc.internal.pageSize.height - imgHeight) / 2; // Center the image vertically

        await addImageToPDF(doc, image, xPos, yPosImg, imgWidth, imgHeight);
        // Only add a new page after the current image if it's not the last image
        if (i < validImages.length - 1) {
          drawBorderAndPageNumber();
          pageNumber++;
          doc.addPage(); // After each image except the last one, create a new page
        }
      }

      // Draw the border and page number for the last image (if any)
      drawBorderAndPageNumber();
      pageNumber++;

      // After the last image, don't add an extra blank page
      // Only proceed with fabric fields after the last image
      doc.addPage(); // Add a new page for the fabric fields
      yPos = 20;
    }

    // Already integrated with small images on same page
    if (includeImages !== 'sm') {
      // Render fabric content text after images
      for (const key of filteredColumnOrder) {
        const value = fabric[key as keyof record];
        if (
          value !== null &&
          value !== undefined &&
          !(Array.isArray(value) && value.length === 0)
        ) {
          if (!imageFields.includes(key)) {
            if (key === 'name') {
              doc.setFont('MiSans-Regular');
              doc.setFontSize(20);
              doc.text(
                `${_parseValue(key, value as string | FabricCert[] | compositionsProp[])}`,
                10,
                yPos
              );
              yPos += 10; // Increase yPos for larger font
              doc.setFont('MiSans-Regular', 'normal');
              doc.setFontSize(10);
            } else if (key !== 'currency') {
              const text = `${parseFabricTitles(key, measurementUnit)}: ${_parseValue(key, value as string | FabricCert[] | compositionsProp[], fabric.currency as string)}`;
              const lines = doc.splitTextToSize(text, 180);
              const heightRequired = lines.length * 6;
              if (yPos + heightRequired > doc.internal.pageSize.height - 40) {
                // Add border and page number before adding a new page
                drawBorderAndPageNumber();
                pageNumber++;
                doc.addPage();
                yPos = 20;
                drawBorderAndPageNumber(); // Draw border and add page number on the new page
              }
              lines.forEach((line: string) => {
                doc.text(line, 10, yPos);
                yPos += 6;
              });
            }
          }
        }
      }
    }
    drawBorderAndPageNumber();

    // Add page breaks if necessary
    if (
      translatedFabricData.indexOf(fabric) <
      translatedFabricData.length - 1
    ) {
      drawBorderAndPageNumber();
      pageNumber++;
      doc.addPage();
    }
  }

  doc.save(`${type} ${name}.pdf`);
};
