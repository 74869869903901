import { FilterValues } from '../FilterTypes';

export const createInitialFilterValues = () => {
  const initialFilterValues: FilterValues = {
    created_at: {
      filter_type: 'date',
      values: [],
      has_null: true,
      required: true,
      grouping: 'other',
    },
    expiry_datetime: {
      filter_type: 'date',
      values: [],
      has_null: true,
      required: true,
      grouping: 'other',
    },
    uses: {
      filter_type: 'number',
      values: [],
      has_null: true,
      required: true,
      grouping: 'other',
    },
  };
  return initialFilterValues;
};
