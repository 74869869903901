import { message } from 'antd';
import i18n from 'i18next';

import { api } from '../api/api';
/**
 * Share the project via User Ids
 */
export async function shareProject(projectId: string, userIds: string[]) {
  const data = { shared_with_user_ids: userIds };
  await api({
    method: 'PUT',
    url: `v1/project/${projectId}/share`,
    body: data,
    showErrMsg: true,
    headers: {
      'content-type': 'application/json',
    },
  });
  message.success(i18n.t('long_messages:message_project_shared_success'));
}
