import { api } from '../api/api';
import {
  CollectionContainedReqProps,
  CollectionContainedResp,
  GetContainedCollectionsProps,
} from './CollectionTypes';

/**
 * Fetch collections containing the specified fabrics or fabrics from a source collection.
 * Only one of fabric IDs or a source collection ID can be provided.
 */
export async function getContainedCollections({
  fabricIds,
  collectionSrcId,
}: GetContainedCollectionsProps): Promise<CollectionContainedResp> {
  const params: CollectionContainedReqProps = {};

  if (fabricIds !== undefined) {
    // Manually add each fabric_id value separately to the params object
    fabricIds.forEach((fabricId) => {
      // Add each fabric_id as a separate query parameter
      params[`fabric_ids`] = fabricId;
    });
  } else if (collectionSrcId !== undefined) {
    params.fabric_source_collection_id = collectionSrcId;
  }

  return await api({
    method: 'GET',
    url: 'v1/collection/collections_containing_fabrics',
    params: params,
    showErrMsg: true,
  }).then((res: CollectionContainedResp) => {
    return res;
  });
}
