import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { TabTitle } from '../../components/TabTitle';
import { FabricDetailsMain } from '../../components/fabric/detail/FabricDetailsMain';
import { FabricDetailsMobile } from '../../components/fabric/detail/FabricDetailsMobile';
import { MainLayout } from '../../layouts/Main';
import { routesNamedDict } from '../../routes';
import { getFabricDetails } from '../../utils/backendRequests/fabric/getFabricDetails';
import { record, RootState } from '../../utils/types/Types';
import { TitleProp } from '../PagesTypes';

export function FabricDetailsPage({ title }: TitleProp) {
  const { fabricId } = useParams<{ fabricId?: string }>();
  const [fabric, setFabric] = useState<record>({} as record);

  const navigate = useNavigate();
  const isMobileFlag = useSelector((state: RootState) => state.common.isMobile);

  const getFabricData = async () => {
    const fabricData = await getFabricDetails(fabricId as string, navigate);
    setFabric(fabricData as record);
  };

  useEffect(() => {
    if (!fabricId) {
      navigate('/' + routesNamedDict.NOT_FOUND);
    } else {
      getFabricData();
    }
  }, [fabricId, navigate]);

  return (
    <MainLayout>
      <TabTitle title={title} id={fabricId} />
      {isMobileFlag ? (
        <FabricDetailsMobile fabric={fabric} />
      ) : (
        <FabricDetailsMain fabric={fabric} />
      )}
    </MainLayout>
  );
}
