import { api } from '../api/api';
import { getColoursResponse } from './ColourTypes';

/**
 * Gets all colours available to the supplier user.
 */
export async function getColours(): Promise<getColoursResponse> {
  return await api({
    method: 'GET',
    url: `v1/colour`,
    showErrMsg: true,
  }).then((res: getColoursResponse) => {
    return res;
  });
}
