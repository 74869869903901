import i18n from 'i18next';
import { useState } from 'react';

import { ExportFabricsExcelContainer } from '../../../elements/fabrics/fabricStyledElement';
import {
  createFabricsExport,
  CreateFabricsExportData,
} from '../../../utils/backendRequests/fabric/createFabricsExport';
import { Modal } from '../../Modal';

export type ExportAllFabricsModalProps = {
  libraryId?: string;
  projectId?: string;
  collectionId?: string;
  imageSearchId?: string;
  fabricIds?: string[];
};

export function ExportAllFabricsModal({
  libraryId,
  projectId,
  collectionId,
  imageSearchId,
  fabricIds,
}: ExportAllFabricsModalProps) {
  const [modal, setModal] = useState({ visible: false });

  const handleCancel = () => {
    setModal({ visible: false });
  };

  const handleOk = async () => {
    const exportData: CreateFabricsExportData = {
      library_id: libraryId ?? null,
      project_id: projectId ?? null,
      collection_id: collectionId ?? null,
      image_search_id: imageSearchId ?? null,
      fabric_ids: fabricIds ?? null,
    };
    await createFabricsExport(exportData);
    handleCancel();
  };

  const showModal = () => {
    setModal({ visible: true });
  };

  return (
    <>
      <a onClick={showModal} role="button">
        {i18n.t('buttons:export')}
      </a>
      <Modal
        title={i18n.t('headers:export_excel')}
        open={modal.visible}
        onCancel={handleCancel}
        cancelButtonProps={{ danger: true }}
        onOk={handleOk}
        okText={i18n.t('buttons:export')}
      >
        <ExportFabricsExcelContainer>
          {i18n.t('long_messages:message_export_fabrics_excel_modal')}
        </ExportFabricsExcelContainer>
      </Modal>
    </>
  );
}
