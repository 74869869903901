import { message } from 'antd';
import i18n from 'i18next';

import { api } from '../api/api';
import { projectsResponse } from './ProjectTypes';
/**
 * Creates a new project.
 * After a project is successfully created, the project
 * list stored in redux is refreshed.
 */
export async function createProject(
  name: string,
  description: string | null,
  fabric_ids: string[]
): Promise<projectsResponse> {
  const data = {
    name: name,
    description: description,
    fabric_ids: fabric_ids,
  };
  const response = await api({
    method: 'POST',
    url: 'v1/project',
    body: data,
    showErrMsg: true,
    headers: {
      'content-type': 'application/json',
    },
  });
  message.success(i18n.t('long_messages:message_project_created_success'));
  return response;
}
