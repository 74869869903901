import { useEffect, useState } from 'react';

import { TableGeneric } from '../../../components/table/TableGeneric';
import { TextSearchListResponse } from '../../../utils/backendRequests/textSearch/TextSearchTypes';
import { getTextSearchList } from '../../../utils/backendRequests/textSearch/getTextSearchList';
import { textColumns } from './columns';

export const TextSearchList = () => {
  const [data, setData] = useState<TextSearchListResponse[]>([]);

  const getData = async () => {
    setData(await getTextSearchList());
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <TableGeneric
        columns={textColumns}
        data={data}
        isBackendPaginated={false}
      />
    </>
  );
};
