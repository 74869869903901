import { message } from 'antd';

import i18n from '../../../plugins/i18n';
import { api } from '../api/api';

export type UpdateUserProps = {
  email: string;
  is_active: boolean;
  is_email_notified: boolean;
  is_2fa_enabled: boolean;
  account_type: number;
  measurement_unit: number;
  name: string;
};

/**
 * Updates an existing user with new user
 * values submitted form a form using the ID to update
 */
export async function editUser(
  user: UpdateUserProps,
  userId: string
): Promise<boolean> {
  try {
    return await api({
      method: 'PUT',
      url: `v1/user/${userId}`,
      body: user,
      showErrMsg: true,
    }).then(() => {
      message.success(i18n.t('long_messages:message_update_user'));
      return true;
    });
  } catch (error) {
    return false;
  }
}
