import { useState } from 'react';
import { Link } from 'react-router-dom';
import i18n from '../../../plugins/i18n';
import { removeSharedProject } from '../../../utils/backendRequests/project/removeSharedProject';
import { Modal } from '../../Modal';
import { RemoveSharedProjectModalWithLinkProps } from '../ModalTypes';

export function RemoveSharedProjectModalWithLink({
  userToRemove,
  sharedUsers,
  projectId,
  setSharedUsers,
}: RemoveSharedProjectModalWithLinkProps) {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const showConfirmationModal = () => {
    setIsConfirmationModalOpen(true);
  };

  const hideConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const removeSharedUserFromProject = async () => {
    const sharedUsersData = await removeSharedProject(
      projectId,
      sharedUsers,
      userToRemove.key,
      hideConfirmationModal
    );
    setSharedUsers(sharedUsersData);
  };

  return (
    <>
      {/* TODO: Link should be replaced with a Button Instead */}
      <Link to="#" onClick={showConfirmationModal}>
        {i18n.t('headers:remove')}
      </Link>
      <Modal
        title={`Confirm removal of ${userToRemove.value}?`}
        open={isConfirmationModalOpen}
        onCancel={hideConfirmationModal}
        onOk={removeSharedUserFromProject}
        okText={i18n.t('buttons:yes')}
        okButtonProps={{ danger: true }}
      ></Modal>
    </>
  );
}
