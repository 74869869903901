import { Image } from 'antd';
import i18n from 'i18next';

import { BinTab } from './BinTab';
import { InspirationBinTabProps } from './BinTypes';

export function InspirationBinTab({
  inspirations,
  getInspirations,
}: InspirationBinTabProps) {
  const columns = [
    {
      dataIndex: 'image_url',
      key: 'image_url',
      title: i18n.t('column_titles:image'),
      render: (image_url: string) => {
        return <Image width={160} src={image_url} />;
      },
    },
    {
      dataIndex: 'description',
      key: 'description',
      title: i18n.t('column_titles:description'),
    },
    {
      dataIndex: 'deleted_timestamp',
      key: 'deleted_timestamp',
      title: i18n.t('column_titles:deleted_at'),
      columnType: 'date' as const,
    },
  ];

  return (
    <BinTab
      binData={inspirations}
      binColumns={columns}
      getBinContents={getInspirations}
      binType="inspirations"
    />
  );
}
