import { message } from 'antd';
import i18n from 'i18next';

import { api } from '../api/api';
import { hideConfigModalProp, sharedUsersProp } from './ProjectTypes';

/**
 * Remove a user from a shared project
 */
export async function removeSharedProject(
  projectId: string,
  sharedUsers: sharedUsersProp,
  idToRemove: string,
  hideConfirmationModal: hideConfigModalProp
): Promise<sharedUsersProp> {
  const sharedUsersUpdated = sharedUsers.reduce(
    (accumulator, currentValue) => {
      if (currentValue.key !== idToRemove) {
        accumulator.id.push(currentValue.key);
        accumulator.users.push(currentValue);
      }
      return accumulator;
    },
    { id: [] as string[], users: [] as sharedUsersProp }
  );

  const data = { shared_with_user_ids: sharedUsersUpdated.id };

  await api({
    method: 'PUT',
    url: `v1/project/${projectId}/share`,
    body: data,
    showErrMsg: true,
    headers: {
      'content-type': 'application/json',
    },
  });
  hideConfirmationModal();
  message.success(i18n.t('long_messages:message_project_removed_user_success'));
  return sharedUsersUpdated.users;
}
