import { api } from '../api/api';
import { CollectionListItem } from './CollectionTypes';

/**
 * Gets all collections available to the user, this depends on their brand.
 */
export async function getCollections(): Promise<CollectionListItem[]> {
  return await api({
    method: 'GET',
    url: 'v1/collection',
    showErrMsg: true,
  }).then((res: CollectionListItem[]) => {
    return res;
  });
}
