import { FabricFormSubmitProps } from '../../components/fabric/FabricTypes';
import { nullParser } from '../parsers/parseNullValues';
import { unitType } from '../types/Types';

// This file contains functions for calculating various fabric-related values
// based on the available data, and a function to apply these calculations.

const conversionFactors = {
  metric: {
    smallWeightToBigWeight: 0.001, // grams to kilograms
    smallLengthToBigLength: 0.01, // centimeters to meters
  },
  imperial: {
    smallWeightToBigWeight: 0.0625, // oz to lb
    smallLengthToBigLength: 1 / 3, // feet to yard
  },
};

const calculatePricePerKg = (
  data: FabricFormSubmitProps,
  measurementUnit: unitType
) => {
  const factors = conversionFactors[measurementUnit];
  if (
    data.price_per_kg === null &&
    data.price_per_sqm !== null &&
    data.weight_grams_per_sqm !== null
  ) {
    data.price_per_kg = Number(
      data.price_per_sqm /
        (data.weight_grams_per_sqm * factors.smallWeightToBigWeight)
    );
  } else if (
    data.price_per_kg === null &&
    data.price_per_m !== null &&
    data.weight_grams_per_m !== null
  ) {
    data.price_per_kg = Number(
      data.price_per_m /
        (data.weight_grams_per_m * factors.smallWeightToBigWeight)
    );
  }

  return data;
};

const calculateMoqPerSqm = (
  data: FabricFormSubmitProps,
  measurementUnit: unitType
) => {
  const factors = conversionFactors[measurementUnit];
  if (data.moq_sqm === null && data.moq_m !== null && data.width_cm !== null) {
    data.moq_sqm = Number(
      data.moq_m * (data.width_cm * factors.smallLengthToBigLength)
    );
  } else if (
    data.moq_sqm === null &&
    data.moq_kg !== null &&
    data.weight_grams_per_sqm !== null
  ) {
    data.moq_sqm = Number(
      data.moq_kg / (data.weight_grams_per_sqm * factors.smallWeightToBigWeight)
    );
  }

  return data;
};

const calculateMoqPerM = (
  data: FabricFormSubmitProps,
  measurementUnit: unitType
) => {
  const factors = conversionFactors[measurementUnit];
  if (data.moq_m === null && data.moq_sqm !== null && data.width_cm !== null) {
    data.moq_m = Number(
      data.moq_sqm / (data.width_cm * factors.smallLengthToBigLength)
    );
  } else if (
    data.moq_m === null &&
    data.moq_kg !== null &&
    data.weight_grams_per_m !== null
  ) {
    data.moq_m = Number(
      data.moq_kg / (data.weight_grams_per_m * factors.smallWeightToBigWeight)
    );
  }
  return data;
};

const calculateMoqPerKg = (
  data: FabricFormSubmitProps,
  measurementUnit: unitType
) => {
  const factors = conversionFactors[measurementUnit];
  if (
    data.moq_kg === null &&
    data.moq_m !== null &&
    data.weight_grams_per_m !== null
  ) {
    data.moq_kg = Number(
      data.moq_m * (data.weight_grams_per_m * factors.smallWeightToBigWeight)
    );
  } else if (
    data.moq_kg === null &&
    data.moq_sqm !== null &&
    data.weight_grams_per_sqm !== null
  ) {
    data.moq_kg = Number(
      data.moq_sqm *
        (data.weight_grams_per_sqm * factors.smallWeightToBigWeight)
    );
  }

  return data;
};

const calculateMcqPerSqm = (
  data: FabricFormSubmitProps,
  measurementUnit: unitType
) => {
  const factors = conversionFactors[measurementUnit];
  if (data.mcq_sqm === null && data.mcq_m !== null && data.width_cm !== null) {
    data.mcq_sqm = Number(
      data.mcq_m * (data.width_cm * factors.smallLengthToBigLength)
    );
  } else if (
    data.mcq_sqm === null &&
    data.mcq_kg !== null &&
    data.weight_grams_per_sqm !== null
  ) {
    data.mcq_sqm = Number(
      data.mcq_kg / (data.weight_grams_per_sqm * factors.smallWeightToBigWeight)
    );
  }

  return data;
};

const calculateMcqPerM = (
  data: FabricFormSubmitProps,
  measurementUnit: unitType
) => {
  const factors = conversionFactors[measurementUnit];
  if (data.mcq_m === null && data.mcq_sqm !== null && data.width_cm !== null) {
    data.mcq_m = Number(
      data.mcq_sqm / (data.width_cm * factors.smallLengthToBigLength)
    );
  } else if (
    data.mcq_m === null &&
    data.mcq_kg !== null &&
    data.weight_grams_per_m !== null
  ) {
    data.mcq_m = Number(
      data.mcq_kg / (data.weight_grams_per_m * factors.smallWeightToBigWeight)
    );
  }

  return data;
};

const calculateMcqPerKg = (
  data: FabricFormSubmitProps,
  measurementUnit: unitType
) => {
  const factors = conversionFactors[measurementUnit];
  if (
    data.mcq_kg === null &&
    data.mcq_m !== null &&
    data.weight_grams_per_m !== null
  ) {
    data.mcq_kg = Number(
      data.mcq_m * (data.weight_grams_per_m * factors.smallWeightToBigWeight)
    );
  } else if (
    data.mcq_kg === null &&
    data.mcq_sqm !== null &&
    data.weight_grams_per_sqm !== null
  ) {
    data.mcq_kg = Number(
      data.mcq_sqm *
        (data.weight_grams_per_sqm * factors.smallWeightToBigWeight)
    );
  }

  return data;
};

const calculateStockPerSqm = (
  data: FabricFormSubmitProps,
  measurementUnit: unitType
) => {
  const factors = conversionFactors[measurementUnit];
  if (
    data.stock_sqm === null &&
    data.stock_m !== null &&
    data.width_cm !== null
  ) {
    data.stock_sqm = Number(
      data.stock_m * (data.width_cm * factors.smallLengthToBigLength)
    );
  } else if (
    data.stock_sqm === null &&
    data.stock_kg !== null &&
    data.weight_grams_per_sqm !== null
  ) {
    data.stock_sqm = Number(
      data.stock_kg /
        (data.weight_grams_per_sqm * factors.smallWeightToBigWeight)
    );
  }

  return data;
};

const calculateStockPerM = (
  data: FabricFormSubmitProps,
  measurementUnit: unitType
) => {
  const factors = conversionFactors[measurementUnit];
  if (
    data.stock_m === null &&
    data.stock_sqm !== null &&
    data.width_cm !== null
  ) {
    data.stock_m = Number(
      data.stock_sqm / (data.width_cm * factors.smallLengthToBigLength)
    );
  } else if (
    data.stock_m === null &&
    data.stock_kg !== null &&
    data.weight_grams_per_m !== null
  ) {
    data.stock_m = Number(
      data.stock_kg / (data.weight_grams_per_m * factors.smallWeightToBigWeight)
    );
  }

  return data;
};

const calculateStockPerKg = (
  data: FabricFormSubmitProps,
  measurementUnit: unitType
) => {
  const factors = conversionFactors[measurementUnit];
  if (
    data.stock_kg === null &&
    data.stock_m !== null &&
    data.weight_grams_per_m !== null
  ) {
    data.stock_kg = Number(
      data.stock_m * (data.weight_grams_per_m * factors.smallWeightToBigWeight)
    );
  } else if (
    data.stock_kg === null &&
    data.stock_sqm !== null &&
    data.weight_grams_per_sqm !== null
  ) {
    data.stock_kg = Number(
      data.stock_sqm *
        (data.weight_grams_per_sqm * factors.smallWeightToBigWeight)
    );
  }

  return data;
};

const calculateWeightGramsPerSqm = (
  data: FabricFormSubmitProps,
  measurementUnit: unitType
) => {
  const factors = conversionFactors[measurementUnit];
  if (
    data.weight_grams_per_sqm === null &&
    data.weight_grams_per_m !== null &&
    data.width_cm !== null
  ) {
    data.weight_grams_per_sqm = Number(
      data.weight_grams_per_m / (data.width_cm * factors.smallLengthToBigLength)
    );
  }

  return data;
};

const calculateWeightGramsPerM = (
  data: FabricFormSubmitProps,
  measurementUnit: unitType
) => {
  const factors = conversionFactors[measurementUnit];
  if (
    data.weight_grams_per_m === null &&
    data.weight_grams_per_sqm !== null &&
    data.width_cm !== null
  ) {
    data.weight_grams_per_m = Number(
      data.weight_grams_per_sqm *
        (data.width_cm * factors.smallLengthToBigLength)
    );
  }

  return data;
};

const calculateWeightGramsPerPiece = (
  data: FabricFormSubmitProps,
  measurementUnit: unitType
) => {
  const factors = conversionFactors[measurementUnit];
  if (
    data.weight_grams_per_piece === null &&
    data.weight_grams_per_m !== null &&
    data.length_cm_per_piece !== null
  ) {
    data.weight_grams_per_piece = Number(
      data.weight_grams_per_m *
        (data.length_cm_per_piece * factors.smallLengthToBigLength)
    );
  } else if (
    data.weight_grams_per_piece === null &&
    data.weight_grams_per_sqm !== null &&
    data.width_cm !== null &&
    data.length_cm_per_piece !== null
  ) {
    data.weight_grams_per_piece = Number(
      data.weight_grams_per_sqm *
        (data.width_cm * factors.smallLengthToBigLength) *
        (data.length_cm_per_piece * factors.smallLengthToBigLength)
    );
  }

  return data;
};

const calculatePricePerSqm = (
  data: FabricFormSubmitProps,
  measurementUnit: unitType
) => {
  const factors = conversionFactors[measurementUnit];
  if (
    data.price_per_sqm === null &&
    data.price_per_m !== null &&
    data.width_cm !== null
  ) {
    data.price_per_sqm = Number(
      data.price_per_m / (data.width_cm * factors.smallLengthToBigLength)
    );
  } else if (
    data.price_per_sqm === null &&
    data.price_per_kg !== null &&
    data.weight_grams_per_sqm !== null
  ) {
    data.price_per_sqm = Number(
      data.price_per_kg *
        (data.weight_grams_per_sqm * factors.smallWeightToBigWeight)
    );
  }
  return data;
};

const calculatePricePerM = (
  data: FabricFormSubmitProps,
  measurementUnit: unitType
) => {
  const factors = conversionFactors[measurementUnit];
  if (
    data.price_per_m === null &&
    data.price_per_sqm !== null &&
    data.width_cm !== null
  ) {
    data.price_per_m = Number(
      data.price_per_sqm * (data.width_cm * factors.smallLengthToBigLength)
    );
  } else if (
    data.price_per_m === null &&
    data.price_per_kg !== null &&
    data.weight_grams_per_m !== null
  ) {
    data.price_per_m = Number(
      data.price_per_kg *
        (data.weight_grams_per_m * factors.smallWeightToBigWeight)
    );
  }
  return data;
};

const applyCalculations = (
  data: FabricFormSubmitProps,
  calculations: Array<
    (
      data: FabricFormSubmitProps,
      measurementUnit: unitType
    ) => FabricFormSubmitProps
  >,
  measurementUnit: unitType
) => {
  return calculations.reduce(
    (acc, calcFn) => calcFn(acc, measurementUnit),
    data
  );
};

/**
 * Compares two objects and logs differences.
 */
export const compareAndReturnDifferences = async (
  data: FabricFormSubmitProps,
  measurementUnit: unitType
): Promise<Array<{
  field: string;
  original: unknown;
  updated: unknown;
}> | null> => {
  // Parse original data
  const original = nullParser(data);

  // Await the async operation to get updated data
  const updated = await populateFabricValues(
    original as FabricFormSubmitProps,
    measurementUnit
  );
  const differences: Array<{
    field: string;
    original: unknown;
    updated: unknown;
  }> = [];
  const deepCompare = (
    obj1: FabricFormSubmitProps,
    obj2: FabricFormSubmitProps,
    path: string[] = []
  ) => {
    for (const key in obj1) {
      if (Object.prototype.hasOwnProperty.call(obj1, key)) {
        const currentPath = [...path, key];
        const keyTyped = key as keyof FabricFormSubmitProps;
        if (
          typeof obj1[keyTyped] === 'object' &&
          obj1[keyTyped] !== null &&
          typeof obj2[keyTyped] === 'object' &&
          obj2[keyTyped] !== null
        ) {
          deepCompare(
            obj1[keyTyped] as unknown as FabricFormSubmitProps,
            obj2[keyTyped] as unknown as FabricFormSubmitProps,
            currentPath
          );
        } else if (obj1[keyTyped] !== obj2[keyTyped]) {
          differences.push({
            field: currentPath.join('.'),
            original: obj1[keyTyped],
            updated: obj2[keyTyped],
          });
        }
      }
    }
  };

  deepCompare(original as FabricFormSubmitProps, updated);

  if (differences.length === 0) {
    return null;
  } else {
    return differences;
  }
};
/**
 * Calculates and populates missing fabric values based on provided
 * fabric data by applying a series of predefined calculations.
 */
export const populateFabricValues = async (
  data: FabricFormSubmitProps,
  measurementUnit: unitType
) => {
  const calculations = [
    calculatePricePerSqm,
    calculatePricePerKg,
    calculateMoqPerSqm,
    calculateMoqPerM,
    calculateMoqPerKg,
    calculateMcqPerSqm,
    calculateMcqPerM,
    calculateMcqPerKg,
    calculateStockPerSqm,
    calculateStockPerM,
    calculateStockPerKg,
    calculateWeightGramsPerSqm,
    calculateWeightGramsPerM,
    calculateWeightGramsPerPiece,
    calculatePricePerSqm,
    calculatePricePerM,
  ];
  const nullData = nullParser(data);
  const updatedData = applyCalculations(
    nullData as FabricFormSubmitProps,
    calculations,
    measurementUnit
  );
  return updatedData;
};
