import { message } from 'antd';
import i18n from 'i18next';

import { api } from '../api/api';

/**
 * Remove fabrics from a Fabric Project via their id
 * Save result to state.
 */
export async function deleteFabricsFromProjects(
  projectId: string,
  fabricsIdsToRemove: string[]
): Promise<boolean> {
  const data = { fabric_ids: fabricsIdsToRemove };

  try {
    await api({
      method: 'DELETE',
      url: `v1/project/${projectId}/fabric`,
      body: data,
      showErrMsg: true,
    });
    message.success(
      i18n.t('long_messages:message_project_removed_fabric_success')
    );
    return true;
  } catch (error) {
    return false;
  }
}
