import { SuppliersPage } from '../pages/suppliers/SuppliersPage';
import { RouteProps } from './RouteTypes';

export const routesSuppliers: RouteProps[] = [
  {
    path: '/suppliers',
    title: 'Suppliers',
    key: 'suppliers_page',
    page: SuppliersPage,
  },
];
