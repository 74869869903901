import i18n from 'i18next';
import { DropDownObjProp } from '../../components/form/FormTypes';

// Define the special order for seasons
const seasonOrder = ['summer', 'autumn', 'winter', 'spring'];

/**
 * Sorts a list of dropdown options based on their translated text. The data should be compatible with
 * the 'propsWithDropDownObj' state used in the FormItems component.
 * If the translated text is the 'None' equivalent, it will always be placed at the top.
 *
 * This function does not translate the options, it only sorts them based on their translated text.
 */
export const sortDropdownOptionsByTranslation = (
  data: DropDownObjProp
): DropDownObjProp => {
  const sortedData = Object.entries(data).reduce((acc, [key, listOptions]) => {
    let sortedOptions: typeof listOptions = []; // Initialize sortedOptions as an empty array
    // If 'season' exists, apply the special case sorting
    if (key === 'season') {
      sortedOptions = listOptions.sort((a, b) => {
        const indexA = seasonOrder.indexOf(a.label);
        const indexB = seasonOrder.indexOf(b.label);
        return indexA - indexB;
      });
    } else {
      // For other keys, sort alphabetically based on translated text
      sortedOptions = listOptions
        .map((option) => ({
          ...option,
          translated: i18n.t(option['label']),
        }))
        .sort((a, b) => {
          if (a.translated === i18n.t('common:none')) return -1; // Always place 'None' at the top
          if (b.translated === i18n.t('common:none')) return 1;
          return a.translated.localeCompare(b.translated);
        })
        .map(({ translated, ...rest }) => {
          // Remove the 'translated' key from the object
          void translated;
          return rest;
        });
    }

    acc[key] = sortedOptions;
    return acc;
  }, {} as DropDownObjProp);
  return sortedData;
};

/**
 * Sorts an array of keys based on their translations.
 * The data is only sorted and not translated. Note namespace is not
 * speciified here as all the namespaces are configured as fallbacks.
 *
 * Field Name is an optional variable passed to apply a season ordering
 * logic to fields.
 */
export const sortKeysByTranslation = (
  keys: string[],
  fieldName?: string
): string[] => {
  const translatedKeys = keys.map((key) => ({
    original: key,
    translated: i18n.t(key),
  }));

  // If fieldName is 'season', sort all keys by season order
  if (fieldName === 'season') {
    translatedKeys.sort((a, b) => {
      const indexA = seasonOrder.indexOf(a.original.toLowerCase());
      const indexB = seasonOrder.indexOf(b.original.toLowerCase());
      return indexA - indexB;
    });
  } else {
    // Otherwise, sort by translated text
    translatedKeys.sort((a, b) => a.translated.localeCompare(b.translated));
  }

  return translatedKeys.map((item) => item.original);
};
