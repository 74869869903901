import { Input, message } from 'antd';
import { useEffect, useState } from 'react';
import { HandleSubmitEditableLabel } from './fabricColumns';

interface EditableLabelProps {
  label: string;
  fabricId: string;
  handleSubmitEditableLabel: HandleSubmitEditableLabel;
}

export const EditableLabel = ({
  label,
  fabricId,
  handleSubmitEditableLabel,
}: EditableLabelProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentLabel, setCurrentLabel] = useState(label);
  const [originalLabel, setOriginalLabel] = useState(label);

  useEffect(() => {
    setCurrentLabel(label);
    setOriginalLabel(label);
  }, [label]);

  const handleSave = async () => {
    if (currentLabel !== label) {
      const updatedLabel = currentLabel.trim() === '' ? null : currentLabel;
      const success = await handleSubmitEditableLabel(updatedLabel, [fabricId]);
      if (success) {
        message.success('Label updated successfully');
        setOriginalLabel(currentLabel);
      } else {
        message.error('Failed to update label. Reverting to original.');
        setCurrentLabel(originalLabel);
      }
    }
    setIsEditing(false);
  };

  return isEditing ? (
    <Input
      value={currentLabel}
      onChange={(e) => setCurrentLabel(e.target.value)}
      onBlur={handleSave}
      onPressEnter={handleSave}
      autoFocus
      style={{ width: '200px' }}
    />
  ) : (
    <div style={{ cursor: 'pointer' }} onDoubleClick={() => setIsEditing(true)}>
      {currentLabel}
    </div>
  );
};
