import { api } from '../api/api';
import { SharedLinksResponse } from './CollectionTypes';

/**
 * Gets all sharable links information available for
 * users supplier brand
 */
export async function getShareableLinks(): Promise<SharedLinksResponse> {
  return await api({
    method: 'GET',
    url: 'v1/shareable_link/collection',
    showErrMsg: true,
  }).then((res: SharedLinksResponse) => {
    return res;
  });
}
