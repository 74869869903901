import styled from 'styled-components';
import { TextB2 as BaseTextB2 } from '../typography/TextB2';
import { TitleH7 as BaseTitleH7 } from '../typography/TitleH7';

export const StyledIframeContainer = styled.div<{ $isMobile: boolean }>`
  display: ${({ $isMobile }) => ($isMobile ? 'flex' : 'block')};
  justify-content: ${({ $isMobile }) => ($isMobile ? 'center' : 'flex-start')};
  width: ${({ $isMobile }) => ($isMobile ? '90%' : 'auto')};
  margin: ${({ $isMobile }) => ($isMobile ? '0 20px auto' : '0')};
`;

export const TitleH7 = styled(BaseTitleH7)<{ $isMobile: boolean }>`
  text-align: ${({ $isMobile }) => ($isMobile ? 'center' : 'left')};
  margin-bottom: ${({ $isMobile }) => ($isMobile ? '10px' : '10px')};
  margin-left: ${({ $isMobile }) => ($isMobile ? '0px' : '0')};
`;

export const StyledIframe = styled.iframe<{ $isMobile: boolean }>`
  width: ${({ $isMobile }) => ($isMobile ? '90%' : '1100px')};
  height: ${({ $isMobile }) => ($isMobile ? '400px' : '600px')};
  border: none;
  display: block;
  margin: ${({ $isMobile }) => ($isMobile ? '0 auto' : '0')};
`;

export const StyledTextB2 = styled(BaseTextB2)<{ $isMobile: boolean }>`
  width: ${({ $isMobile }) => ($isMobile ? '70%' : '1100px')};
  margin: ${({ $isMobile }) => ($isMobile ? '10px 65px auto' : '20px 0')};
  text-align: ${({ $isMobile }) => ($isMobile ? 'center' : 'left')};
`;
