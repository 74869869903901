import i18n from 'i18next';
import { Color } from '../../../elements/base/Color';
import { deleteActiveSupplier } from '../../../utils/backendRequests/suppliers/deleteActiveSupplier';
import { Setter } from '../../../utils/types/Types';
import { Modal } from '../../Modal';

interface DeactivateSupplierModalProps {
  isModalOpen: { visible: boolean };
  setIsModalOpen: Setter<{ visible: boolean }>;
  supplierId: string;
  getSupplierData: () => Promise<void>;
}

export function DeactivateSupplierModal({
  isModalOpen,
  setIsModalOpen,
  supplierId,
  getSupplierData,
}: DeactivateSupplierModalProps) {
  // Submits a request to unapprove supplier linked with current designer
  // If successful, closes modal
  const handleSubmit = async () => {
    const updateUserResponse = await deleteActiveSupplier(supplierId);

    if (updateUserResponse) {
      getSupplierData();
      setIsModalOpen({ visible: false });
    }
  };

  const handleCancel = () => {
    setIsModalOpen({ visible: false });
  };

  return (
    <Modal
      title={i18n.t('headers:confirm_unapprove_supplier')}
      open={isModalOpen.visible}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okText={i18n.t('buttons:remove')}
      okButtonProps={{
        style: { backgroundColor: Color.Red },
      }}
    >
      {i18n.t('long_messages:message_confirm_unapprove_supplier')}
    </Modal>
  );
}
